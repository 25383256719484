import { useQuery } from "@apollo/client";

import { LotNftApprovalState, LotNode, LotState } from "@/generated/schema";
import { useFetchAccountData } from "@/hooks/useFetchAccountData";
import { formatCurrency } from "@/utils/getCurrency";

import { GET_LOT } from "../api/GET_LOT";

import { useCreateBid } from "./useCreateBid";

interface QueryResponse {
  lot: LotNode;
}

// TODO: Need to tidy this up and rethink what is exported.
// Maybe prices object, with opening, next, highest formatted?

export const useLot = (id: string) => {
  const { data, error, loading } = useQuery<QueryResponse>(GET_LOT, {
    variables: { id },
  });

  const { accountData } = useFetchAccountData();

  const lot = data?.lot;
  const { createBid } = useCreateBid(id, lot?.currency);

  const bids = lot?.bidSet?.edges.map((bid) => ({
    id: bid?.node?.id,
    bidPrice: bid?.node?.bidPrice!,
    price: formatCurrency(bid?.node?.bidPrice, lot.currency),
    username: bid?.node?.user.username,
  }));

  const hasEnded = ![LotState.BIDDING, LotState.UPCOMING].includes(lot?.state!);

  const hasSold = [
    LotState.SOLD_AWAITING_NFT_TRANSFER,
    LotState.SOLD_AWAITING_PAYMENT,
    LotState.COMPLETING_SALE,
    LotState.COMPLETED_SALE,
  ].includes(lot?.state!);

  const hasSaleCompleted = [
    LotState.COMPLETING_SALE,
    LotState.COMPLETED_SALE,
  ].includes(lot?.state!);

  const highestBid = formatCurrency(lot?.highestBid?.bidPrice, lot?.currency);
  const minimumNextBid = formatCurrency(
    lot?.minimumNextBidPrice,
    lot?.currency,
  );
  const openingPrice = formatCurrency(lot?.openingPrice, lot?.currency);
  const sortedBids = bids ? bids.sort((a, b) => b.bidPrice - a.bidPrice) : [];

  const isUserWinning = sortedBids.length
    ? accountData?.username === sortedBids[0].username
    : false;
  const userOwnsNft =
    accountData?.username?.toLowerCase() ===
    lot?.sellerWalletAddress?.toLowerCase();

  return {
    createBid: async (bidPrice: number) =>
      await createBid({ variables: { bidPrice } }),
    error: !!error,
    loading,
    lot: {
      id: lot?.id!,
      biddingEndsAfter: lot?.biddingEndsAfter as Date | null,
      bids: sortedBids,
      buyersPremiumFee: lot?.buyersPremiumFee,
      buyersPremiumPercentage: lot?.buyersPremiumPercentage,
      collection: lot?.nftCollectionName,
      currency: lot?.currency,
      description: lot?.description,
      externalLink: lot?.nftExternalUrl,
      finalPurchasePrice: lot?.finalPurchasePrice,
      formattedPrices: {
        highestBid,
        minimumNextBid,
        openingPrice,
      },
      hasEnded,
      hasSaleCompleted,
      hasSold,
      hasUserWon: hasSold && isUserWinning,
      highestBid: {
        id: lot?.highestBid?.id,
        bidPrice: lot?.highestBid?.bidPrice,
        bidPriceCurrency: lot?.currency,
        createdAt: lot?.highestBid?.createdAt,
        state: lot?.highestBid?.state,
        walletId: (bids && bids[0]?.username) ?? "",
      },
      image:
        lot?.nftItem?.cachedImageUrl ||
        lot?.lotphotoSet?.edges[0]?.node?.photoMd,
      isAcceptingBids: lot?.state === LotState.BIDDING,
      isAwaitingNftApproval:
        lot?.state === LotState.SOLD_AWAITING_NFT_TRANSFER &&
        lot?.nftApprovalState === LotNftApprovalState.WAITING_FOR_APPROVAL,
      isProcessingNftApproval:
        lot?.state === LotState.SOLD_AWAITING_NFT_TRANSFER &&
        lot?.nftApprovalState !== LotNftApprovalState.WAITING_FOR_APPROVAL,
      isAwaitingPayment: lot?.state === LotState.SOLD_AWAITING_PAYMENT,
      isCompletingSale: lot?.state === LotState.COMPLETING_SALE,
      isCompletedSale: lot?.state === LotState.COMPLETED_SALE,
      isUpcoming: lot?.state === LotState.UPCOMING,
      isUserWinning,
      minimumNextBidPrice: lot?.minimumNextBidPrice,
      name: lot?.name,
      nftItem: lot?.nftItem,
      nftApprovalState: lot?.nftApprovalState!,
      scheduledStartTime: lot?.scheduledStartTime as Date | null,
      smartContractLotId: lot?.smartContractLotId!,
      state: lot?.state,
      userOwnsNft,
    },
    lotAnalytics: {
      "Lot ID": lot?.id,
      "Lot Currency": lot?.currency,
      "Lot Name": lot?.name,
      "Lot NFT Collection Name": lot?.nftCollectionName,
      "Lot Order": lot?.order,
      "Lot State": lot?.state,
    },
  };
};
