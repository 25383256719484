import { useState } from "react";

import { EmptyTab } from "@/components/EmptyTab";
import { LoadingBoundary } from "@/components/LoadingBoundary";
import { AuctionCard } from "@/features/nftAuctions";
import type { AuctionNodeEdge } from "@/generated/schema";

import { useChannel } from "../../hooks/useChannel";

import { Auctions, Container, Content, Control, Controls } from "./styles";

type Tab = "active" | "drafts" | "ended";

interface Props {
  channelSlug: string;
}

const AuctionsGrid = ({
  auctions,
  channelSlug,
}: {
  auctions: AuctionNodeEdge[];
  channelSlug: string;
}) => (
  <Auctions>
    {auctions.map((auction) => (
      <AuctionCard
        key={auction?.node?.id}
        currency={auction?.node?.currency!}
        description={auction?.node?.description}
        image={auction?.node?.auctionphotoSet.edges[0]?.node?.photoMd}
        link={`/channels/${channelSlug}/manage-auction/${auction.node?.id}`}
        lotCount={auction?.node?.lotCount}
        openingPrice={auction?.node?.lotMinOpeningPrice}
        scheduledStartTime={auction?.node?.scheduledStartTime}
        slug={auction?.node?.slug!}
        state={auction?.node?.state!}
        title={auction?.node?.name!}
      />
    ))}
  </Auctions>
);

export const AuctionsNavigation = ({ channelSlug }: Props) => {
  const { auctions, error, loading } = useChannel(channelSlug);
  const [activeTab, setActiveTab] = useState<Tab>("drafts");

  const activeActive = activeTab === "active";
  const draftsActive = activeTab === "drafts";
  const endedActive = activeTab === "ended";

  const updateActiveTab = (selected: Tab) => setActiveTab(selected);

  return (
    <LoadingBoundary error={error} loading={loading}>
      <Container>
        <Controls>
          <Control
            active={draftsActive}
            onClick={() => updateActiveTab("drafts")}
          >
            Drafts
          </Control>

          <Control
            active={activeActive}
            onClick={() => updateActiveTab("active")}
          >
            Active
          </Control>

          <Control
            active={endedActive}
            onClick={() => updateActiveTab("ended")}
          >
            Ended
          </Control>
        </Controls>

        <Content>
          <div hidden={!draftsActive}>
            {auctions.draft.length ? (
              <AuctionsGrid
                auctions={auctions.draft}
                channelSlug={channelSlug}
              />
            ) : (
              <EmptyTab
                copy="Auctions you have created but not yet published will appear here."
                heading="No draft auctions"
              />
            )}
          </div>

          <div hidden={!activeActive}>
            {auctions.active.length ? (
              <AuctionsGrid
                auctions={auctions.active}
                channelSlug={channelSlug}
              />
            ) : (
              <EmptyTab
                copy="ACTIVITY_PLACEHOLDER"
                heading="No active auctions"
              />
            )}
          </div>

          <div hidden={!endedActive}>
            {auctions.ended.length ? (
              <AuctionsGrid
                auctions={auctions.ended}
                channelSlug={channelSlug}
              />
            ) : (
              <EmptyTab
                copy="FOLLOWING_PLACEHOLDER"
                heading="No ended auctions"
              />
            )}
          </div>
        </Content>
      </Container>
    </LoadingBoundary>
  );
};
