import { Observable } from 'graphql-typed-client'

export interface Query {
  myPaymentMethods: (StripePaymentMethodNode | null)[] | null
  streamingChannels: StreamingChannelNodeConnection | null
  streamingChannel: StreamingChannelNode | null
  streams: StreamNodeConnection | null
  stream: StreamNode | null
  events: EventNodeConnection | null
  event: EventNode | null
  defaultNotificationPreferences: (UserNotificationPreferenceNode | null)[] | null
  myNotificationPreferences: (UserNotificationPreferenceNode | null)[] | null
  nftCollections: NFTCollectionNodeConnection | null
  nftCollection: NFTCollectionNode | null
  nftItems: NFTItemNodeConnection | null
  nftItem: NFTItemNode | null
  importNftItemsForOwnerAddress: NFTItemNodeConnection | null
  myBiddingDeposit: DepositNode | null
  channels: ChannelNodeConnection | null
  channel: ChannelNode | null
  boxBreak: BoxBreakNode | null
  boxBreaks: BoxBreakNodeConnection | null
  myBoxBreaks: BoxBreakNodeConnection | null
  myPersonalBreakRequests: PersonalBreakRequestNodeConnection | null
  remints: BoxBreakNodeConnection | null
  remint: BoxBreakNode | null
  auction: AuctionNode | null
  auctions: AuctionNodeConnection | null
  bid: BidNode | null
  lot: LotNode | null
  lots: LotNodeConnection | null
  myBiddingLots: LotNodeConnection | null
  announcementBanners: (AnnouncementNode | null)[] | null
  myAddresses: (AddressNode | null)[] | null
  me: UserType | null
  myAccount: UserType | null
  promotion: PromotionType | null
  myDiscordUser: DiscordUserType | null
  user: UserType | null
  __typename: 'Query'
}

export interface StripePaymentMethodNode extends Node {
  paymentMethodId: String
  brand: String
  lastFourDigits: String
  expiryMonth: String
  expiryYear: String
  offSession: Boolean
  defaultPaymentMethod: Boolean
  /** The ID of the object. */
  id: ID
  __typename: 'StripePaymentMethodNode'
}

/** An object with an ID */
export interface Node {
  /** The ID of the object. */
  id: ID
  __typename:
    | 'StripePaymentMethodNode'
    | 'StreamingChannelNode'
    | 'EventNode'
    | 'StreamNode'
    | 'BoxBreakNode'
    | 'FormatNode'
    | 'TeamMemberNode'
    | 'ChannelNode'
    | 'ImageNode'
    | 'AuctionNode'
    | 'AuctionPhotoNode'
    | 'LotNode'
    | 'NFTItemNode'
    | 'NFTCollectionNode'
    | 'NFTItemTraitNode'
    | 'BidNode'
    | 'LotPhotoNode'
    | 'BreakLocationNode'
    | 'BoxNode'
    | 'BoxBreakPhotoNode'
    | 'SpotNode'
    | 'HitNode'
    | 'PersonalBreakRequestNode'
    | 'AddressNode'
    | 'ParticipantNode'
    | 'RemintNFTNode'
    | 'UserNotificationPreferenceNode'
    | 'AnnouncementNode'
    | 'AnnouncementStyleNode'
    | 'PromotionType'
}

/** The `ID` scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ID type appears in a JSON response as a String; however, it is not intended to be human-readable. When expected as an input type, any string (such as `"4"`) or integer (such as `4`) input value will be accepted as an ID. */
export type ID = string

/** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
export type String = string

/** The `Boolean` scalar type represents `true` or `false`. */
export type Boolean = boolean

/** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
export type Int = number

export interface StreamingChannelNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (StreamingChannelNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'StreamingChannelNodeConnection'
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export interface PageInfo {
  /** When paginating forwards, are there more items? */
  hasNextPage: Boolean
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Boolean
  /** When paginating backwards, the cursor to continue. */
  startCursor: String | null
  /** When paginating forwards, the cursor to continue. */
  endCursor: String | null
  __typename: 'PageInfo'
}

/** A Relay edge containing a `StreamingChannelNode` and its cursor. */
export interface StreamingChannelNodeEdge {
  /** The item at the end of the edge */
  node: StreamingChannelNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'StreamingChannelNodeEdge'
}

export interface StreamingChannelNode extends Node {
  /** The ID of the object. */
  id: ID
  name: String
  /** User-facing description of this channel and its focus. */
  description: String
  /** URL identifier for this channel. */
  slug: String
  eventSet: EventNodeConnection
  streamSet: StreamNodeConnection
  nextUpcomingStreamSet: StreamNodeConnection | null
  nextUpcomingStream: StreamNode | null
  liveStreamSet: StreamNodeConnection | null
  liveStream: StreamNode | null
  recentlyEndedStreamSet: StreamNodeConnection | null
  recentlyEndedStream: StreamNode | null
  __typename: 'StreamingChannelNode'
}

export interface EventNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (EventNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'EventNodeConnection'
}

/** A Relay edge containing a `EventNode` and its cursor. */
export interface EventNodeEdge {
  /** The item at the end of the edge */
  node: EventNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'EventNodeEdge'
}

export interface EventNode extends Node {
  /** The ID of the object. */
  id: ID
  eventType: EventEventType
  channel: StreamingChannelNode
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  stream: StreamNode | null
  /** Date and time at which this event is scheduled to begin. Does not enforce when it starts; only used as a guideline. May be left blank if not yet scheduled. */
  scheduledStartTime: DateTime | null
  /** Estimated duration of this event. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration: Float
  /** Optional event-specific replay video, hosted on YouTube. If populated, once the event has ended it will show this video instead of the video from the associated Stream. Leave blank to just continue to show the full Stream video. */
  replayYoutubeVideoId: String
  state: EventState
  boxBreak: BoxBreakNode | null
  name: String | null
  __typename: 'EventNode'
}

/** An enumeration. */
export enum EventEventType {
  /** Break */
  BREAK = 'BREAK',
  /** Auction */
  AUCTION = 'AUCTION',
}

export interface StreamNode extends Node {
  /** The ID of the object. */
  id: ID
  /** Display name for this stream. May be named after the date, number in a series, or just a descriptive title for the content being included in the stream. */
  name: String
  /** User-facing description of this stream and and what it will be covering. e.g. "Tonight we will be ripping 4 group breaks including a PYT Bowman Chrome break! Personal breaks on-request throughout the night!" */
  description: String
  channel: StreamingChannelNode
  /** Date and time at which this stream is scheduled to begin. Does not enforce when it starts; only used as a guideline. */
  scheduledStartTime: DateTime
  /** Estimated duration of this stream. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration: Float
  /** The 11-digit YouTube video ID for this stream. */
  youtubeVideoId: String
  state: StreamState
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  eventSet: EventNodeConnection
  nextUpcomingEventSet: EventNodeConnection | null
  nextUpcomingEvent: EventNode | null
  liveEventSet: EventNodeConnection | null
  liveEvent: EventNode | null
  recentlyEndedEventSet: EventNodeConnection | null
  recentlyEndedEvent: EventNode | null
  __typename: 'StreamNode'
}

/**
 * The `DateTime` scalar type represents a DateTime
 * value as specified by
 * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
 */
export type DateTime = any

/** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](https://en.wikipedia.org/wiki/IEEE_floating_point). */
export type Float = number

/** An enumeration. */
export enum StreamState {
  /** Draft */
  DRAFT = 'DRAFT',
  /** Deleted draft */
  DELETED_DRAFT = 'DELETED_DRAFT',
  /** Upcoming */
  UPCOMING = 'UPCOMING',
  /** Live */
  LIVE = 'LIVE',
  /** Ended */
  ENDED = 'ENDED',
  /** Canceled */
  CANCELED = 'CANCELED',
}

/** An enumeration. */
export enum EventState {
  /** Draft */
  DRAFT = 'DRAFT',
  /** Deleted draft */
  DELETED_DRAFT = 'DELETED_DRAFT',
  /** Upcoming */
  UPCOMING = 'UPCOMING',
  /** Live */
  LIVE = 'LIVE',
  /** Ended */
  ENDED = 'ENDED',
  /** Canceled */
  CANCELED = 'CANCELED',
}

export interface BoxBreakNode extends Node {
  /** The ID of the object. */
  id: ID
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  format: FormatNode
  /** Display name for this break (e.g. "2021 Topps Definitive Baseball 3x Hobby Box Break: 2 Random Teams") */
  name: String
  /** URL identifier for this break */
  slug: String
  /** Assign a featured rank to promote this break. Higher values take priority. Use sparingly; leave blank for most breaks. */
  featuredRank: Int | null
  /** A text description of this break, for example outlining the break format (random teams, PYT...) and products being broken. */
  description: String
  /** Date and time at which this break is scheduled to begin. Presented to users as approximate. Leave blank if break is not yet scheduled. */
  scheduledStartTime: DateTime | null
  createdAt: DateTime
  createdBy: UserType | null
  state: BoxBreakState
  /** Date and time at which this break was published onto the live site. */
  publishedAt: DateTime | null
  /** Date and time at which the break opened for entries. */
  openedAt: DateTime | null
  /** Date and time at which the live break began. */
  startedAt: DateTime | null
  /** Date and time at which the break stopped accepting new entries. */
  lockedAt: DateTime | null
  /** Date and time at which the live break concluded. */
  endedAt: DateTime | null
  /** Date and time at which the break's items completed processing. */
  settledAt: DateTime | null
  /** The 11-digit video ID for the YouTube video this break will be streamed on. */
  youtubeVideoId: String
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId: String
  /** The name for the Twitch channel this break will be streamed on. */
  twitchChannelName: String
  breakLocation: BreakLocationNode
  /** Choose where the event is visible and who can access it. */
  visibility: BoxBreakVisibility
  /** Optional restriction on how many spots a participant can claim. */
  maxSpotsPerUser: Int | null
  /** Optional URL to link to buy page for reMints */
  purchaseOverrideUrl: String
  /** Break in which this box is going to be or was opened. */
  boxSet: BoxNodeConnection
  boxbreakphotoSet: BoxBreakPhotoNodeConnection
  spotSet: SpotNodeConnection
  /** The scheduled personal break that the user would like their box broken as part of. */
  personalbreakrequestSet: PersonalBreakRequestNodeConnection
  /** Break in which this nft item is going to be or was opened. */
  remintnftSet: RemintNFTNodeConnection
  event: EventNode | null
  myParticipant: ParticipantNode | null
  hitSet: HitNodeConnection | null
  spotsTotalCount: Int | null
  spotsAvailableCount: Int | null
  spotsFilledCount: Int | null
  spotsMinPrice: Float | null
  spotsMaxPrice: Float | null
  spotsCurrency: String | null
  allowPersonalBreakRequests: Boolean | null
  __typename: 'BoxBreakNode'
}

export interface FormatNode extends Node {
  /** The ID of the object. */
  id: ID
  /** A descriptive name for this break format (e.g. "Random Team" or "Hit Draft") */
  name: String
  /** Optional description of this break format, to inform users about how it works and why they might want to choose this format over another. */
  description: String
  /** Indicates whether it is a group break, open to multiple participants, or a personal break for a single user. */
  breakCategory: FormatBreakCategory
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  boxbreakSet: BoxBreakNodeConnection
  __typename: 'FormatNode'
}

/** An enumeration. */
export enum FormatBreakCategory {
  /** Group */
  GROUP = 'GROUP',
  /** Personal */
  PERSONAL = 'PERSONAL',
  /** ReMint */
  REMINT = 'REMINT',
}

export interface BoxBreakNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (BoxBreakNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'BoxBreakNodeConnection'
}

/** A Relay edge containing a `BoxBreakNode` and its cursor. */
export interface BoxBreakNodeEdge {
  /** The item at the end of the edge */
  node: BoxBreakNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'BoxBreakNodeEdge'
}

export interface UserType {
  id: ID
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: String
  firstName: String
  lastName: String
  email: String
  discorduser: DiscordUserType | null
  userprofile: UserProfileType | null
  teamMemberships: TeamMemberNodeConnection | null
  balance: Float | null
  isStaff: Boolean | null
  __typename: 'UserType'
}

export interface DiscordUserType {
  id: ID
  discordId: String
  discordUsername: String
  discordDiscriminator: String
  user: UserType | null
  __typename: 'DiscordUserType'
}

export interface UserProfileType {
  id: ID
  emailAddress: String
  marketingEmails: Boolean
  buyTargetPercentage: Int
  sellTargetPercentage: Int
  dailyDigestHour: Int
  isCollectionPublic: Boolean
  __typename: 'UserProfileType'
}

export interface TeamMemberNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (TeamMemberNodeEdge | null)[]
  __typename: 'TeamMemberNodeConnection'
}

/** A Relay edge containing a `TeamMemberNode` and its cursor. */
export interface TeamMemberNodeEdge {
  /** The item at the end of the edge */
  node: TeamMemberNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'TeamMemberNodeEdge'
}

export interface TeamMemberNode extends Node {
  /** The ID of the object. */
  id: ID
  user: UserType
  channel: ChannelNode
  role: TeamMemberRole
  __typename: 'TeamMemberNode'
}

export interface ChannelNode extends Node {
  /** The ID of the object. */
  id: ID
  name: String
  /** User-facing description of this channel and its focus. */
  description: String
  /** URL identifier for this channel. */
  slug: String
  bannerImageUrl: String | null
  profileImageUrl: String | null
  bannerImage: ImageNode | null
  profileImage: ImageNode | null
  auctions: AuctionNodeConnection | null
  teamMembers: TeamMemberNodeConnection | null
  __typename: 'ChannelNode'
}

export interface ImageNode extends Node {
  /** The ID of the object. */
  id: ID
  createdAt: DateTime
  url: String | null
  originalUrl: String | null
  __typename: 'ImageNode'
}

export interface AuctionNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (AuctionNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'AuctionNodeConnection'
}

/** A Relay edge containing a `AuctionNode` and its cursor. */
export interface AuctionNodeEdge {
  /** The item at the end of the edge */
  node: AuctionNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'AuctionNodeEdge'
}

export interface AuctionNode extends Node {
  channel: ChannelNode
  name: String
  /** URL identifier for this auction. */
  slug: String
  /** Assign a featured rank to promote this auction. Higher values take priority. Use sparingly; leave blank for most auctions. */
  featuredRank: Int | null
  /** A longer text description of this auction, such as an overview of the items included, special guests participating in the stream, and so on. */
  description: String
  state: AuctionState
  /** Date and time at which this auction is scheduled to begin. Presented to users as approximate. Leave blank if auction is not yet scheduled. */
  scheduledStartTime: DateTime | null
  /** The 11-digit video ID for the YouTube video this auction will be streamed on. */
  youtubeVideoId: String
  /** The name for the Twitch channel this auction will be streamed on. */
  twitchChannelName: String
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId: String
  /** Controls how and when included lots are opened for bidding. */
  lotsOpenBidding: AuctionLotsOpenBidding
  /** The date and time that included lots should open for bidding. Leave blank if auction is configured to open lots immediately. */
  lotsOpenBiddingAt: DateTime | null
  /** The date and time that the first lot is scheduled to conclude bidding. */
  lotsConcludeBiddingFrom: DateTime | null
  /** The time period, in seconds, between the scheduled end times of adjacent lots. */
  lotInterval: AuctionLotInterval
  /** The time period, in seconds, at the end of a bidding window where new bids will reset the countdown to this time remaining. */
  lotBidExtensionPeriod: AuctionLotBidExtensionPeriod
  currency: AuctionCurrency
  /** Choose where the event is visible and who can access it. */
  visibility: AuctionVisibility
  /** Tag to allow Discord channels to filter content to share in their server. */
  discordFilterTag: String
  auctionphotoSet: AuctionPhotoNodeConnection
  lotSet: LotNodeConnection
  /** The ID of the object. */
  id: ID
  lotCount: Int | null
  lotMinOpeningPrice: Float | null
  lotMaxOpeningPrice: Float | null
  lotRemainingCount: Int | null
  pusherChannelId: String | null
  __typename: 'AuctionNode'
}

/** An enumeration. */
export enum AuctionState {
  /** Draft */
  DRAFT = 'DRAFT',
  /** Deleted draft */
  DELETED_DRAFT = 'DELETED_DRAFT',
  /** Published */
  PUBLISHED = 'PUBLISHED',
  /** Live */
  LIVE = 'LIVE',
  /** Ended */
  ENDED = 'ENDED',
  /** Canceled */
  CANCELED = 'CANCELED',
}

/** An enumeration. */
export enum AuctionLotsOpenBidding {
  /** Immediately when auction is published */
  IMMEDIATELY = 'IMMEDIATELY',
  /** At a later scheduled date and time */
  SCHEDULED = 'SCHEDULED',
}

/** An enumeration. */
export enum AuctionLotInterval {
  /** No delay between end times */
  A_0 = 'A_0',
  /** 3 minutes */
  A_180 = 'A_180',
  /** 5 minutes */
  A_300 = 'A_300',
  /** 10 minutes */
  A_600 = 'A_600',
}

/** An enumeration. */
export enum AuctionLotBidExtensionPeriod {
  /** No bid extensions */
  A_0 = 'A_0',
  /** 30 seconds */
  A_30 = 'A_30',
  /** 45 seconds */
  A_45 = 'A_45',
  /** 1 minute */
  A_60 = 'A_60',
  /** 3 minutes */
  A_180 = 'A_180',
  /** 5 minutes */
  A_300 = 'A_300',
  /** 10 minutes */
  A_600 = 'A_600',
}

/** An enumeration. */
export enum AuctionCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

/** An enumeration. */
export enum AuctionVisibility {
  /** Public */
  PUBLIC = 'PUBLIC',
  /** Unlisted */
  UNLISTED = 'UNLISTED',
}

export interface AuctionPhotoNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (AuctionPhotoNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'AuctionPhotoNodeConnection'
}

/** A Relay edge containing a `AuctionPhotoNode` and its cursor. */
export interface AuctionPhotoNodeEdge {
  /** The item at the end of the edge */
  node: AuctionPhotoNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'AuctionPhotoNodeEdge'
}

export interface AuctionPhotoNode extends Node {
  /** The ID of the object. */
  id: ID
  auction: AuctionNode
  photo: String | null
  createdAt: DateTime
  photoXs: String | null
  photoSm: String | null
  photoMd: String | null
  __typename: 'AuctionPhotoNode'
}

export interface LotNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (LotNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'LotNodeConnection'
}

/** A Relay edge containing a `LotNode` and its cursor. */
export interface LotNodeEdge {
  /** The item at the end of the edge */
  node: LotNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'LotNodeEdge'
}

export interface LotNode extends Node {
  order: Int
  /** An optional name which will overwrite the NFT name. */
  name: String
  /** An optional description which will overwrite the NFT description if it has one. */
  description: String
  auction: AuctionNode | null
  /** The NFT item to be sold in this lot. If not found here please import via the staff page. */
  nftItem: NFTItemNode
  nftApprovalState: LotNftApprovalState
  /** Optional URL to link out to further details about this NFT. Leave blank to default to the NFT's OpenSea page. */
  externalUrl: String
  /** Wallet address for seller of lot */
  sellerWalletAddress: String
  currency: LotCurrency
  openingPrice: Float | null
  initialDuration: Int | null
  /** Fixed end time for the initial bidding period for this lot. Set only one of initial duration or initial end time, based on whether a precise window or precise conclusion time are more important. */
  initialEndTime: DateTime | null
  /** Optional scheduled start time to automatically open bidding for this lot. Leave blank to manually handle opening bidding. */
  scheduledStartTime: DateTime | null
  newBidMinimumDuration: Int | null
  state: LotState
  /** Date and time at which this lot opened for bidding. */
  biddingOpenedAt: DateTime | null
  /** Date and time at which this lot is scheduled to conclude bidding. */
  biddingEndsAfter: DateTime | null
  /** Date and time at which this lot concluded bidding. */
  biddingEndedAt: DateTime | null
  bidSet: BidNodeConnection
  lotphotoSet: LotPhotoNodeConnection
  /** The ID of the object. */
  id: ID
  bidCount: Int | null
  currentPrice: Float | null
  highestBid: BidNode | null
  highestBidPrice: Float | null
  bidPriceIncrement: Float | null
  minimumNextBidPrice: Float | null
  buyersPremiumPercentage: Float | null
  buyersPremiumFee: Float | null
  finalPurchasePrice: Float | null
  nftExternalUrl: String | null
  smartContractLotId: Int | null
  nftCollectionName: String | null
  __typename: 'LotNode'
}

export interface NFTItemNode extends Node {
  /** The ID of the object. */
  id: ID
  name: String
  description: String
  tokenType: NFTItemTokenType
  tokenAddress: String
  tokenId: String
  /** The token_uri as it was imported from a third party service. */
  importedTokenUri: String
  /** If the token URI uses ipfs then this is the canonical location in the format of ipfs://<ipfs_path>. */
  canonicalTokenUri: String
  collection: NFTCollectionNode
  /** The image_uri as it was imported from a third party service. */
  importedImageUri: String
  /** If the image uses ipfs then this is the canonical location in the format of ipfs://<ipfs_path>. */
  canonicalImageUri: String
  chain: NFTItemChain
  data: JSONString | null
  createdAt: DateTime
  updatedAt: DateTime
  cachedImage: ImageNode | null
  nftitemtraitSet: NFTItemTraitNodeConnection
  /** The NFT item to be sold in this lot. If not found here please import via the staff page. */
  lotSet: LotNodeConnection
  tokenAddressAndTokenId: String | null
  openseaUrl: String | null
  cachedImageUrl: String | null
  __typename: 'NFTItemNode'
}

/** An enumeration. */
export enum NFTItemTokenType {
  /** ERC1155 */
  ERC1155 = 'ERC1155',
  /** ERC721 */
  ERC721 = 'ERC721',
}

export interface NFTCollectionNode extends Node {
  /** The ID of the object. */
  id: ID
  name: String
  description: String
  createdAt: DateTime
  updatedAt: DateTime
  nftitemSet: NFTItemNodeConnection
  __typename: 'NFTCollectionNode'
}

export interface NFTItemNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (NFTItemNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'NFTItemNodeConnection'
}

/** A Relay edge containing a `NFTItemNode` and its cursor. */
export interface NFTItemNodeEdge {
  /** The item at the end of the edge */
  node: NFTItemNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'NFTItemNodeEdge'
}

/** An enumeration. */
export enum NFTItemChain {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
}

/**
 * Allows use of a JSON String for input / output from the GraphQL schema.
 *
 * Use of this type is *not recommended* as you lose the benefits of having a defined, static
 * schema (one of the key benefits of GraphQL).
 */
export type JSONString = any

export interface NFTItemTraitNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (NFTItemTraitNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'NFTItemTraitNodeConnection'
}

/** A Relay edge containing a `NFTItemTraitNode` and its cursor. */
export interface NFTItemTraitNodeEdge {
  /** The item at the end of the edge */
  node: NFTItemTraitNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'NFTItemTraitNodeEdge'
}

export interface NFTItemTraitNode extends Node {
  /** The ID of the object. */
  id: ID
  nft: NFTItemNode
  name: String
  value: String
  __typename: 'NFTItemTraitNode'
}

/** An enumeration. */
export enum LotNftApprovalState {
  /** Waiting for approval */
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  /** Approving */
  APPROVING = 'APPROVING',
  /** Approved */
  APPROVED = 'APPROVED',
}

/** An enumeration. */
export enum LotCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

/** An enumeration. */
export enum LotState {
  /** Upcoming */
  UPCOMING = 'UPCOMING',
  /** Bidding */
  BIDDING = 'BIDDING',
  /** Unsold */
  UNSOLD = 'UNSOLD',
  /** Sold awaiting NFT transfer */
  SOLD_AWAITING_NFT_TRANSFER = 'SOLD_AWAITING_NFT_TRANSFER',
  /** Sold awaiting payment */
  SOLD_AWAITING_PAYMENT = 'SOLD_AWAITING_PAYMENT',
  /** Completing sale */
  COMPLETING_SALE = 'COMPLETING_SALE',
  /** Completed sale */
  COMPLETED_SALE = 'COMPLETED_SALE',
  /** Canceled */
  CANCELED = 'CANCELED',
}

export interface BidNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (BidNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'BidNodeConnection'
}

/** A Relay edge containing a `BidNode` and its cursor. */
export interface BidNodeEdge {
  /** The item at the end of the edge */
  node: BidNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'BidNodeEdge'
}

export interface BidNode extends Node {
  lot: LotNode
  user: UserType
  bidPrice: Float | null
  createdAt: DateTime
  state: BidState
  /** The ID of the object. */
  id: ID
  bidPriceCurrency: String | null
  __typename: 'BidNode'
}

/** An enumeration. */
export enum BidState {
  /** Submitted */
  SUBMITTED = 'SUBMITTED',
  /** Canceled */
  CANCELED = 'CANCELED',
  /** Below reserve */
  BELOW_RESERVE = 'BELOW_RESERVE',
  /** Lost */
  LOST = 'LOST',
  /** Won (pending payment) */
  WON_PENDING_PAYMENT = 'WON_PENDING_PAYMENT',
  /** Won (paid) */
  WON = 'WON',
}

export interface LotPhotoNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (LotPhotoNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'LotPhotoNodeConnection'
}

/** A Relay edge containing a `LotPhotoNode` and its cursor. */
export interface LotPhotoNodeEdge {
  /** The item at the end of the edge */
  node: LotPhotoNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'LotPhotoNodeEdge'
}

export interface LotPhotoNode extends Node {
  /** The ID of the object. */
  id: ID
  lot: LotNode
  photo: String | null
  createdAt: DateTime
  photoXs: String | null
  photoSm: String | null
  photoMd: String | null
  __typename: 'LotPhotoNode'
}

/** An enumeration. */
export enum TeamMemberRole {
  /** Owner */
  OWNER = 'OWNER',
  /** Admin */
  ADMIN = 'ADMIN',
}

/** An enumeration. */
export enum BoxBreakState {
  /** Template */
  TEMPLATE = 'TEMPLATE',
  /** Draft */
  DRAFT = 'DRAFT',
  /** Deleted draft */
  DELETED_DRAFT = 'DELETED_DRAFT',
  /** Published (locked) */
  PUBLISHED_LOCKED = 'PUBLISHED_LOCKED',
  /** Published (open) */
  PUBLISHED_OPEN = 'PUBLISHED_OPEN',
  /** Live (open) */
  LIVE_OPEN = 'LIVE_OPEN',
  /** Live (locked) */
  LIVE_LOCKED = 'LIVE_LOCKED',
  /** Ended */
  ENDED = 'ENDED',
  /** Settled */
  SETTLED = 'SETTLED',
  /** Canceled */
  CANCELED = 'CANCELED',
}

export interface BreakLocationNode extends Node {
  /** The ID of the object. */
  id: ID
  /** e.g. StarStock New York office, Partner Studio... */
  name: String
  /** Enable to allow users to request an item in their collection be broken during a personal break. */
  allowPersonalBreakRequests: Boolean
  boxbreakSet: BoxBreakNodeConnection
  __typename: 'BreakLocationNode'
}

/** An enumeration. */
export enum BoxBreakVisibility {
  /** Public */
  PUBLIC = 'PUBLIC',
  /** Unlisted */
  UNLISTED = 'UNLISTED',
}

export interface BoxNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (BoxNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'BoxNodeConnection'
}

/** A Relay edge containing a `BoxNode` and its cursor. */
export interface BoxNodeEdge {
  /** The item at the end of the edge */
  node: BoxNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'BoxNodeEdge'
}

export interface BoxNode extends Node {
  /** The ID of the object. */
  id: ID
  /** Optional display name for this this breakable product (e.g. Prizm Football 2020 Hobby Box). If blank, name is taken from associated product type. */
  name: String
  /** Break in which this box is going to be or was opened. */
  boxBreak: BoxBreakNode | null
  createdAt: DateTime
  createdBy: UserType | null
  __typename: 'BoxNode'
}

export interface BoxBreakPhotoNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (BoxBreakPhotoNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'BoxBreakPhotoNodeConnection'
}

/** A Relay edge containing a `BoxBreakPhotoNode` and its cursor. */
export interface BoxBreakPhotoNodeEdge {
  /** The item at the end of the edge */
  node: BoxBreakPhotoNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'BoxBreakPhotoNodeEdge'
}

export interface BoxBreakPhotoNode extends Node {
  /** The ID of the object. */
  id: ID
  boxBreak: BoxBreakNode
  photo: String | null
  createdAt: DateTime
  photoXs: String | null
  photoSm: String | null
  photoMd: String | null
  __typename: 'BoxBreakPhotoNode'
}

export interface SpotNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (SpotNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'SpotNodeConnection'
}

/** A Relay edge containing a `SpotNode` and its cursor. */
export interface SpotNodeEdge {
  /** The item at the end of the edge */
  node: SpotNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'SpotNodeEdge'
}

export interface SpotNode extends Node {
  /** The ID of the object. */
  id: ID
  boxBreak: BoxBreakNode
  /** Spots are ordered within the parent break by their rank (asc). Rank will also be used as slot name if one is not specified (e.g. random breaks). */
  rank: Int
  /** Optional display name for this spot in the break (e.g. Cleveland Browns), or may be left blank to use rank. */
  name: String
  priceCurrency: SpotPriceCurrency
  price: Float | null
  purchasedAt: DateTime | null
  /** The spot in the break that this hit is assigned to. */
  hitSet: HitNodeConnection
  personalbreakrequest: PersonalBreakRequestNode | null
  isPersonalBreakRequest: Boolean | null
  myParticipant: ParticipantNode | null
  __typename: 'SpotNode'
}

/** An enumeration. */
export enum SpotPriceCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

export interface HitNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (HitNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'HitNodeConnection'
}

/** A Relay edge containing a `HitNode` and its cursor. */
export interface HitNodeEdge {
  /** The item at the end of the edge */
  node: HitNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'HitNodeEdge'
}

export interface HitNode extends Node {
  /** The ID of the object. */
  id: ID
  /** User-facing display name for this hit (e.g. "🔥 EPIC PULL! Tom Brady Prizm Auto Silver #/10!") */
  name: String
  /** An optional longer description, giving context to the break or hit. */
  description: String
  /** The spot in the break that this hit is assigned to. */
  spot: SpotNode
  createdAt: DateTime
  createdBy: UserType | null
  photo: String | null
  photoXs: String | null
  photoSm: String | null
  photoMd: String | null
  __typename: 'HitNode'
}

export interface PersonalBreakRequestNode extends Node {
  /** The ID of the object. */
  id: ID
  user: UserType
  /** The scheduled personal break that the user would like their box broken as part of. */
  boxBreak: BoxBreakNode
  /** The spot in the break that represents this accepted request. */
  spot: SpotNode | null
  /** Optional, additional request information, such as a preferred start time. */
  requestDetails: String
  breakFeeCurrency: PersonalBreakRequestBreakFeeCurrency
  breakFee: Float | null
  createdAt: DateTime
  state: PersonalBreakRequestState
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome: Boolean
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions: String
  address: AddressNode | null
  __typename: 'PersonalBreakRequestNode'
}

/** An enumeration. */
export enum PersonalBreakRequestBreakFeeCurrency {
  /** ETH */
  ETH = 'ETH',
  /** SOL */
  SOL = 'SOL',
  /** USD $ */
  USD = 'USD',
}

/** An enumeration. */
export enum PersonalBreakRequestState {
  /** Requested */
  REQUESTED = 'REQUESTED',
  /** Canceled */
  CANCELED = 'CANCELED',
  /** Rejected */
  REJECTED = 'REJECTED',
  /** Accepted */
  ACCEPTED = 'ACCEPTED',
}

export interface AddressNode extends Node {
  description: String
  name: String
  addressLines: String
  locality: String
  region: String
  postalCode: String
  country: String
  role: AddressRole
  default: Boolean
  /** The ID of the object. */
  id: ID
  __typename: 'AddressNode'
}

/** An enumeration. */
export enum AddressRole {
  /** Shipping */
  SHIPPING = 'SHIPPING',
}

export interface ParticipantNode extends Node {
  /** The ID of the object. */
  id: ID
  boxBreak: BoxBreakNode
  user: UserType
  joinedAt: DateTime
  /** The streaming platform on which the participant plans to watch. */
  liveStreamPlatform: ParticipantLiveStreamPlatform
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome: Boolean
  address: AddressNode | null
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions: String
  /** The break participant who has claimed this spot (note that one participant may claim multiple spots). */
  spotSet: SpotNodeConnection
  __typename: 'ParticipantNode'
}

/** An enumeration. */
export enum ParticipantLiveStreamPlatform {
  /** YouTube */
  YOUTUBE = 'YOUTUBE',
}

export interface PersonalBreakRequestNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (PersonalBreakRequestNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'PersonalBreakRequestNodeConnection'
}

/** A Relay edge containing a `PersonalBreakRequestNode` and its cursor. */
export interface PersonalBreakRequestNodeEdge {
  /** The item at the end of the edge */
  node: PersonalBreakRequestNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'PersonalBreakRequestNodeEdge'
}

export interface RemintNFTNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (RemintNFTNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'RemintNFTNodeConnection'
}

/** A Relay edge containing a `RemintNFTNode` and its cursor. */
export interface RemintNFTNodeEdge {
  /** The item at the end of the edge */
  node: RemintNFTNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'RemintNFTNodeEdge'
}

export interface RemintNFTNode extends Node {
  /** The ID of the object. */
  id: ID
  /** Display name for this nft item. */
  name: String
  /** Display name for the collection this nft item belongs to. */
  collectionName: String
  blockchain: RemintNFTBlockchain
  /** Optional Link to external URL. */
  externalLink: String
  /** Optional Name of external URL page. */
  externalLinkText: String
  image: String
  /** Break in which this nft item is going to be or was opened. */
  boxBreak: BoxBreakNode | null
  __typename: 'RemintNFTNode'
}

/** An enumeration. */
export enum RemintNFTBlockchain {
  /** Ethereum */
  ETHEREUM = 'ETHEREUM',
  /** Solana */
  SOLANA = 'SOLANA',
}

export interface StreamNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (StreamNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'StreamNodeConnection'
}

/** A Relay edge containing a `StreamNode` and its cursor. */
export interface StreamNodeEdge {
  /** The item at the end of the edge */
  node: StreamNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'StreamNodeEdge'
}

export interface UserNotificationPreferenceNode extends Node {
  /** The ID of the object. */
  id: ID
  notificationType: UserNotificationPreferenceNotificationType
  targetType: UserNotificationPreferenceTargetType
  __typename: 'UserNotificationPreferenceNode'
}

/** An enumeration. */
export enum UserNotificationPreferenceNotificationType {
  /** Buy orders created */
  BUY_ORDERS_CREATED = 'BUY_ORDERS_CREATED',
  /** Buy orders completed */
  BUY_ORDERS_COMPLETED = 'BUY_ORDERS_COMPLETED',
  /** Buy orders new lowest listing */
  BUY_ORDERS_NEW_LOWEST_LISTING = 'BUY_ORDERS_NEW_LOWEST_LISTING',
  /** Buy orders outbid */
  BUY_ORDERS_OUTBID = 'BUY_ORDERS_OUTBID',
  /** Buy orders suspended */
  BUY_ORDERS_SUSPENDED = 'BUY_ORDERS_SUSPENDED',
  /** Buy orders expired */
  BUY_ORDERS_EXPIRED = 'BUY_ORDERS_EXPIRED',
  /** Sell orders created */
  SELL_ORDERS_CREATED = 'SELL_ORDERS_CREATED',
  /** Sell orders completed */
  SELL_ORDERS_COMPLETED = 'SELL_ORDERS_COMPLETED',
  /** Sell orders new best offer */
  SELL_ORDERS_NEW_BEST_OFFER = 'SELL_ORDERS_NEW_BEST_OFFER',
  /** Sell orders undercut */
  SELL_ORDERS_UNDERCUT = 'SELL_ORDERS_UNDERCUT',
  /** Sell orders expired */
  SELL_ORDERS_EXPIRED = 'SELL_ORDERS_EXPIRED',
  /** Ship home request confirmation */
  SHIP_HOME_REQUEST_CONFIRMATION = 'SHIP_HOME_REQUEST_CONFIRMATION',
  /** Submission completed */
  SUBMISSION_COMPLETED = 'SUBMISSION_COMPLETED',
  /** Break joined */
  BREAK_JOINED = 'BREAK_JOINED',
  /** Break started */
  BREAK_STARTED = 'BREAK_STARTED',
  /** Auction winning bid */
  AUCTION_WINNING_BID = 'AUCTION_WINNING_BID',
  /** Deposit receipt */
  DEPOSIT_RECEIPT = 'DEPOSIT_RECEIPT',
  /** Withdrawal request confirmation */
  WITHDRAWAL_REQUEST_CONFIRMATION = 'WITHDRAWAL_REQUEST_CONFIRMATION',
  /** Withdrawal request payment confirmation */
  WITHDRAWAL_REQUEST_PAYMENT_CONFIRMATION = 'WITHDRAWAL_REQUEST_PAYMENT_CONFIRMATION',
  /** Withdrawal request stripe refund */
  WITHDRAWAL_REQUEST_STRIPE_REFUND = 'WITHDRAWAL_REQUEST_STRIPE_REFUND',
  /** Withdrawal request paypal rejected */
  WITHDRAWAL_REQUEST_PAYPAL_REJECTED = 'WITHDRAWAL_REQUEST_PAYPAL_REJECTED',
  /** Withdrawal request insufficient funds */
  WITHDRAWAL_REQUEST_INSUFFICIENT_FUNDS = 'WITHDRAWAL_REQUEST_INSUFFICIENT_FUNDS',
  /** Withdrawal submission fee */
  WITHDRAWAL_SUBMISSION_FEE = 'WITHDRAWAL_SUBMISSION_FEE',
  /** Daily report */
  DAILY_REPORT = 'DAILY_REPORT',
  /** Weekly report */
  WEEKLY_REPORT = 'WEEKLY_REPORT',
}

/** An enumeration. */
export enum UserNotificationPreferenceTargetType {
  /** Email */
  EMAIL = 'EMAIL',
  /** Ignore */
  IGNORE = 'IGNORE',
}

export interface NFTCollectionNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (NFTCollectionNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'NFTCollectionNodeConnection'
}

/** A Relay edge containing a `NFTCollectionNode` and its cursor. */
export interface NFTCollectionNodeEdge {
  /** The item at the end of the edge */
  node: NFTCollectionNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'NFTCollectionNodeEdge'
}

export interface DepositNode {
  id: ID
  user: UserType
  /** Determines what state the bidding deposit is in */
  depositStatus: BiddingDepositDepositStatus
  biddingEnabled: Boolean | null
  __typename: 'DepositNode'
}

/** An enumeration. */
export enum BiddingDepositDepositStatus {
  /** Disabled */
  DISABLED = 'DISABLED',
  /** Enabled */
  ENABLED = 'ENABLED',
  /** Processing */
  PROCESSING = 'PROCESSING',
  /** Withdrawing */
  WITHDRAWING = 'WITHDRAWING',
}

export interface ChannelNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (ChannelNodeEdge | null)[]
  totalCount: Int | null
  edgeCount: Int | null
  __typename: 'ChannelNodeConnection'
}

/** A Relay edge containing a `ChannelNode` and its cursor. */
export interface ChannelNodeEdge {
  /** The item at the end of the edge */
  node: ChannelNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'ChannelNodeEdge'
}

export interface AnnouncementNode extends Node {
  /** The ID of the object. */
  id: ID
  state: AnnouncementBannerState
  /** Automatically generated. Example: 2021-10-16-submit-for-grading-update */
  slug: String
  /** Example: Submit for grading update */
  name: String
  /** Text that will be highlighted in the announcement banner  */
  prefixText: String | null
  /** Announcement body content */
  bodyText: String
  /** Link title */
  linkTitle: String
  /** URL linking to the announced target */
  linkUrl: String
  style: AnnouncementStyleNode
  __typename: 'AnnouncementNode'
}

/** An enumeration. */
export enum AnnouncementBannerState {
  /** Draft */
  DRAFT = 'DRAFT',
  /** Preview */
  PREVIEW = 'PREVIEW',
  /** Live (published) */
  LIVE = 'LIVE',
  /** Ended */
  ENDED = 'ENDED',
}

export interface AnnouncementStyleNode extends Node {
  /** The ID of the object. */
  id: ID
  /** Examples: Breaks, Product announcements, Technical announcements, Marketing, etc. */
  name: String
  badgeTextColor: String | null
  linkTextColor: String | null
  badgeGradientStart: String
  badgeGradientEnd: String
  announcementbannerSet: AnnouncementNodeConnection
  __typename: 'AnnouncementStyleNode'
}

export interface AnnouncementNodeConnection {
  /** Pagination data for this connection. */
  pageInfo: PageInfo
  /** Contains the nodes in this connection. */
  edges: (AnnouncementNodeEdge | null)[]
  __typename: 'AnnouncementNodeConnection'
}

/** A Relay edge containing a `AnnouncementNode` and its cursor. */
export interface AnnouncementNodeEdge {
  /** The item at the end of the edge */
  node: AnnouncementNode | null
  /** A cursor for use in pagination */
  cursor: String
  __typename: 'AnnouncementNodeEdge'
}

export interface PromotionType extends Node {
  /** The ID of the object. */
  id: ID
  description: String | null
  __typename: 'PromotionType'
}

export interface Mutation {
  createPaymentIntent: CreatePaymentIntent | null
  createWithdrawalRequest: CreateWithdrawalRequest | null
  deletePaymentMethod: DeletePaymentMethod | null
  setDefaultPaymentMethod: SetDefaultPaymentMethod | null
  createSetupIntent: CreateSetupIntent | null
  updateCryptoWalletBalance: UpdateCryptoWalletBalance | null
  updateNotificationPreferences: UpdateNotificationPreferences | null
  withdrawDepositRequestCreate: WithdrawDepositRequestCreate | null
  editChannel: EditChannel | null
  removeTeamMemberFromChannel: RemoveTeamMemberFromChannel | null
  uploadChannelBannerImage: UploadChannelBannerImage | null
  uploadChannelProfileImage: UploadChannelProfileImage | null
  addTeamMemberToChannel: AddTeamMemberToChannel | null
  buyBoxBreakSpots: BuyBoxBreakSpots | null
  auctionCreate: AuctionCreate | null
  auctionDraftDelete: AuctionDraftDelete | null
  auctionDraftEdit: AuctionDraftEdit | null
  bidCreate: BidCreate | null
  bidTransitionToPendingReview: BidTransitionToPendingReview | null
  discordBotBidCreate: DiscordBotBidCreate | null
  updateLotNftApprovalStateToApproving: UpdateLotNftApprovalStateToApproving | null
  updateLotStateToCompletingSale: UpdateLotStateToCompletingSale | null
  /** Create a brand new address for the logged-in user. */
  createAddress: CreateAddress | null
  /** Hide a specified address for the logged-in user. */
  deleteAddress: DeleteAddress | null
  /** Hide the existing address and create a replacement with the specified changes. */
  updateAddress: UpdateAddress | null
  /** Mark an existing address as the default. */
  makeAddressDefault: MakeAddressDefault | null
  accountRegister: AccountRegister | null
  disconnectDiscordUser: DisconnectDiscordUser | null
  editUserProfile: EditUserProfile | null
  generateDiscordToken: GenerateDiscordToken | null
  passwordChange: PasswordChange | null
  passwordResetRequest: PasswordResetRequest | null
  passwordResetChange: PasswordResetChange | null
  tokenAuth: ObtainJSONWebToken | null
  updateUserCollectionVisibility: UpdateUserCollectionVisibility | null
  verifyDiscordToken: VerifyDiscordToken | null
  walletAuth: WalletAuth | null
  verifyToken: Verify | null
  refreshToken: Refresh | null
  deleteTokenCookie: DeleteJSONWebTokenCookie | null
  deleteRefreshTokenCookie: DeleteRefreshTokenCookie | null
  __typename: 'Mutation'
}

export interface CreatePaymentIntent {
  paymentIntentToken: String | null
  paymentSequence: Int | null
  __typename: 'CreatePaymentIntent'
}

/** An enumeration. */
export enum WithdrawalPaymentMethods {
  PAYPAL = 'PAYPAL',
  CHECK = 'CHECK',
}

export interface CreateWithdrawalRequest {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'CreateWithdrawalRequest'
}

/** Represents an error in the input of a mutation. */
export interface Error {
  /** Name of the field that caused the error. A value of `null` indicates that the error isn't associated with a particular field. */
  field: String | null
  /** The error message. */
  message: String | null
  __typename: 'Error'
}

export interface DeletePaymentMethod {
  paymentMethodId: String | null
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'DeletePaymentMethod'
}

export interface SetDefaultPaymentMethod {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'SetDefaultPaymentMethod'
}

export interface CreateSetupIntent {
  setupIntentClientSecret: String | null
  __typename: 'CreateSetupIntent'
}

/** An enumeration. */
export enum CryptoCurrencies {
  ETH = 'ETH',
  SOL = 'SOL',
}

export interface UpdateCryptoWalletBalance {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'UpdateCryptoWalletBalance'
}

/** An enumeration. */
export enum NotificationTypes {
  BUY_ORDERS_CREATED = 'BUY_ORDERS_CREATED',
  BUY_ORDERS_COMPLETED = 'BUY_ORDERS_COMPLETED',
  BUY_ORDERS_NEW_LOWEST_LISTING = 'BUY_ORDERS_NEW_LOWEST_LISTING',
  BUY_ORDERS_OUTBID = 'BUY_ORDERS_OUTBID',
  BUY_ORDERS_SUSPENDED = 'BUY_ORDERS_SUSPENDED',
  BUY_ORDERS_EXPIRED = 'BUY_ORDERS_EXPIRED',
  SELL_ORDERS_CREATED = 'SELL_ORDERS_CREATED',
  SELL_ORDERS_COMPLETED = 'SELL_ORDERS_COMPLETED',
  SELL_ORDERS_NEW_BEST_OFFER = 'SELL_ORDERS_NEW_BEST_OFFER',
  SELL_ORDERS_UNDERCUT = 'SELL_ORDERS_UNDERCUT',
  SELL_ORDERS_EXPIRED = 'SELL_ORDERS_EXPIRED',
  SHIP_HOME_REQUEST_CONFIRMATION = 'SHIP_HOME_REQUEST_CONFIRMATION',
  SUBMISSION_COMPLETED = 'SUBMISSION_COMPLETED',
  BREAK_JOINED = 'BREAK_JOINED',
  BREAK_STARTED = 'BREAK_STARTED',
  AUCTION_WINNING_BID = 'AUCTION_WINNING_BID',
  DEPOSIT_RECEIPT = 'DEPOSIT_RECEIPT',
  WITHDRAWAL_REQUEST_CONFIRMATION = 'WITHDRAWAL_REQUEST_CONFIRMATION',
  WITHDRAWAL_REQUEST_PAYMENT_CONFIRMATION = 'WITHDRAWAL_REQUEST_PAYMENT_CONFIRMATION',
  WITHDRAWAL_REQUEST_STRIPE_REFUND = 'WITHDRAWAL_REQUEST_STRIPE_REFUND',
  WITHDRAWAL_REQUEST_PAYPAL_REJECTED = 'WITHDRAWAL_REQUEST_PAYPAL_REJECTED',
  WITHDRAWAL_REQUEST_INSUFFICIENT_FUNDS = 'WITHDRAWAL_REQUEST_INSUFFICIENT_FUNDS',
  WITHDRAWAL_SUBMISSION_FEE = 'WITHDRAWAL_SUBMISSION_FEE',
  DAILY_SUMMARY = 'DAILY_SUMMARY',
  WEEKLY_SUMMARY = 'WEEKLY_SUMMARY',
}

/** An enumeration. */
export enum TargetTypes {
  EMAIL = 'EMAIL',
  IGNORE = 'IGNORE',
}

export interface UpdateNotificationPreferences {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'UpdateNotificationPreferences'
}

export interface WithdrawDepositRequestCreate {
  withdrawDepositRequest: WithdrawDepositRequestNode | null
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'WithdrawDepositRequestCreate'
}

export interface WithdrawDepositRequestNode {
  id: ID
  user: UserType
  status: WithdrawDepositRequestStatus
  createdAt: DateTime
  refundedAt: DateTime | null
  notes: String
  __typename: 'WithdrawDepositRequestNode'
}

/** An enumeration. */
export enum WithdrawDepositRequestStatus {
  /** Requested */
  REQUESTED = 'REQUESTED',
  /** Reviewing */
  REVIEWING = 'REVIEWING',
  /** Rejected */
  REJECTED = 'REJECTED',
  /** Refunding */
  REFUNDING = 'REFUNDING',
  /** Refunded */
  REFUNDED = 'REFUNDED',
}

export interface EditChannel {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'EditChannel'
}

export interface RemoveTeamMemberFromChannel {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'RemoveTeamMemberFromChannel'
}

/**
 * Create scalar that ignores normal serialization/deserialization, since
 * that will be handled by the multipart request spec
 */
export type Upload = any

export interface UploadChannelBannerImage {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'UploadChannelBannerImage'
}

export interface UploadChannelProfileImage {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'UploadChannelProfileImage'
}

export interface AddTeamMemberToChannel {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'AddTeamMemberToChannel'
}

export interface BuyBoxBreakSpots {
  purchasedSpotsCount: Int | null
  purchasedSpotsCost: Float | null
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'BuyBoxBreakSpots'
}

export interface AuctionCreate {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'AuctionCreate'
}

export interface AuctionDraftDelete {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'AuctionDraftDelete'
}

export interface AuctionDraftEdit {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'AuctionDraftEdit'
}

export interface BidCreate {
  bid: BidNode | null
  lot: LotNode | null
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'BidCreate'
}

export interface BidTransitionToPendingReview {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'BidTransitionToPendingReview'
}

export interface DiscordBotBidCreate {
  bid: BidNode | null
  lot: LotNode | null
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'DiscordBotBidCreate'
}

export interface UpdateLotNftApprovalStateToApproving {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'UpdateLotNftApprovalStateToApproving'
}

export interface UpdateLotStateToCompletingSale {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'UpdateLotStateToCompletingSale'
}

/** Create a brand new address for the logged-in user. */
export interface CreateAddress {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'CreateAddress'
}

/** Hide a specified address for the logged-in user. */
export interface DeleteAddress {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'DeleteAddress'
}

/** Hide the existing address and create a replacement with the specified changes. */
export interface UpdateAddress {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'UpdateAddress'
}

/** Mark an existing address as the default. */
export interface MakeAddressDefault {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'MakeAddressDefault'
}

export interface AccountRegister {
  user: UserType | null
  refreshToken: String | null
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'AccountRegister'
}

export interface DisconnectDiscordUser {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'DisconnectDiscordUser'
}

export interface EditUserProfile {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'EditUserProfile'
}

export interface GenerateDiscordToken {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  token: String | null
  __typename: 'GenerateDiscordToken'
}

export interface PasswordChange {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'PasswordChange'
}

export interface PasswordResetRequest {
  success: Boolean | null
  __typename: 'PasswordResetRequest'
}

export interface PasswordResetChange {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'PasswordResetChange'
}

export interface ObtainJSONWebToken {
  payload: GenericScalar
  refreshExpiresIn: Int
  token: String
  refreshToken: String
  __typename: 'ObtainJSONWebToken'
}

/**
 * The `GenericScalar` scalar type represents a generic
 * GraphQL scalar value that could be:
 * String, Boolean, Int, Float, List or Object.
 */
export type GenericScalar = any

export interface UpdateUserCollectionVisibility {
  ok: Boolean | null
  __typename: 'UpdateUserCollectionVisibility'
}

export interface VerifyDiscordToken {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  __typename: 'VerifyDiscordToken'
}

export interface WalletAuth {
  /** List of errors that occurred executing the mutation. */
  errors: Error[] | null
  token: String | null
  refreshToken: String | null
  refreshExpiresIn: Int | null
  exp: Int | null
  origIat: Int | null
  __typename: 'WalletAuth'
}

export interface Verify {
  payload: GenericScalar
  __typename: 'Verify'
}

export interface Refresh {
  payload: GenericScalar
  refreshExpiresIn: Int
  token: String
  refreshToken: String
  __typename: 'Refresh'
}

export interface DeleteJSONWebTokenCookie {
  deleted: Boolean
  __typename: 'DeleteJSONWebTokenCookie'
}

export interface DeleteRefreshTokenCookie {
  deleted: Boolean
  __typename: 'DeleteRefreshTokenCookie'
}

export interface QueryRequest {
  myPaymentMethods?: StripePaymentMethodNodeRequest
  streamingChannels?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          /** Ordering */
          orderBy?: String | null
        },
        StreamingChannelNodeConnectionRequest,
      ]
    | StreamingChannelNodeConnectionRequest
  streamingChannel?: [{ id?: ID | null; slug?: String | null }, StreamingChannelNodeRequest] | StreamingChannelNodeRequest
  streams?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        StreamNodeConnectionRequest,
      ]
    | StreamNodeConnectionRequest
  stream?: [{ id?: ID | null }, StreamNodeRequest] | StreamNodeRequest
  events?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          eventType?: (String | null)[] | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        EventNodeConnectionRequest,
      ]
    | EventNodeConnectionRequest
  event?: [{ id?: ID | null }, EventNodeRequest] | EventNodeRequest
  defaultNotificationPreferences?: UserNotificationPreferenceNodeRequest
  myNotificationPreferences?: UserNotificationPreferenceNodeRequest
  nftCollections?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        NFTCollectionNodeConnectionRequest,
      ]
    | NFTCollectionNodeConnectionRequest
  nftCollection?: [{ id?: ID | null }, NFTCollectionNodeRequest] | NFTCollectionNodeRequest
  nftItems?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          chain?: String | null
          collection?: ID | null
          tokenAddress?: String | null
          tokenId?: String | null
          tokenAddressAndTokenIds?: (String | null)[] | null
        },
        NFTItemNodeConnectionRequest,
      ]
    | NFTItemNodeConnectionRequest
  nftItem?:
    | [{ id?: ID | null; tokenAddress?: String | null; tokenId?: String | null }, NFTItemNodeRequest]
    | NFTItemNodeRequest
  importNftItemsForOwnerAddress?: [
    {
      ownerAddress: String
      offset?: Int | null
      before?: String | null
      after?: String | null
      first?: Int | null
      last?: Int | null
      chain?: String | null
      collection?: ID | null
      tokenAddress?: String | null
      tokenId?: String | null
      tokenAddressAndTokenIds?: (String | null)[] | null
    },
    NFTItemNodeConnectionRequest,
  ]
  myBiddingDeposit?: DepositNodeRequest
  channels?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          /** Ordering */
          orderBy?: String | null
        },
        ChannelNodeConnectionRequest,
      ]
    | ChannelNodeConnectionRequest
  channel?: [{ id?: ID | null; slug?: String | null }, ChannelNodeRequest] | ChannelNodeRequest
  boxBreak?: [{ id?: ID | null; slug?: String | null }, BoxBreakNodeRequest] | BoxBreakNodeRequest
  boxBreaks?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          spotsAvailable?: Boolean | null
          state?: (String | null)[] | null
          stateGroup?: (String | null)[] | null
          featured?: Boolean | null
          breakCategory?: (String | null)[] | null
          allowPersonalBreakRequests?: Boolean | null
          /** Ordering */
          orderBy?: String | null
        },
        BoxBreakNodeConnectionRequest,
      ]
    | BoxBreakNodeConnectionRequest
  myBoxBreaks?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          spotsAvailable?: Boolean | null
          state?: (String | null)[] | null
          stateGroup?: (String | null)[] | null
          featured?: Boolean | null
          breakCategory?: (String | null)[] | null
          allowPersonalBreakRequests?: Boolean | null
          /** Ordering */
          orderBy?: String | null
        },
        BoxBreakNodeConnectionRequest,
      ]
    | BoxBreakNodeConnectionRequest
  myPersonalBreakRequests?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        PersonalBreakRequestNodeConnectionRequest,
      ]
    | PersonalBreakRequestNodeConnectionRequest
  remints?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          spotsAvailable?: Boolean | null
          state?: (String | null)[] | null
          stateGroup?: (String | null)[] | null
          featured?: Boolean | null
          breakCategory?: (String | null)[] | null
          allowPersonalBreakRequests?: Boolean | null
          /** Ordering */
          orderBy?: String | null
        },
        BoxBreakNodeConnectionRequest,
      ]
    | BoxBreakNodeConnectionRequest
  remint?: [{ id?: ID | null; slug?: String | null }, BoxBreakNodeRequest] | BoxBreakNodeRequest
  auction?: [{ id?: ID | null; slug?: String | null }, AuctionNodeRequest] | AuctionNodeRequest
  auctions?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          era?: String | null
          state?: (String | null)[] | null
          featured?: Boolean | null
          currency?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        AuctionNodeConnectionRequest,
      ]
    | AuctionNodeConnectionRequest
  bid?: [{ id?: ID | null }, BidNodeRequest] | BidNodeRequest
  lot?: [{ id?: ID | null }, LotNodeRequest] | LotNodeRequest
  lots?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        LotNodeConnectionRequest,
      ]
    | LotNodeConnectionRequest
  myBiddingLots?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        LotNodeConnectionRequest,
      ]
    | LotNodeConnectionRequest
  announcementBanners?: AnnouncementNodeRequest
  myAddresses?: AddressNodeRequest
  me?: UserTypeRequest
  myAccount?: UserTypeRequest
  promotion?: PromotionTypeRequest
  myDiscordUser?: DiscordUserTypeRequest
  user?: [{ discordId?: String | null }, UserTypeRequest] | UserTypeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface StripePaymentMethodNodeRequest {
  paymentMethodId?: boolean | number
  brand?: boolean | number
  lastFourDigits?: boolean | number
  expiryMonth?: boolean | number
  expiryYear?: boolean | number
  offSession?: boolean | number
  defaultPaymentMethod?: boolean | number
  /** The ID of the object. */
  id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** An object with an ID */
export interface NodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  on_StripePaymentMethodNode?: StripePaymentMethodNodeRequest
  on_StreamingChannelNode?: StreamingChannelNodeRequest
  on_EventNode?: EventNodeRequest
  on_StreamNode?: StreamNodeRequest
  on_BoxBreakNode?: BoxBreakNodeRequest
  on_FormatNode?: FormatNodeRequest
  on_TeamMemberNode?: TeamMemberNodeRequest
  on_ChannelNode?: ChannelNodeRequest
  on_ImageNode?: ImageNodeRequest
  on_AuctionNode?: AuctionNodeRequest
  on_AuctionPhotoNode?: AuctionPhotoNodeRequest
  on_LotNode?: LotNodeRequest
  on_NFTItemNode?: NFTItemNodeRequest
  on_NFTCollectionNode?: NFTCollectionNodeRequest
  on_NFTItemTraitNode?: NFTItemTraitNodeRequest
  on_BidNode?: BidNodeRequest
  on_LotPhotoNode?: LotPhotoNodeRequest
  on_BreakLocationNode?: BreakLocationNodeRequest
  on_BoxNode?: BoxNodeRequest
  on_BoxBreakPhotoNode?: BoxBreakPhotoNodeRequest
  on_SpotNode?: SpotNodeRequest
  on_HitNode?: HitNodeRequest
  on_PersonalBreakRequestNode?: PersonalBreakRequestNodeRequest
  on_AddressNode?: AddressNodeRequest
  on_ParticipantNode?: ParticipantNodeRequest
  on_RemintNFTNode?: RemintNFTNodeRequest
  on_UserNotificationPreferenceNode?: UserNotificationPreferenceNodeRequest
  on_AnnouncementNode?: AnnouncementNodeRequest
  on_AnnouncementStyleNode?: AnnouncementStyleNodeRequest
  on_PromotionType?: PromotionTypeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface StreamingChannelNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: StreamingChannelNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export interface PageInfoRequest {
  /** When paginating forwards, are there more items? */
  hasNextPage?: boolean | number
  /** When paginating backwards, are there more items? */
  hasPreviousPage?: boolean | number
  /** When paginating backwards, the cursor to continue. */
  startCursor?: boolean | number
  /** When paginating forwards, the cursor to continue. */
  endCursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `StreamingChannelNode` and its cursor. */
export interface StreamingChannelNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: StreamingChannelNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface StreamingChannelNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  name?: boolean | number
  /** User-facing description of this channel and its focus. */
  description?: boolean | number
  /** URL identifier for this channel. */
  slug?: boolean | number
  eventSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          eventType?: (String | null)[] | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        EventNodeConnectionRequest,
      ]
    | EventNodeConnectionRequest
  streamSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        StreamNodeConnectionRequest,
      ]
    | StreamNodeConnectionRequest
  nextUpcomingStreamSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        StreamNodeConnectionRequest,
      ]
    | StreamNodeConnectionRequest
  nextUpcomingStream?: StreamNodeRequest
  liveStreamSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        StreamNodeConnectionRequest,
      ]
    | StreamNodeConnectionRequest
  liveStream?: StreamNodeRequest
  recentlyEndedStreamSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        StreamNodeConnectionRequest,
      ]
    | StreamNodeConnectionRequest
  recentlyEndedStream?: StreamNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface EventNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: EventNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `EventNode` and its cursor. */
export interface EventNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: EventNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface EventNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  eventType?: boolean | number
  channel?: StreamingChannelNodeRequest
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  stream?: StreamNodeRequest
  /** Date and time at which this event is scheduled to begin. Does not enforce when it starts; only used as a guideline. May be left blank if not yet scheduled. */
  scheduledStartTime?: boolean | number
  /** Estimated duration of this event. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration?: boolean | number
  /** Optional event-specific replay video, hosted on YouTube. If populated, once the event has ended it will show this video instead of the video from the associated Stream. Leave blank to just continue to show the full Stream video. */
  replayYoutubeVideoId?: boolean | number
  state?: boolean | number
  boxBreak?: BoxBreakNodeRequest
  name?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface StreamNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** Display name for this stream. May be named after the date, number in a series, or just a descriptive title for the content being included in the stream. */
  name?: boolean | number
  /** User-facing description of this stream and and what it will be covering. e.g. "Tonight we will be ripping 4 group breaks including a PYT Bowman Chrome break! Personal breaks on-request throughout the night!" */
  description?: boolean | number
  channel?: StreamingChannelNodeRequest
  /** Date and time at which this stream is scheduled to begin. Does not enforce when it starts; only used as a guideline. */
  scheduledStartTime?: boolean | number
  /** Estimated duration of this stream. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration?: boolean | number
  /** The 11-digit YouTube video ID for this stream. */
  youtubeVideoId?: boolean | number
  state?: boolean | number
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  eventSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          eventType?: (String | null)[] | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        EventNodeConnectionRequest,
      ]
    | EventNodeConnectionRequest
  nextUpcomingEventSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        EventNodeConnectionRequest,
      ]
    | EventNodeConnectionRequest
  nextUpcomingEvent?: EventNodeRequest
  liveEventSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        EventNodeConnectionRequest,
      ]
    | EventNodeConnectionRequest
  liveEvent?: EventNodeRequest
  recentlyEndedEventSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        EventNodeConnectionRequest,
      ]
    | EventNodeConnectionRequest
  recentlyEndedEvent?: EventNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BoxBreakNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  format?: FormatNodeRequest
  /** Display name for this break (e.g. "2021 Topps Definitive Baseball 3x Hobby Box Break: 2 Random Teams") */
  name?: boolean | number
  /** URL identifier for this break */
  slug?: boolean | number
  /** Assign a featured rank to promote this break. Higher values take priority. Use sparingly; leave blank for most breaks. */
  featuredRank?: boolean | number
  /** A text description of this break, for example outlining the break format (random teams, PYT...) and products being broken. */
  description?: boolean | number
  /** Date and time at which this break is scheduled to begin. Presented to users as approximate. Leave blank if break is not yet scheduled. */
  scheduledStartTime?: boolean | number
  createdAt?: boolean | number
  createdBy?: UserTypeRequest
  state?: boolean | number
  /** Date and time at which this break was published onto the live site. */
  publishedAt?: boolean | number
  /** Date and time at which the break opened for entries. */
  openedAt?: boolean | number
  /** Date and time at which the live break began. */
  startedAt?: boolean | number
  /** Date and time at which the break stopped accepting new entries. */
  lockedAt?: boolean | number
  /** Date and time at which the live break concluded. */
  endedAt?: boolean | number
  /** Date and time at which the break's items completed processing. */
  settledAt?: boolean | number
  /** The 11-digit video ID for the YouTube video this break will be streamed on. */
  youtubeVideoId?: boolean | number
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId?: boolean | number
  /** The name for the Twitch channel this break will be streamed on. */
  twitchChannelName?: boolean | number
  breakLocation?: BreakLocationNodeRequest
  /** Choose where the event is visible and who can access it. */
  visibility?: boolean | number
  /** Optional restriction on how many spots a participant can claim. */
  maxSpotsPerUser?: boolean | number
  /** Optional URL to link to buy page for reMints */
  purchaseOverrideUrl?: boolean | number
  /** Break in which this box is going to be or was opened. */
  boxSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        BoxNodeConnectionRequest,
      ]
    | BoxNodeConnectionRequest
  boxbreakphotoSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        BoxBreakPhotoNodeConnectionRequest,
      ]
    | BoxBreakPhotoNodeConnectionRequest
  spotSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        SpotNodeConnectionRequest,
      ]
    | SpotNodeConnectionRequest
  /** The scheduled personal break that the user would like their box broken as part of. */
  personalbreakrequestSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        PersonalBreakRequestNodeConnectionRequest,
      ]
    | PersonalBreakRequestNodeConnectionRequest
  /** Break in which this nft item is going to be or was opened. */
  remintnftSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        RemintNFTNodeConnectionRequest,
      ]
    | RemintNFTNodeConnectionRequest
  event?: EventNodeRequest
  myParticipant?: ParticipantNodeRequest
  hitSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        HitNodeConnectionRequest,
      ]
    | HitNodeConnectionRequest
  spotsTotalCount?: boolean | number
  spotsAvailableCount?: boolean | number
  spotsFilledCount?: boolean | number
  spotsMinPrice?: boolean | number
  spotsMaxPrice?: boolean | number
  spotsCurrency?: boolean | number
  allowPersonalBreakRequests?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface FormatNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** A descriptive name for this break format (e.g. "Random Team" or "Hit Draft") */
  name?: boolean | number
  /** Optional description of this break format, to inform users about how it works and why they might want to choose this format over another. */
  description?: boolean | number
  /** Indicates whether it is a group break, open to multiple participants, or a personal break for a single user. */
  breakCategory?: boolean | number
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  boxbreakSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          spotsAvailable?: Boolean | null
          state?: (String | null)[] | null
          stateGroup?: (String | null)[] | null
          featured?: Boolean | null
          breakCategory?: (String | null)[] | null
          allowPersonalBreakRequests?: Boolean | null
          /** Ordering */
          orderBy?: String | null
        },
        BoxBreakNodeConnectionRequest,
      ]
    | BoxBreakNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BoxBreakNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: BoxBreakNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `BoxBreakNode` and its cursor. */
export interface BoxBreakNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: BoxBreakNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserTypeRequest {
  id?: boolean | number
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username?: boolean | number
  firstName?: boolean | number
  lastName?: boolean | number
  email?: boolean | number
  discorduser?: DiscordUserTypeRequest
  userprofile?: UserProfileTypeRequest
  teamMemberships?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          role?: String | null
        },
        TeamMemberNodeConnectionRequest,
      ]
    | TeamMemberNodeConnectionRequest
  balance?: boolean | number
  isStaff?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DiscordUserTypeRequest {
  id?: boolean | number
  discordId?: boolean | number
  discordUsername?: boolean | number
  discordDiscriminator?: boolean | number
  user?: UserTypeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserProfileTypeRequest {
  id?: boolean | number
  emailAddress?: boolean | number
  marketingEmails?: boolean | number
  buyTargetPercentage?: boolean | number
  sellTargetPercentage?: boolean | number
  dailyDigestHour?: boolean | number
  isCollectionPublic?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface TeamMemberNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: TeamMemberNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `TeamMemberNode` and its cursor. */
export interface TeamMemberNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: TeamMemberNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface TeamMemberNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  user?: UserTypeRequest
  channel?: ChannelNodeRequest
  role?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ChannelNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  name?: boolean | number
  /** User-facing description of this channel and its focus. */
  description?: boolean | number
  /** URL identifier for this channel. */
  slug?: boolean | number
  bannerImageUrl?:
    | [{ width?: Int | null; height?: Int | null; crop?: String | null; quality?: Int | null }]
    | boolean
    | number
  profileImageUrl?:
    | [{ width?: Int | null; height?: Int | null; crop?: String | null; quality?: Int | null }]
    | boolean
    | number
  bannerImage?: ImageNodeRequest
  profileImage?: ImageNodeRequest
  auctions?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          era?: String | null
          state?: (String | null)[] | null
          featured?: Boolean | null
          currency?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        AuctionNodeConnectionRequest,
      ]
    | AuctionNodeConnectionRequest
  teamMembers?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          role?: String | null
        },
        TeamMemberNodeConnectionRequest,
      ]
    | TeamMemberNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ImageNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  createdAt?: boolean | number
  url?: [{ width?: Int | null; height?: Int | null; crop?: String | null; quality?: Int | null }] | boolean | number
  originalUrl?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AuctionNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: AuctionNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `AuctionNode` and its cursor. */
export interface AuctionNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: AuctionNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AuctionNodeRequest {
  channel?: ChannelNodeRequest
  name?: boolean | number
  /** URL identifier for this auction. */
  slug?: boolean | number
  /** Assign a featured rank to promote this auction. Higher values take priority. Use sparingly; leave blank for most auctions. */
  featuredRank?: boolean | number
  /** A longer text description of this auction, such as an overview of the items included, special guests participating in the stream, and so on. */
  description?: boolean | number
  state?: boolean | number
  /** Date and time at which this auction is scheduled to begin. Presented to users as approximate. Leave blank if auction is not yet scheduled. */
  scheduledStartTime?: boolean | number
  /** The 11-digit video ID for the YouTube video this auction will be streamed on. */
  youtubeVideoId?: boolean | number
  /** The name for the Twitch channel this auction will be streamed on. */
  twitchChannelName?: boolean | number
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId?: boolean | number
  /** Controls how and when included lots are opened for bidding. */
  lotsOpenBidding?: boolean | number
  /** The date and time that included lots should open for bidding. Leave blank if auction is configured to open lots immediately. */
  lotsOpenBiddingAt?: boolean | number
  /** The date and time that the first lot is scheduled to conclude bidding. */
  lotsConcludeBiddingFrom?: boolean | number
  /** The time period, in seconds, between the scheduled end times of adjacent lots. */
  lotInterval?: boolean | number
  /** The time period, in seconds, at the end of a bidding window where new bids will reset the countdown to this time remaining. */
  lotBidExtensionPeriod?: boolean | number
  currency?: boolean | number
  /** Choose where the event is visible and who can access it. */
  visibility?: boolean | number
  /** Tag to allow Discord channels to filter content to share in their server. */
  discordFilterTag?: boolean | number
  auctionphotoSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        AuctionPhotoNodeConnectionRequest,
      ]
    | AuctionPhotoNodeConnectionRequest
  lotSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        LotNodeConnectionRequest,
      ]
    | LotNodeConnectionRequest
  /** The ID of the object. */
  id?: boolean | number
  lotCount?: boolean | number
  lotMinOpeningPrice?: boolean | number
  lotMaxOpeningPrice?: boolean | number
  lotRemainingCount?: boolean | number
  pusherChannelId?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AuctionPhotoNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: AuctionPhotoNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `AuctionPhotoNode` and its cursor. */
export interface AuctionPhotoNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: AuctionPhotoNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AuctionPhotoNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  auction?: AuctionNodeRequest
  photo?: boolean | number
  createdAt?: boolean | number
  photoXs?: boolean | number
  photoSm?: boolean | number
  photoMd?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LotNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: LotNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `LotNode` and its cursor. */
export interface LotNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: LotNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LotNodeRequest {
  order?: boolean | number
  /** An optional name which will overwrite the NFT name. */
  name?: boolean | number
  /** An optional description which will overwrite the NFT description if it has one. */
  description?: boolean | number
  auction?: AuctionNodeRequest
  /** The NFT item to be sold in this lot. If not found here please import via the staff page. */
  nftItem?: NFTItemNodeRequest
  nftApprovalState?: boolean | number
  /** Optional URL to link out to further details about this NFT. Leave blank to default to the NFT's OpenSea page. */
  externalUrl?: boolean | number
  /** Wallet address for seller of lot */
  sellerWalletAddress?: boolean | number
  currency?: boolean | number
  openingPrice?: boolean | number
  initialDuration?: boolean | number
  /** Fixed end time for the initial bidding period for this lot. Set only one of initial duration or initial end time, based on whether a precise window or precise conclusion time are more important. */
  initialEndTime?: boolean | number
  /** Optional scheduled start time to automatically open bidding for this lot. Leave blank to manually handle opening bidding. */
  scheduledStartTime?: boolean | number
  newBidMinimumDuration?: boolean | number
  state?: boolean | number
  /** Date and time at which this lot opened for bidding. */
  biddingOpenedAt?: boolean | number
  /** Date and time at which this lot is scheduled to conclude bidding. */
  biddingEndsAfter?: boolean | number
  /** Date and time at which this lot concluded bidding. */
  biddingEndedAt?: boolean | number
  bidSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        BidNodeConnectionRequest,
      ]
    | BidNodeConnectionRequest
  lotphotoSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        LotPhotoNodeConnectionRequest,
      ]
    | LotPhotoNodeConnectionRequest
  /** The ID of the object. */
  id?: boolean | number
  bidCount?: boolean | number
  currentPrice?: boolean | number
  highestBid?: BidNodeRequest
  highestBidPrice?: boolean | number
  bidPriceIncrement?: boolean | number
  minimumNextBidPrice?: boolean | number
  buyersPremiumPercentage?: boolean | number
  buyersPremiumFee?: boolean | number
  finalPurchasePrice?: boolean | number
  nftExternalUrl?: boolean | number
  smartContractLotId?: boolean | number
  nftCollectionName?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface NFTItemNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  name?: boolean | number
  description?: boolean | number
  tokenType?: boolean | number
  tokenAddress?: boolean | number
  tokenId?: boolean | number
  /** The token_uri as it was imported from a third party service. */
  importedTokenUri?: boolean | number
  /** If the token URI uses ipfs then this is the canonical location in the format of ipfs://<ipfs_path>. */
  canonicalTokenUri?: boolean | number
  collection?: NFTCollectionNodeRequest
  /** The image_uri as it was imported from a third party service. */
  importedImageUri?: boolean | number
  /** If the image uses ipfs then this is the canonical location in the format of ipfs://<ipfs_path>. */
  canonicalImageUri?: boolean | number
  chain?: boolean | number
  data?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  cachedImage?: ImageNodeRequest
  nftitemtraitSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        NFTItemTraitNodeConnectionRequest,
      ]
    | NFTItemTraitNodeConnectionRequest
  /** The NFT item to be sold in this lot. If not found here please import via the staff page. */
  lotSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          state?: (String | null)[] | null
          /** Ordering */
          orderBy?: String | null
        },
        LotNodeConnectionRequest,
      ]
    | LotNodeConnectionRequest
  tokenAddressAndTokenId?: boolean | number
  openseaUrl?: boolean | number
  cachedImageUrl?:
    | [{ width?: Int | null; height?: Int | null; crop?: String | null; quality?: Int | null }]
    | boolean
    | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface NFTCollectionNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  name?: boolean | number
  description?: boolean | number
  createdAt?: boolean | number
  updatedAt?: boolean | number
  nftitemSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          chain?: String | null
          collection?: ID | null
          tokenAddress?: String | null
          tokenId?: String | null
          tokenAddressAndTokenIds?: (String | null)[] | null
        },
        NFTItemNodeConnectionRequest,
      ]
    | NFTItemNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface NFTItemNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: NFTItemNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `NFTItemNode` and its cursor. */
export interface NFTItemNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: NFTItemNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface NFTItemTraitNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: NFTItemTraitNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `NFTItemTraitNode` and its cursor. */
export interface NFTItemTraitNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: NFTItemTraitNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface NFTItemTraitNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  nft?: NFTItemNodeRequest
  name?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BidNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: BidNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `BidNode` and its cursor. */
export interface BidNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: BidNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BidNodeRequest {
  lot?: LotNodeRequest
  user?: UserTypeRequest
  bidPrice?: boolean | number
  createdAt?: boolean | number
  state?: boolean | number
  /** The ID of the object. */
  id?: boolean | number
  bidPriceCurrency?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LotPhotoNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: LotPhotoNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `LotPhotoNode` and its cursor. */
export interface LotPhotoNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: LotPhotoNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface LotPhotoNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  lot?: LotNodeRequest
  photo?: boolean | number
  createdAt?: boolean | number
  photoXs?: boolean | number
  photoSm?: boolean | number
  photoMd?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BreakLocationNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** e.g. StarStock New York office, Partner Studio... */
  name?: boolean | number
  /** Enable to allow users to request an item in their collection be broken during a personal break. */
  allowPersonalBreakRequests?: boolean | number
  boxbreakSet?:
    | [
        {
          offset?: Int | null
          before?: String | null
          after?: String | null
          first?: Int | null
          last?: Int | null
          query?: String | null
          spotsAvailable?: Boolean | null
          state?: (String | null)[] | null
          stateGroup?: (String | null)[] | null
          featured?: Boolean | null
          breakCategory?: (String | null)[] | null
          allowPersonalBreakRequests?: Boolean | null
          /** Ordering */
          orderBy?: String | null
        },
        BoxBreakNodeConnectionRequest,
      ]
    | BoxBreakNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BoxNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: BoxNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `BoxNode` and its cursor. */
export interface BoxNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: BoxNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BoxNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** Optional display name for this this breakable product (e.g. Prizm Football 2020 Hobby Box). If blank, name is taken from associated product type. */
  name?: boolean | number
  /** Break in which this box is going to be or was opened. */
  boxBreak?: BoxBreakNodeRequest
  createdAt?: boolean | number
  createdBy?: UserTypeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BoxBreakPhotoNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: BoxBreakPhotoNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `BoxBreakPhotoNode` and its cursor. */
export interface BoxBreakPhotoNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: BoxBreakPhotoNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BoxBreakPhotoNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  boxBreak?: BoxBreakNodeRequest
  photo?: boolean | number
  createdAt?: boolean | number
  photoXs?: boolean | number
  photoSm?: boolean | number
  photoMd?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SpotNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: SpotNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `SpotNode` and its cursor. */
export interface SpotNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: SpotNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SpotNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  boxBreak?: BoxBreakNodeRequest
  /** Spots are ordered within the parent break by their rank (asc). Rank will also be used as slot name if one is not specified (e.g. random breaks). */
  rank?: boolean | number
  /** Optional display name for this spot in the break (e.g. Cleveland Browns), or may be left blank to use rank. */
  name?: boolean | number
  priceCurrency?: boolean | number
  price?: boolean | number
  purchasedAt?: boolean | number
  /** The spot in the break that this hit is assigned to. */
  hitSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        HitNodeConnectionRequest,
      ]
    | HitNodeConnectionRequest
  personalbreakrequest?: PersonalBreakRequestNodeRequest
  isPersonalBreakRequest?: boolean | number
  myParticipant?: ParticipantNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface HitNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: HitNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `HitNode` and its cursor. */
export interface HitNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: HitNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface HitNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** User-facing display name for this hit (e.g. "🔥 EPIC PULL! Tom Brady Prizm Auto Silver #/10!") */
  name?: boolean | number
  /** An optional longer description, giving context to the break or hit. */
  description?: boolean | number
  /** The spot in the break that this hit is assigned to. */
  spot?: SpotNodeRequest
  createdAt?: boolean | number
  createdBy?: UserTypeRequest
  photo?: boolean | number
  photoXs?: boolean | number
  photoSm?: boolean | number
  photoMd?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PersonalBreakRequestNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  user?: UserTypeRequest
  /** The scheduled personal break that the user would like their box broken as part of. */
  boxBreak?: BoxBreakNodeRequest
  /** The spot in the break that represents this accepted request. */
  spot?: SpotNodeRequest
  /** Optional, additional request information, such as a preferred start time. */
  requestDetails?: boolean | number
  breakFeeCurrency?: boolean | number
  breakFee?: boolean | number
  createdAt?: boolean | number
  state?: boolean | number
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome?: boolean | number
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions?: boolean | number
  address?: AddressNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AddressNodeRequest {
  description?: boolean | number
  name?: boolean | number
  addressLines?: boolean | number
  locality?: boolean | number
  region?: boolean | number
  postalCode?: boolean | number
  country?: boolean | number
  role?: boolean | number
  default?: boolean | number
  /** The ID of the object. */
  id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ParticipantNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  boxBreak?: BoxBreakNodeRequest
  user?: UserTypeRequest
  joinedAt?: boolean | number
  /** The streaming platform on which the participant plans to watch. */
  liveStreamPlatform?: boolean | number
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome?: boolean | number
  address?: AddressNodeRequest
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions?: boolean | number
  /** The break participant who has claimed this spot (note that one participant may claim multiple spots). */
  spotSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        SpotNodeConnectionRequest,
      ]
    | SpotNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PersonalBreakRequestNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: PersonalBreakRequestNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `PersonalBreakRequestNode` and its cursor. */
export interface PersonalBreakRequestNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: PersonalBreakRequestNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface RemintNFTNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: RemintNFTNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `RemintNFTNode` and its cursor. */
export interface RemintNFTNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: RemintNFTNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface RemintNFTNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** Display name for this nft item. */
  name?: boolean | number
  /** Display name for the collection this nft item belongs to. */
  collectionName?: boolean | number
  blockchain?: boolean | number
  /** Optional Link to external URL. */
  externalLink?: boolean | number
  /** Optional Name of external URL page. */
  externalLinkText?: boolean | number
  image?: boolean | number
  /** Break in which this nft item is going to be or was opened. */
  boxBreak?: BoxBreakNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface StreamNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: StreamNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `StreamNode` and its cursor. */
export interface StreamNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: StreamNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UserNotificationPreferenceNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  notificationType?: boolean | number
  targetType?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface NFTCollectionNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: NFTCollectionNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `NFTCollectionNode` and its cursor. */
export interface NFTCollectionNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: NFTCollectionNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DepositNodeRequest {
  id?: boolean | number
  user?: UserTypeRequest
  /** Determines what state the bidding deposit is in */
  depositStatus?: boolean | number
  biddingEnabled?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ChannelNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: ChannelNodeEdgeRequest
  totalCount?: boolean | number
  edgeCount?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `ChannelNode` and its cursor. */
export interface ChannelNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: ChannelNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AnnouncementNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  state?: boolean | number
  /** Automatically generated. Example: 2021-10-16-submit-for-grading-update */
  slug?: boolean | number
  /** Example: Submit for grading update */
  name?: boolean | number
  /** Text that will be highlighted in the announcement banner  */
  prefixText?: boolean | number
  /** Announcement body content */
  bodyText?: boolean | number
  /** Link title */
  linkTitle?: boolean | number
  /** URL linking to the announced target */
  linkUrl?: boolean | number
  style?: AnnouncementStyleNodeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AnnouncementStyleNodeRequest {
  /** The ID of the object. */
  id?: boolean | number
  /** Examples: Breaks, Product announcements, Technical announcements, Marketing, etc. */
  name?: boolean | number
  badgeTextColor?: boolean | number
  linkTextColor?: boolean | number
  badgeGradientStart?: boolean | number
  badgeGradientEnd?: boolean | number
  announcementbannerSet?:
    | [
        { offset?: Int | null; before?: String | null; after?: String | null; first?: Int | null; last?: Int | null },
        AnnouncementNodeConnectionRequest,
      ]
    | AnnouncementNodeConnectionRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AnnouncementNodeConnectionRequest {
  /** Pagination data for this connection. */
  pageInfo?: PageInfoRequest
  /** Contains the nodes in this connection. */
  edges?: AnnouncementNodeEdgeRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** A Relay edge containing a `AnnouncementNode` and its cursor. */
export interface AnnouncementNodeEdgeRequest {
  /** The item at the end of the edge */
  node?: AnnouncementNodeRequest
  /** A cursor for use in pagination */
  cursor?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PromotionTypeRequest {
  /** The ID of the object. */
  id?: boolean | number
  description?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface MutationRequest {
  createPaymentIntent?: [{ amount: Int; saveCard?: Boolean | null }, CreatePaymentIntentRequest]
  createWithdrawalRequest?: [
    {
      addressLine1?: String | null
      addressLine2?: String | null
      amount: Float
      checkRecipient?: String | null
      city?: String | null
      country?: String | null
      paymentMethod: WithdrawalPaymentMethods
      paypalEmail?: String | null
      postalTracking?: Boolean | null
      state?: String | null
      zipCode?: String | null
    },
    CreateWithdrawalRequestRequest,
  ]
  deletePaymentMethod?: [{ paymentMethodId?: String | null }, DeletePaymentMethodRequest] | DeletePaymentMethodRequest
  setDefaultPaymentMethod?:
    | [{ paymentMethodId?: String | null }, SetDefaultPaymentMethodRequest]
    | SetDefaultPaymentMethodRequest
  createSetupIntent?: CreateSetupIntentRequest
  updateCryptoWalletBalance?: [
    { balanceAmount: Float; balanceCurrency: CryptoCurrencies; cryptoWalletId: String },
    UpdateCryptoWalletBalanceRequest,
  ]
  updateNotificationPreferences?:
    | [
        {
          buyTargetPercentage?: Int | null
          notificationPreferences?: (UpdateNotificationPreferencesInput | null)[] | null
          sellTargetPercentage?: Int | null
        },
        UpdateNotificationPreferencesRequest,
      ]
    | UpdateNotificationPreferencesRequest
  withdrawDepositRequestCreate?: WithdrawDepositRequestCreateRequest
  editChannel?: [{ channelId: ID; description?: String | null; name?: String | null }, EditChannelRequest]
  removeTeamMemberFromChannel?: [{ teamMemberId: ID }, RemoveTeamMemberFromChannelRequest]
  uploadChannelBannerImage?: [{ bannerImage: Upload; channelId: ID }, UploadChannelBannerImageRequest]
  uploadChannelProfileImage?: [{ channelId: ID; profileImage: Upload }, UploadChannelProfileImageRequest]
  addTeamMemberToChannel?: [{ channelId: ID; role: String; walletAddress: String }, AddTeamMemberToChannelRequest]
  buyBoxBreakSpots?: [
    {
      additionalInstructions?: String | null
      addressId: ID
      boxBreakId: ID
      paymentMethod: String
      preferShipHome?: Boolean | null
      spotIds: (ID | null)[]
    },
    BuyBoxBreakSpotsRequest,
  ]
  auctionCreate?: [{ channelId: ID; name: String }, AuctionCreateRequest]
  auctionDraftDelete?: [{ auctionId: ID }, AuctionDraftDeleteRequest]
  auctionDraftEdit?: [
    {
      auctionId: ID
      description?: String | null
      name?: String | null
      scheduledStartTime?: DateTime | null
      twitchChannelName?: String | null
      twitchVideoId?: String | null
      youtubeVideoId?: String | null
    },
    AuctionDraftEditRequest,
  ]
  bidCreate?: [{ bidCurrency?: String | null; bidPrice: Float; lotId: ID }, BidCreateRequest]
  bidTransitionToPendingReview?: [{ bidId: ID }, BidTransitionToPendingReviewRequest]
  discordBotBidCreate?: [
    { bidCurrency?: String | null; bidPrice: Float; botHmacDigest: String; discordId: String; lotId: ID },
    DiscordBotBidCreateRequest,
  ]
  updateLotNftApprovalStateToApproving?: [{ lotId: ID }, UpdateLotNftApprovalStateToApprovingRequest]
  updateLotStateToCompletingSale?: [{ lotId: ID }, UpdateLotStateToCompletingSaleRequest]
  /** Create a brand new address for the logged-in user. */
  createAddress?: [
    {
      addressLines: String
      country: String
      description: String
      locality: String
      name: String
      postalCode: String
      region: String
      role: String
    },
    CreateAddressRequest,
  ]
  /** Hide a specified address for the logged-in user. */
  deleteAddress?: [{ id: ID }, DeleteAddressRequest]
  /** Hide the existing address and create a replacement with the specified changes. */
  updateAddress?: [
    {
      addressLines: String
      country: String
      description: String
      id: ID
      locality: String
      name: String
      postalCode: String
      region: String
      role: String
    },
    UpdateAddressRequest,
  ]
  /** Mark an existing address as the default. */
  makeAddressDefault?: [{ id: ID }, MakeAddressDefaultRequest]
  accountRegister?: [
    {
      email: String
      firstName: String
      lastName: String
      marketingEmails: Boolean
      password: String
      promoCode?: String | null
      username: String
    },
    AccountRegisterRequest,
  ]
  disconnectDiscordUser?: DisconnectDiscordUserRequest
  editUserProfile?: [{ emailAddress?: String | null }, EditUserProfileRequest] | EditUserProfileRequest
  generateDiscordToken?: [
    { discordDiscriminator: String; discordId: String; discordUsername: String },
    GenerateDiscordTokenRequest,
  ]
  passwordChange?:
    | [{ newPassword?: String | null; oldPassword?: String | null }, PasswordChangeRequest]
    | PasswordChangeRequest
  passwordResetRequest?: [{ username?: String | null }, PasswordResetRequestRequest] | PasswordResetRequestRequest
  passwordResetChange?:
    | [{ newPassword?: String | null; token?: String | null; userIdB64?: String | null }, PasswordResetChangeRequest]
    | PasswordResetChangeRequest
  tokenAuth?: [{ username: String; password: String }, ObtainJSONWebTokenRequest]
  updateUserCollectionVisibility?: [{ isPublic: Boolean }, UpdateUserCollectionVisibilityRequest]
  verifyDiscordToken?: [{ token: String }, VerifyDiscordTokenRequest]
  walletAuth?: [
    { cryptoWalletId: String; logOutUser?: Boolean | null; moralisToken: String; moralisUserId: String; signature: String },
    WalletAuthRequest,
  ]
  verifyToken?: [{ token?: String | null }, VerifyRequest] | VerifyRequest
  refreshToken?: [{ refreshToken?: String | null }, RefreshRequest] | RefreshRequest
  deleteTokenCookie?: DeleteJSONWebTokenCookieRequest
  deleteRefreshTokenCookie?: DeleteRefreshTokenCookieRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CreatePaymentIntentRequest {
  paymentIntentToken?: boolean | number
  paymentSequence?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CreateWithdrawalRequestRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Represents an error in the input of a mutation. */
export interface ErrorRequest {
  /** Name of the field that caused the error. A value of `null` indicates that the error isn't associated with a particular field. */
  field?: boolean | number
  /** The error message. */
  message?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DeletePaymentMethodRequest {
  paymentMethodId?: boolean | number
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface SetDefaultPaymentMethodRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface CreateSetupIntentRequest {
  setupIntentClientSecret?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UpdateCryptoWalletBalanceRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UpdateNotificationPreferencesInput {
  notificationType: NotificationTypes
  targetType: TargetTypes
}

export interface UpdateNotificationPreferencesRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface WithdrawDepositRequestCreateRequest {
  withdrawDepositRequest?: WithdrawDepositRequestNodeRequest
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface WithdrawDepositRequestNodeRequest {
  id?: boolean | number
  user?: UserTypeRequest
  status?: boolean | number
  createdAt?: boolean | number
  refundedAt?: boolean | number
  notes?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface EditChannelRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface RemoveTeamMemberFromChannelRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UploadChannelBannerImageRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UploadChannelProfileImageRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AddTeamMemberToChannelRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BuyBoxBreakSpotsRequest {
  purchasedSpotsCount?: boolean | number
  purchasedSpotsCost?: boolean | number
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AuctionCreateRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AuctionDraftDeleteRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AuctionDraftEditRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BidCreateRequest {
  bid?: BidNodeRequest
  lot?: LotNodeRequest
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface BidTransitionToPendingReviewRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DiscordBotBidCreateRequest {
  bid?: BidNodeRequest
  lot?: LotNodeRequest
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UpdateLotNftApprovalStateToApprovingRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UpdateLotStateToCompletingSaleRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Create a brand new address for the logged-in user. */
export interface CreateAddressRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Hide a specified address for the logged-in user. */
export interface DeleteAddressRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Hide the existing address and create a replacement with the specified changes. */
export interface UpdateAddressRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Mark an existing address as the default. */
export interface MakeAddressDefaultRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface AccountRegisterRequest {
  user?: UserTypeRequest
  refreshToken?: boolean | number
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DisconnectDiscordUserRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface EditUserProfileRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface GenerateDiscordTokenRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  token?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PasswordChangeRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PasswordResetRequestRequest {
  success?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface PasswordResetChangeRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface ObtainJSONWebTokenRequest {
  payload?: boolean | number
  refreshExpiresIn?: boolean | number
  token?: boolean | number
  refreshToken?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface UpdateUserCollectionVisibilityRequest {
  ok?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VerifyDiscordTokenRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface WalletAuthRequest {
  /** List of errors that occurred executing the mutation. */
  errors?: ErrorRequest
  token?: boolean | number
  refreshToken?: boolean | number
  refreshExpiresIn?: boolean | number
  exp?: boolean | number
  origIat?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface VerifyRequest {
  payload?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface RefreshRequest {
  payload?: boolean | number
  refreshExpiresIn?: boolean | number
  token?: boolean | number
  refreshToken?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DeleteJSONWebTokenCookieRequest {
  deleted?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface DeleteRefreshTokenCookieRequest {
  deleted?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

const Query_possibleTypes = ['Query']
export const isQuery = (obj: { __typename: String }): obj is Query => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Query_possibleTypes.includes(obj.__typename)
}

const StripePaymentMethodNode_possibleTypes = ['StripePaymentMethodNode']
export const isStripePaymentMethodNode = (obj: { __typename: String }): obj is StripePaymentMethodNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return StripePaymentMethodNode_possibleTypes.includes(obj.__typename)
}

const Node_possibleTypes = [
  'StripePaymentMethodNode',
  'StreamingChannelNode',
  'EventNode',
  'StreamNode',
  'BoxBreakNode',
  'FormatNode',
  'TeamMemberNode',
  'ChannelNode',
  'ImageNode',
  'AuctionNode',
  'AuctionPhotoNode',
  'LotNode',
  'NFTItemNode',
  'NFTCollectionNode',
  'NFTItemTraitNode',
  'BidNode',
  'LotPhotoNode',
  'BreakLocationNode',
  'BoxNode',
  'BoxBreakPhotoNode',
  'SpotNode',
  'HitNode',
  'PersonalBreakRequestNode',
  'AddressNode',
  'ParticipantNode',
  'RemintNFTNode',
  'UserNotificationPreferenceNode',
  'AnnouncementNode',
  'AnnouncementStyleNode',
  'PromotionType',
]
export const isNode = (obj: { __typename: String }): obj is Node => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Node_possibleTypes.includes(obj.__typename)
}

const StreamingChannelNodeConnection_possibleTypes = ['StreamingChannelNodeConnection']
export const isStreamingChannelNodeConnection = (obj: { __typename: String }): obj is StreamingChannelNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return StreamingChannelNodeConnection_possibleTypes.includes(obj.__typename)
}

const PageInfo_possibleTypes = ['PageInfo']
export const isPageInfo = (obj: { __typename: String }): obj is PageInfo => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PageInfo_possibleTypes.includes(obj.__typename)
}

const StreamingChannelNodeEdge_possibleTypes = ['StreamingChannelNodeEdge']
export const isStreamingChannelNodeEdge = (obj: { __typename: String }): obj is StreamingChannelNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return StreamingChannelNodeEdge_possibleTypes.includes(obj.__typename)
}

const StreamingChannelNode_possibleTypes = ['StreamingChannelNode']
export const isStreamingChannelNode = (obj: { __typename: String }): obj is StreamingChannelNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return StreamingChannelNode_possibleTypes.includes(obj.__typename)
}

const EventNodeConnection_possibleTypes = ['EventNodeConnection']
export const isEventNodeConnection = (obj: { __typename: String }): obj is EventNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return EventNodeConnection_possibleTypes.includes(obj.__typename)
}

const EventNodeEdge_possibleTypes = ['EventNodeEdge']
export const isEventNodeEdge = (obj: { __typename: String }): obj is EventNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return EventNodeEdge_possibleTypes.includes(obj.__typename)
}

const EventNode_possibleTypes = ['EventNode']
export const isEventNode = (obj: { __typename: String }): obj is EventNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return EventNode_possibleTypes.includes(obj.__typename)
}

const StreamNode_possibleTypes = ['StreamNode']
export const isStreamNode = (obj: { __typename: String }): obj is StreamNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return StreamNode_possibleTypes.includes(obj.__typename)
}

const BoxBreakNode_possibleTypes = ['BoxBreakNode']
export const isBoxBreakNode = (obj: { __typename: String }): obj is BoxBreakNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxBreakNode_possibleTypes.includes(obj.__typename)
}

const FormatNode_possibleTypes = ['FormatNode']
export const isFormatNode = (obj: { __typename: String }): obj is FormatNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return FormatNode_possibleTypes.includes(obj.__typename)
}

const BoxBreakNodeConnection_possibleTypes = ['BoxBreakNodeConnection']
export const isBoxBreakNodeConnection = (obj: { __typename: String }): obj is BoxBreakNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxBreakNodeConnection_possibleTypes.includes(obj.__typename)
}

const BoxBreakNodeEdge_possibleTypes = ['BoxBreakNodeEdge']
export const isBoxBreakNodeEdge = (obj: { __typename: String }): obj is BoxBreakNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxBreakNodeEdge_possibleTypes.includes(obj.__typename)
}

const UserType_possibleTypes = ['UserType']
export const isUserType = (obj: { __typename: String }): obj is UserType => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UserType_possibleTypes.includes(obj.__typename)
}

const DiscordUserType_possibleTypes = ['DiscordUserType']
export const isDiscordUserType = (obj: { __typename: String }): obj is DiscordUserType => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DiscordUserType_possibleTypes.includes(obj.__typename)
}

const UserProfileType_possibleTypes = ['UserProfileType']
export const isUserProfileType = (obj: { __typename: String }): obj is UserProfileType => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UserProfileType_possibleTypes.includes(obj.__typename)
}

const TeamMemberNodeConnection_possibleTypes = ['TeamMemberNodeConnection']
export const isTeamMemberNodeConnection = (obj: { __typename: String }): obj is TeamMemberNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return TeamMemberNodeConnection_possibleTypes.includes(obj.__typename)
}

const TeamMemberNodeEdge_possibleTypes = ['TeamMemberNodeEdge']
export const isTeamMemberNodeEdge = (obj: { __typename: String }): obj is TeamMemberNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return TeamMemberNodeEdge_possibleTypes.includes(obj.__typename)
}

const TeamMemberNode_possibleTypes = ['TeamMemberNode']
export const isTeamMemberNode = (obj: { __typename: String }): obj is TeamMemberNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return TeamMemberNode_possibleTypes.includes(obj.__typename)
}

const ChannelNode_possibleTypes = ['ChannelNode']
export const isChannelNode = (obj: { __typename: String }): obj is ChannelNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ChannelNode_possibleTypes.includes(obj.__typename)
}

const ImageNode_possibleTypes = ['ImageNode']
export const isImageNode = (obj: { __typename: String }): obj is ImageNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ImageNode_possibleTypes.includes(obj.__typename)
}

const AuctionNodeConnection_possibleTypes = ['AuctionNodeConnection']
export const isAuctionNodeConnection = (obj: { __typename: String }): obj is AuctionNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionNodeConnection_possibleTypes.includes(obj.__typename)
}

const AuctionNodeEdge_possibleTypes = ['AuctionNodeEdge']
export const isAuctionNodeEdge = (obj: { __typename: String }): obj is AuctionNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionNodeEdge_possibleTypes.includes(obj.__typename)
}

const AuctionNode_possibleTypes = ['AuctionNode']
export const isAuctionNode = (obj: { __typename: String }): obj is AuctionNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionNode_possibleTypes.includes(obj.__typename)
}

const AuctionPhotoNodeConnection_possibleTypes = ['AuctionPhotoNodeConnection']
export const isAuctionPhotoNodeConnection = (obj: { __typename: String }): obj is AuctionPhotoNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionPhotoNodeConnection_possibleTypes.includes(obj.__typename)
}

const AuctionPhotoNodeEdge_possibleTypes = ['AuctionPhotoNodeEdge']
export const isAuctionPhotoNodeEdge = (obj: { __typename: String }): obj is AuctionPhotoNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionPhotoNodeEdge_possibleTypes.includes(obj.__typename)
}

const AuctionPhotoNode_possibleTypes = ['AuctionPhotoNode']
export const isAuctionPhotoNode = (obj: { __typename: String }): obj is AuctionPhotoNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionPhotoNode_possibleTypes.includes(obj.__typename)
}

const LotNodeConnection_possibleTypes = ['LotNodeConnection']
export const isLotNodeConnection = (obj: { __typename: String }): obj is LotNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotNodeConnection_possibleTypes.includes(obj.__typename)
}

const LotNodeEdge_possibleTypes = ['LotNodeEdge']
export const isLotNodeEdge = (obj: { __typename: String }): obj is LotNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotNodeEdge_possibleTypes.includes(obj.__typename)
}

const LotNode_possibleTypes = ['LotNode']
export const isLotNode = (obj: { __typename: String }): obj is LotNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotNode_possibleTypes.includes(obj.__typename)
}

const NFTItemNode_possibleTypes = ['NFTItemNode']
export const isNFTItemNode = (obj: { __typename: String }): obj is NFTItemNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return NFTItemNode_possibleTypes.includes(obj.__typename)
}

const NFTCollectionNode_possibleTypes = ['NFTCollectionNode']
export const isNFTCollectionNode = (obj: { __typename: String }): obj is NFTCollectionNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return NFTCollectionNode_possibleTypes.includes(obj.__typename)
}

const NFTItemNodeConnection_possibleTypes = ['NFTItemNodeConnection']
export const isNFTItemNodeConnection = (obj: { __typename: String }): obj is NFTItemNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return NFTItemNodeConnection_possibleTypes.includes(obj.__typename)
}

const NFTItemNodeEdge_possibleTypes = ['NFTItemNodeEdge']
export const isNFTItemNodeEdge = (obj: { __typename: String }): obj is NFTItemNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return NFTItemNodeEdge_possibleTypes.includes(obj.__typename)
}

const NFTItemTraitNodeConnection_possibleTypes = ['NFTItemTraitNodeConnection']
export const isNFTItemTraitNodeConnection = (obj: { __typename: String }): obj is NFTItemTraitNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return NFTItemTraitNodeConnection_possibleTypes.includes(obj.__typename)
}

const NFTItemTraitNodeEdge_possibleTypes = ['NFTItemTraitNodeEdge']
export const isNFTItemTraitNodeEdge = (obj: { __typename: String }): obj is NFTItemTraitNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return NFTItemTraitNodeEdge_possibleTypes.includes(obj.__typename)
}

const NFTItemTraitNode_possibleTypes = ['NFTItemTraitNode']
export const isNFTItemTraitNode = (obj: { __typename: String }): obj is NFTItemTraitNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return NFTItemTraitNode_possibleTypes.includes(obj.__typename)
}

const BidNodeConnection_possibleTypes = ['BidNodeConnection']
export const isBidNodeConnection = (obj: { __typename: String }): obj is BidNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BidNodeConnection_possibleTypes.includes(obj.__typename)
}

const BidNodeEdge_possibleTypes = ['BidNodeEdge']
export const isBidNodeEdge = (obj: { __typename: String }): obj is BidNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BidNodeEdge_possibleTypes.includes(obj.__typename)
}

const BidNode_possibleTypes = ['BidNode']
export const isBidNode = (obj: { __typename: String }): obj is BidNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BidNode_possibleTypes.includes(obj.__typename)
}

const LotPhotoNodeConnection_possibleTypes = ['LotPhotoNodeConnection']
export const isLotPhotoNodeConnection = (obj: { __typename: String }): obj is LotPhotoNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotPhotoNodeConnection_possibleTypes.includes(obj.__typename)
}

const LotPhotoNodeEdge_possibleTypes = ['LotPhotoNodeEdge']
export const isLotPhotoNodeEdge = (obj: { __typename: String }): obj is LotPhotoNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotPhotoNodeEdge_possibleTypes.includes(obj.__typename)
}

const LotPhotoNode_possibleTypes = ['LotPhotoNode']
export const isLotPhotoNode = (obj: { __typename: String }): obj is LotPhotoNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return LotPhotoNode_possibleTypes.includes(obj.__typename)
}

const BreakLocationNode_possibleTypes = ['BreakLocationNode']
export const isBreakLocationNode = (obj: { __typename: String }): obj is BreakLocationNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BreakLocationNode_possibleTypes.includes(obj.__typename)
}

const BoxNodeConnection_possibleTypes = ['BoxNodeConnection']
export const isBoxNodeConnection = (obj: { __typename: String }): obj is BoxNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxNodeConnection_possibleTypes.includes(obj.__typename)
}

const BoxNodeEdge_possibleTypes = ['BoxNodeEdge']
export const isBoxNodeEdge = (obj: { __typename: String }): obj is BoxNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxNodeEdge_possibleTypes.includes(obj.__typename)
}

const BoxNode_possibleTypes = ['BoxNode']
export const isBoxNode = (obj: { __typename: String }): obj is BoxNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxNode_possibleTypes.includes(obj.__typename)
}

const BoxBreakPhotoNodeConnection_possibleTypes = ['BoxBreakPhotoNodeConnection']
export const isBoxBreakPhotoNodeConnection = (obj: { __typename: String }): obj is BoxBreakPhotoNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxBreakPhotoNodeConnection_possibleTypes.includes(obj.__typename)
}

const BoxBreakPhotoNodeEdge_possibleTypes = ['BoxBreakPhotoNodeEdge']
export const isBoxBreakPhotoNodeEdge = (obj: { __typename: String }): obj is BoxBreakPhotoNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxBreakPhotoNodeEdge_possibleTypes.includes(obj.__typename)
}

const BoxBreakPhotoNode_possibleTypes = ['BoxBreakPhotoNode']
export const isBoxBreakPhotoNode = (obj: { __typename: String }): obj is BoxBreakPhotoNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BoxBreakPhotoNode_possibleTypes.includes(obj.__typename)
}

const SpotNodeConnection_possibleTypes = ['SpotNodeConnection']
export const isSpotNodeConnection = (obj: { __typename: String }): obj is SpotNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SpotNodeConnection_possibleTypes.includes(obj.__typename)
}

const SpotNodeEdge_possibleTypes = ['SpotNodeEdge']
export const isSpotNodeEdge = (obj: { __typename: String }): obj is SpotNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SpotNodeEdge_possibleTypes.includes(obj.__typename)
}

const SpotNode_possibleTypes = ['SpotNode']
export const isSpotNode = (obj: { __typename: String }): obj is SpotNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SpotNode_possibleTypes.includes(obj.__typename)
}

const HitNodeConnection_possibleTypes = ['HitNodeConnection']
export const isHitNodeConnection = (obj: { __typename: String }): obj is HitNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return HitNodeConnection_possibleTypes.includes(obj.__typename)
}

const HitNodeEdge_possibleTypes = ['HitNodeEdge']
export const isHitNodeEdge = (obj: { __typename: String }): obj is HitNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return HitNodeEdge_possibleTypes.includes(obj.__typename)
}

const HitNode_possibleTypes = ['HitNode']
export const isHitNode = (obj: { __typename: String }): obj is HitNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return HitNode_possibleTypes.includes(obj.__typename)
}

const PersonalBreakRequestNode_possibleTypes = ['PersonalBreakRequestNode']
export const isPersonalBreakRequestNode = (obj: { __typename: String }): obj is PersonalBreakRequestNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PersonalBreakRequestNode_possibleTypes.includes(obj.__typename)
}

const AddressNode_possibleTypes = ['AddressNode']
export const isAddressNode = (obj: { __typename: String }): obj is AddressNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AddressNode_possibleTypes.includes(obj.__typename)
}

const ParticipantNode_possibleTypes = ['ParticipantNode']
export const isParticipantNode = (obj: { __typename: String }): obj is ParticipantNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ParticipantNode_possibleTypes.includes(obj.__typename)
}

const PersonalBreakRequestNodeConnection_possibleTypes = ['PersonalBreakRequestNodeConnection']
export const isPersonalBreakRequestNodeConnection = (obj: {
  __typename: String
}): obj is PersonalBreakRequestNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PersonalBreakRequestNodeConnection_possibleTypes.includes(obj.__typename)
}

const PersonalBreakRequestNodeEdge_possibleTypes = ['PersonalBreakRequestNodeEdge']
export const isPersonalBreakRequestNodeEdge = (obj: { __typename: String }): obj is PersonalBreakRequestNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PersonalBreakRequestNodeEdge_possibleTypes.includes(obj.__typename)
}

const RemintNFTNodeConnection_possibleTypes = ['RemintNFTNodeConnection']
export const isRemintNFTNodeConnection = (obj: { __typename: String }): obj is RemintNFTNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return RemintNFTNodeConnection_possibleTypes.includes(obj.__typename)
}

const RemintNFTNodeEdge_possibleTypes = ['RemintNFTNodeEdge']
export const isRemintNFTNodeEdge = (obj: { __typename: String }): obj is RemintNFTNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return RemintNFTNodeEdge_possibleTypes.includes(obj.__typename)
}

const RemintNFTNode_possibleTypes = ['RemintNFTNode']
export const isRemintNFTNode = (obj: { __typename: String }): obj is RemintNFTNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return RemintNFTNode_possibleTypes.includes(obj.__typename)
}

const StreamNodeConnection_possibleTypes = ['StreamNodeConnection']
export const isStreamNodeConnection = (obj: { __typename: String }): obj is StreamNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return StreamNodeConnection_possibleTypes.includes(obj.__typename)
}

const StreamNodeEdge_possibleTypes = ['StreamNodeEdge']
export const isStreamNodeEdge = (obj: { __typename: String }): obj is StreamNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return StreamNodeEdge_possibleTypes.includes(obj.__typename)
}

const UserNotificationPreferenceNode_possibleTypes = ['UserNotificationPreferenceNode']
export const isUserNotificationPreferenceNode = (obj: { __typename: String }): obj is UserNotificationPreferenceNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UserNotificationPreferenceNode_possibleTypes.includes(obj.__typename)
}

const NFTCollectionNodeConnection_possibleTypes = ['NFTCollectionNodeConnection']
export const isNFTCollectionNodeConnection = (obj: { __typename: String }): obj is NFTCollectionNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return NFTCollectionNodeConnection_possibleTypes.includes(obj.__typename)
}

const NFTCollectionNodeEdge_possibleTypes = ['NFTCollectionNodeEdge']
export const isNFTCollectionNodeEdge = (obj: { __typename: String }): obj is NFTCollectionNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return NFTCollectionNodeEdge_possibleTypes.includes(obj.__typename)
}

const DepositNode_possibleTypes = ['DepositNode']
export const isDepositNode = (obj: { __typename: String }): obj is DepositNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DepositNode_possibleTypes.includes(obj.__typename)
}

const ChannelNodeConnection_possibleTypes = ['ChannelNodeConnection']
export const isChannelNodeConnection = (obj: { __typename: String }): obj is ChannelNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ChannelNodeConnection_possibleTypes.includes(obj.__typename)
}

const ChannelNodeEdge_possibleTypes = ['ChannelNodeEdge']
export const isChannelNodeEdge = (obj: { __typename: String }): obj is ChannelNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ChannelNodeEdge_possibleTypes.includes(obj.__typename)
}

const AnnouncementNode_possibleTypes = ['AnnouncementNode']
export const isAnnouncementNode = (obj: { __typename: String }): obj is AnnouncementNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnnouncementNode_possibleTypes.includes(obj.__typename)
}

const AnnouncementStyleNode_possibleTypes = ['AnnouncementStyleNode']
export const isAnnouncementStyleNode = (obj: { __typename: String }): obj is AnnouncementStyleNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnnouncementStyleNode_possibleTypes.includes(obj.__typename)
}

const AnnouncementNodeConnection_possibleTypes = ['AnnouncementNodeConnection']
export const isAnnouncementNodeConnection = (obj: { __typename: String }): obj is AnnouncementNodeConnection => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnnouncementNodeConnection_possibleTypes.includes(obj.__typename)
}

const AnnouncementNodeEdge_possibleTypes = ['AnnouncementNodeEdge']
export const isAnnouncementNodeEdge = (obj: { __typename: String }): obj is AnnouncementNodeEdge => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AnnouncementNodeEdge_possibleTypes.includes(obj.__typename)
}

const PromotionType_possibleTypes = ['PromotionType']
export const isPromotionType = (obj: { __typename: String }): obj is PromotionType => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PromotionType_possibleTypes.includes(obj.__typename)
}

const Mutation_possibleTypes = ['Mutation']
export const isMutation = (obj: { __typename: String }): obj is Mutation => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Mutation_possibleTypes.includes(obj.__typename)
}

const CreatePaymentIntent_possibleTypes = ['CreatePaymentIntent']
export const isCreatePaymentIntent = (obj: { __typename: String }): obj is CreatePaymentIntent => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CreatePaymentIntent_possibleTypes.includes(obj.__typename)
}

const CreateWithdrawalRequest_possibleTypes = ['CreateWithdrawalRequest']
export const isCreateWithdrawalRequest = (obj: { __typename: String }): obj is CreateWithdrawalRequest => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CreateWithdrawalRequest_possibleTypes.includes(obj.__typename)
}

const Error_possibleTypes = ['Error']
export const isError = (obj: { __typename: String }): obj is Error => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Error_possibleTypes.includes(obj.__typename)
}

const DeletePaymentMethod_possibleTypes = ['DeletePaymentMethod']
export const isDeletePaymentMethod = (obj: { __typename: String }): obj is DeletePaymentMethod => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DeletePaymentMethod_possibleTypes.includes(obj.__typename)
}

const SetDefaultPaymentMethod_possibleTypes = ['SetDefaultPaymentMethod']
export const isSetDefaultPaymentMethod = (obj: { __typename: String }): obj is SetDefaultPaymentMethod => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return SetDefaultPaymentMethod_possibleTypes.includes(obj.__typename)
}

const CreateSetupIntent_possibleTypes = ['CreateSetupIntent']
export const isCreateSetupIntent = (obj: { __typename: String }): obj is CreateSetupIntent => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CreateSetupIntent_possibleTypes.includes(obj.__typename)
}

const UpdateCryptoWalletBalance_possibleTypes = ['UpdateCryptoWalletBalance']
export const isUpdateCryptoWalletBalance = (obj: { __typename: String }): obj is UpdateCryptoWalletBalance => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UpdateCryptoWalletBalance_possibleTypes.includes(obj.__typename)
}

const UpdateNotificationPreferences_possibleTypes = ['UpdateNotificationPreferences']
export const isUpdateNotificationPreferences = (obj: { __typename: String }): obj is UpdateNotificationPreferences => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UpdateNotificationPreferences_possibleTypes.includes(obj.__typename)
}

const WithdrawDepositRequestCreate_possibleTypes = ['WithdrawDepositRequestCreate']
export const isWithdrawDepositRequestCreate = (obj: { __typename: String }): obj is WithdrawDepositRequestCreate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return WithdrawDepositRequestCreate_possibleTypes.includes(obj.__typename)
}

const WithdrawDepositRequestNode_possibleTypes = ['WithdrawDepositRequestNode']
export const isWithdrawDepositRequestNode = (obj: { __typename: String }): obj is WithdrawDepositRequestNode => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return WithdrawDepositRequestNode_possibleTypes.includes(obj.__typename)
}

const EditChannel_possibleTypes = ['EditChannel']
export const isEditChannel = (obj: { __typename: String }): obj is EditChannel => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return EditChannel_possibleTypes.includes(obj.__typename)
}

const RemoveTeamMemberFromChannel_possibleTypes = ['RemoveTeamMemberFromChannel']
export const isRemoveTeamMemberFromChannel = (obj: { __typename: String }): obj is RemoveTeamMemberFromChannel => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return RemoveTeamMemberFromChannel_possibleTypes.includes(obj.__typename)
}

const UploadChannelBannerImage_possibleTypes = ['UploadChannelBannerImage']
export const isUploadChannelBannerImage = (obj: { __typename: String }): obj is UploadChannelBannerImage => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UploadChannelBannerImage_possibleTypes.includes(obj.__typename)
}

const UploadChannelProfileImage_possibleTypes = ['UploadChannelProfileImage']
export const isUploadChannelProfileImage = (obj: { __typename: String }): obj is UploadChannelProfileImage => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UploadChannelProfileImage_possibleTypes.includes(obj.__typename)
}

const AddTeamMemberToChannel_possibleTypes = ['AddTeamMemberToChannel']
export const isAddTeamMemberToChannel = (obj: { __typename: String }): obj is AddTeamMemberToChannel => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AddTeamMemberToChannel_possibleTypes.includes(obj.__typename)
}

const BuyBoxBreakSpots_possibleTypes = ['BuyBoxBreakSpots']
export const isBuyBoxBreakSpots = (obj: { __typename: String }): obj is BuyBoxBreakSpots => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BuyBoxBreakSpots_possibleTypes.includes(obj.__typename)
}

const AuctionCreate_possibleTypes = ['AuctionCreate']
export const isAuctionCreate = (obj: { __typename: String }): obj is AuctionCreate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionCreate_possibleTypes.includes(obj.__typename)
}

const AuctionDraftDelete_possibleTypes = ['AuctionDraftDelete']
export const isAuctionDraftDelete = (obj: { __typename: String }): obj is AuctionDraftDelete => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionDraftDelete_possibleTypes.includes(obj.__typename)
}

const AuctionDraftEdit_possibleTypes = ['AuctionDraftEdit']
export const isAuctionDraftEdit = (obj: { __typename: String }): obj is AuctionDraftEdit => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AuctionDraftEdit_possibleTypes.includes(obj.__typename)
}

const BidCreate_possibleTypes = ['BidCreate']
export const isBidCreate = (obj: { __typename: String }): obj is BidCreate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BidCreate_possibleTypes.includes(obj.__typename)
}

const BidTransitionToPendingReview_possibleTypes = ['BidTransitionToPendingReview']
export const isBidTransitionToPendingReview = (obj: { __typename: String }): obj is BidTransitionToPendingReview => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return BidTransitionToPendingReview_possibleTypes.includes(obj.__typename)
}

const DiscordBotBidCreate_possibleTypes = ['DiscordBotBidCreate']
export const isDiscordBotBidCreate = (obj: { __typename: String }): obj is DiscordBotBidCreate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DiscordBotBidCreate_possibleTypes.includes(obj.__typename)
}

const UpdateLotNftApprovalStateToApproving_possibleTypes = ['UpdateLotNftApprovalStateToApproving']
export const isUpdateLotNftApprovalStateToApproving = (obj: {
  __typename: String
}): obj is UpdateLotNftApprovalStateToApproving => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UpdateLotNftApprovalStateToApproving_possibleTypes.includes(obj.__typename)
}

const UpdateLotStateToCompletingSale_possibleTypes = ['UpdateLotStateToCompletingSale']
export const isUpdateLotStateToCompletingSale = (obj: { __typename: String }): obj is UpdateLotStateToCompletingSale => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UpdateLotStateToCompletingSale_possibleTypes.includes(obj.__typename)
}

const CreateAddress_possibleTypes = ['CreateAddress']
export const isCreateAddress = (obj: { __typename: String }): obj is CreateAddress => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return CreateAddress_possibleTypes.includes(obj.__typename)
}

const DeleteAddress_possibleTypes = ['DeleteAddress']
export const isDeleteAddress = (obj: { __typename: String }): obj is DeleteAddress => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DeleteAddress_possibleTypes.includes(obj.__typename)
}

const UpdateAddress_possibleTypes = ['UpdateAddress']
export const isUpdateAddress = (obj: { __typename: String }): obj is UpdateAddress => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UpdateAddress_possibleTypes.includes(obj.__typename)
}

const MakeAddressDefault_possibleTypes = ['MakeAddressDefault']
export const isMakeAddressDefault = (obj: { __typename: String }): obj is MakeAddressDefault => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return MakeAddressDefault_possibleTypes.includes(obj.__typename)
}

const AccountRegister_possibleTypes = ['AccountRegister']
export const isAccountRegister = (obj: { __typename: String }): obj is AccountRegister => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return AccountRegister_possibleTypes.includes(obj.__typename)
}

const DisconnectDiscordUser_possibleTypes = ['DisconnectDiscordUser']
export const isDisconnectDiscordUser = (obj: { __typename: String }): obj is DisconnectDiscordUser => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DisconnectDiscordUser_possibleTypes.includes(obj.__typename)
}

const EditUserProfile_possibleTypes = ['EditUserProfile']
export const isEditUserProfile = (obj: { __typename: String }): obj is EditUserProfile => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return EditUserProfile_possibleTypes.includes(obj.__typename)
}

const GenerateDiscordToken_possibleTypes = ['GenerateDiscordToken']
export const isGenerateDiscordToken = (obj: { __typename: String }): obj is GenerateDiscordToken => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return GenerateDiscordToken_possibleTypes.includes(obj.__typename)
}

const PasswordChange_possibleTypes = ['PasswordChange']
export const isPasswordChange = (obj: { __typename: String }): obj is PasswordChange => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PasswordChange_possibleTypes.includes(obj.__typename)
}

const PasswordResetRequest_possibleTypes = ['PasswordResetRequest']
export const isPasswordResetRequest = (obj: { __typename: String }): obj is PasswordResetRequest => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PasswordResetRequest_possibleTypes.includes(obj.__typename)
}

const PasswordResetChange_possibleTypes = ['PasswordResetChange']
export const isPasswordResetChange = (obj: { __typename: String }): obj is PasswordResetChange => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return PasswordResetChange_possibleTypes.includes(obj.__typename)
}

const ObtainJSONWebToken_possibleTypes = ['ObtainJSONWebToken']
export const isObtainJSONWebToken = (obj: { __typename: String }): obj is ObtainJSONWebToken => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return ObtainJSONWebToken_possibleTypes.includes(obj.__typename)
}

const UpdateUserCollectionVisibility_possibleTypes = ['UpdateUserCollectionVisibility']
export const isUpdateUserCollectionVisibility = (obj: { __typename: String }): obj is UpdateUserCollectionVisibility => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return UpdateUserCollectionVisibility_possibleTypes.includes(obj.__typename)
}

const VerifyDiscordToken_possibleTypes = ['VerifyDiscordToken']
export const isVerifyDiscordToken = (obj: { __typename: String }): obj is VerifyDiscordToken => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return VerifyDiscordToken_possibleTypes.includes(obj.__typename)
}

const WalletAuth_possibleTypes = ['WalletAuth']
export const isWalletAuth = (obj: { __typename: String }): obj is WalletAuth => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return WalletAuth_possibleTypes.includes(obj.__typename)
}

const Verify_possibleTypes = ['Verify']
export const isVerify = (obj: { __typename: String }): obj is Verify => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Verify_possibleTypes.includes(obj.__typename)
}

const Refresh_possibleTypes = ['Refresh']
export const isRefresh = (obj: { __typename: String }): obj is Refresh => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return Refresh_possibleTypes.includes(obj.__typename)
}

const DeleteJSONWebTokenCookie_possibleTypes = ['DeleteJSONWebTokenCookie']
export const isDeleteJSONWebTokenCookie = (obj: { __typename: String }): obj is DeleteJSONWebTokenCookie => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DeleteJSONWebTokenCookie_possibleTypes.includes(obj.__typename)
}

const DeleteRefreshTokenCookie_possibleTypes = ['DeleteRefreshTokenCookie']
export const isDeleteRefreshTokenCookie = (obj: { __typename: String }): obj is DeleteRefreshTokenCookie => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return DeleteRefreshTokenCookie_possibleTypes.includes(obj.__typename)
}

export interface QueryPromiseChain {
  myPaymentMethods: {
    execute: (
      request: StripePaymentMethodNodeRequest,
      defaultValue?: (StripePaymentMethodNode | null)[] | null,
    ) => Promise<(StripePaymentMethodNode | null)[] | null>
  }
  streamingChannels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => StreamingChannelNodeConnectionPromiseChain & {
    execute: (
      request: StreamingChannelNodeConnectionRequest,
      defaultValue?: StreamingChannelNodeConnection | null,
    ) => Promise<StreamingChannelNodeConnection | null>
  }) &
    (StreamingChannelNodeConnectionPromiseChain & {
      execute: (
        request: StreamingChannelNodeConnectionRequest,
        defaultValue?: StreamingChannelNodeConnection | null,
      ) => Promise<StreamingChannelNodeConnection | null>
    })
  streamingChannel: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => StreamingChannelNodePromiseChain & {
    execute: (
      request: StreamingChannelNodeRequest,
      defaultValue?: StreamingChannelNode | null,
    ) => Promise<StreamingChannelNode | null>
  }) &
    (StreamingChannelNodePromiseChain & {
      execute: (
        request: StreamingChannelNodeRequest,
        defaultValue?: StreamingChannelNode | null,
      ) => Promise<StreamingChannelNode | null>
    })
  streams: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => StreamNodeConnectionPromiseChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Promise<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionPromiseChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Promise<StreamNodeConnection | null>
    })
  stream: ((args?: {
    id?: ID | null
  }) => StreamNodePromiseChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
  }) &
    (StreamNodePromiseChain & {
      execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
    })
  events: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    eventType?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => EventNodeConnectionPromiseChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Promise<EventNodeConnection | null>
  }) &
    (EventNodeConnectionPromiseChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Promise<EventNodeConnection | null>
    })
  event: ((args?: {
    id?: ID | null
  }) => EventNodePromiseChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
  }) &
    (EventNodePromiseChain & {
      execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
    })
  defaultNotificationPreferences: {
    execute: (
      request: UserNotificationPreferenceNodeRequest,
      defaultValue?: (UserNotificationPreferenceNode | null)[] | null,
    ) => Promise<(UserNotificationPreferenceNode | null)[] | null>
  }
  myNotificationPreferences: {
    execute: (
      request: UserNotificationPreferenceNodeRequest,
      defaultValue?: (UserNotificationPreferenceNode | null)[] | null,
    ) => Promise<(UserNotificationPreferenceNode | null)[] | null>
  }
  nftCollections: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => NFTCollectionNodeConnectionPromiseChain & {
    execute: (
      request: NFTCollectionNodeConnectionRequest,
      defaultValue?: NFTCollectionNodeConnection | null,
    ) => Promise<NFTCollectionNodeConnection | null>
  }) &
    (NFTCollectionNodeConnectionPromiseChain & {
      execute: (
        request: NFTCollectionNodeConnectionRequest,
        defaultValue?: NFTCollectionNodeConnection | null,
      ) => Promise<NFTCollectionNodeConnection | null>
    })
  nftCollection: ((args?: {
    id?: ID | null
  }) => NFTCollectionNodePromiseChain & {
    execute: (
      request: NFTCollectionNodeRequest,
      defaultValue?: NFTCollectionNode | null,
    ) => Promise<NFTCollectionNode | null>
  }) &
    (NFTCollectionNodePromiseChain & {
      execute: (
        request: NFTCollectionNodeRequest,
        defaultValue?: NFTCollectionNode | null,
      ) => Promise<NFTCollectionNode | null>
    })
  nftItems: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    chain?: String | null
    collection?: ID | null
    tokenAddress?: String | null
    tokenId?: String | null
    tokenAddressAndTokenIds?: (String | null)[] | null
  }) => NFTItemNodeConnectionPromiseChain & {
    execute: (
      request: NFTItemNodeConnectionRequest,
      defaultValue?: NFTItemNodeConnection | null,
    ) => Promise<NFTItemNodeConnection | null>
  }) &
    (NFTItemNodeConnectionPromiseChain & {
      execute: (
        request: NFTItemNodeConnectionRequest,
        defaultValue?: NFTItemNodeConnection | null,
      ) => Promise<NFTItemNodeConnection | null>
    })
  nftItem: ((args?: {
    id?: ID | null
    tokenAddress?: String | null
    tokenId?: String | null
  }) => NFTItemNodePromiseChain & {
    execute: (request: NFTItemNodeRequest, defaultValue?: NFTItemNode | null) => Promise<NFTItemNode | null>
  }) &
    (NFTItemNodePromiseChain & {
      execute: (request: NFTItemNodeRequest, defaultValue?: NFTItemNode | null) => Promise<NFTItemNode | null>
    })
  importNftItemsForOwnerAddress: (args: {
    ownerAddress: String
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    chain?: String | null
    collection?: ID | null
    tokenAddress?: String | null
    tokenId?: String | null
    tokenAddressAndTokenIds?: (String | null)[] | null
  }) => NFTItemNodeConnectionPromiseChain & {
    execute: (
      request: NFTItemNodeConnectionRequest,
      defaultValue?: NFTItemNodeConnection | null,
    ) => Promise<NFTItemNodeConnection | null>
  }
  myBiddingDeposit: DepositNodePromiseChain & {
    execute: (request: DepositNodeRequest, defaultValue?: DepositNode | null) => Promise<DepositNode | null>
  }
  channels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => ChannelNodeConnectionPromiseChain & {
    execute: (
      request: ChannelNodeConnectionRequest,
      defaultValue?: ChannelNodeConnection | null,
    ) => Promise<ChannelNodeConnection | null>
  }) &
    (ChannelNodeConnectionPromiseChain & {
      execute: (
        request: ChannelNodeConnectionRequest,
        defaultValue?: ChannelNodeConnection | null,
      ) => Promise<ChannelNodeConnection | null>
    })
  channel: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => ChannelNodePromiseChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode | null) => Promise<ChannelNode | null>
  }) &
    (ChannelNodePromiseChain & {
      execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode | null) => Promise<ChannelNode | null>
    })
  boxBreak: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
  }) &
    (BoxBreakNodePromiseChain & {
      execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
    })
  boxBreaks: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionPromiseChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection | null,
    ) => Promise<BoxBreakNodeConnection | null>
  }) &
    (BoxBreakNodeConnectionPromiseChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection | null,
      ) => Promise<BoxBreakNodeConnection | null>
    })
  myBoxBreaks: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionPromiseChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection | null,
    ) => Promise<BoxBreakNodeConnection | null>
  }) &
    (BoxBreakNodeConnectionPromiseChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection | null,
      ) => Promise<BoxBreakNodeConnection | null>
    })
  myPersonalBreakRequests: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PersonalBreakRequestNodeConnectionPromiseChain & {
    execute: (
      request: PersonalBreakRequestNodeConnectionRequest,
      defaultValue?: PersonalBreakRequestNodeConnection | null,
    ) => Promise<PersonalBreakRequestNodeConnection | null>
  }) &
    (PersonalBreakRequestNodeConnectionPromiseChain & {
      execute: (
        request: PersonalBreakRequestNodeConnectionRequest,
        defaultValue?: PersonalBreakRequestNodeConnection | null,
      ) => Promise<PersonalBreakRequestNodeConnection | null>
    })
  remints: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionPromiseChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection | null,
    ) => Promise<BoxBreakNodeConnection | null>
  }) &
    (BoxBreakNodeConnectionPromiseChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection | null,
      ) => Promise<BoxBreakNodeConnection | null>
    })
  remint: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
  }) &
    (BoxBreakNodePromiseChain & {
      execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
    })
  auction: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => AuctionNodePromiseChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Promise<AuctionNode | null>
  }) &
    (AuctionNodePromiseChain & {
      execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Promise<AuctionNode | null>
    })
  auctions: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    era?: String | null
    state?: (String | null)[] | null
    featured?: Boolean | null
    currency?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => AuctionNodeConnectionPromiseChain & {
    execute: (
      request: AuctionNodeConnectionRequest,
      defaultValue?: AuctionNodeConnection | null,
    ) => Promise<AuctionNodeConnection | null>
  }) &
    (AuctionNodeConnectionPromiseChain & {
      execute: (
        request: AuctionNodeConnectionRequest,
        defaultValue?: AuctionNodeConnection | null,
      ) => Promise<AuctionNodeConnection | null>
    })
  bid: ((args?: {
    id?: ID | null
  }) => BidNodePromiseChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Promise<BidNode | null>
  }) &
    (BidNodePromiseChain & { execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Promise<BidNode | null> })
  lot: ((args?: {
    id?: ID | null
  }) => LotNodePromiseChain & {
    execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Promise<LotNode | null>
  }) &
    (LotNodePromiseChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Promise<LotNode | null> })
  lots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionPromiseChain & {
    execute: (
      request: LotNodeConnectionRequest,
      defaultValue?: LotNodeConnection | null,
    ) => Promise<LotNodeConnection | null>
  }) &
    (LotNodeConnectionPromiseChain & {
      execute: (
        request: LotNodeConnectionRequest,
        defaultValue?: LotNodeConnection | null,
      ) => Promise<LotNodeConnection | null>
    })
  myBiddingLots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionPromiseChain & {
    execute: (
      request: LotNodeConnectionRequest,
      defaultValue?: LotNodeConnection | null,
    ) => Promise<LotNodeConnection | null>
  }) &
    (LotNodeConnectionPromiseChain & {
      execute: (
        request: LotNodeConnectionRequest,
        defaultValue?: LotNodeConnection | null,
      ) => Promise<LotNodeConnection | null>
    })
  announcementBanners: {
    execute: (
      request: AnnouncementNodeRequest,
      defaultValue?: (AnnouncementNode | null)[] | null,
    ) => Promise<(AnnouncementNode | null)[] | null>
  }
  myAddresses: {
    execute: (
      request: AddressNodeRequest,
      defaultValue?: (AddressNode | null)[] | null,
    ) => Promise<(AddressNode | null)[] | null>
  }
  me: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  myAccount: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  promotion: PromotionTypePromiseChain & {
    execute: (request: PromotionTypeRequest, defaultValue?: PromotionType | null) => Promise<PromotionType | null>
  }
  myDiscordUser: DiscordUserTypePromiseChain & {
    execute: (request: DiscordUserTypeRequest, defaultValue?: DiscordUserType | null) => Promise<DiscordUserType | null>
  }
  user: ((args?: {
    discordId?: String | null
  }) => UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }) &
    (UserTypePromiseChain & {
      execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
    })
}

export interface QueryObservableChain {
  myPaymentMethods: {
    execute: (
      request: StripePaymentMethodNodeRequest,
      defaultValue?: (StripePaymentMethodNode | null)[] | null,
    ) => Observable<(StripePaymentMethodNode | null)[] | null>
  }
  streamingChannels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => StreamingChannelNodeConnectionObservableChain & {
    execute: (
      request: StreamingChannelNodeConnectionRequest,
      defaultValue?: StreamingChannelNodeConnection | null,
    ) => Observable<StreamingChannelNodeConnection | null>
  }) &
    (StreamingChannelNodeConnectionObservableChain & {
      execute: (
        request: StreamingChannelNodeConnectionRequest,
        defaultValue?: StreamingChannelNodeConnection | null,
      ) => Observable<StreamingChannelNodeConnection | null>
    })
  streamingChannel: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => StreamingChannelNodeObservableChain & {
    execute: (
      request: StreamingChannelNodeRequest,
      defaultValue?: StreamingChannelNode | null,
    ) => Observable<StreamingChannelNode | null>
  }) &
    (StreamingChannelNodeObservableChain & {
      execute: (
        request: StreamingChannelNodeRequest,
        defaultValue?: StreamingChannelNode | null,
      ) => Observable<StreamingChannelNode | null>
    })
  streams: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => StreamNodeConnectionObservableChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Observable<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionObservableChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Observable<StreamNodeConnection | null>
    })
  stream: ((args?: {
    id?: ID | null
  }) => StreamNodeObservableChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
  }) &
    (StreamNodeObservableChain & {
      execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
    })
  events: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    eventType?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => EventNodeConnectionObservableChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Observable<EventNodeConnection | null>
  }) &
    (EventNodeConnectionObservableChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Observable<EventNodeConnection | null>
    })
  event: ((args?: {
    id?: ID | null
  }) => EventNodeObservableChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
  }) &
    (EventNodeObservableChain & {
      execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
    })
  defaultNotificationPreferences: {
    execute: (
      request: UserNotificationPreferenceNodeRequest,
      defaultValue?: (UserNotificationPreferenceNode | null)[] | null,
    ) => Observable<(UserNotificationPreferenceNode | null)[] | null>
  }
  myNotificationPreferences: {
    execute: (
      request: UserNotificationPreferenceNodeRequest,
      defaultValue?: (UserNotificationPreferenceNode | null)[] | null,
    ) => Observable<(UserNotificationPreferenceNode | null)[] | null>
  }
  nftCollections: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => NFTCollectionNodeConnectionObservableChain & {
    execute: (
      request: NFTCollectionNodeConnectionRequest,
      defaultValue?: NFTCollectionNodeConnection | null,
    ) => Observable<NFTCollectionNodeConnection | null>
  }) &
    (NFTCollectionNodeConnectionObservableChain & {
      execute: (
        request: NFTCollectionNodeConnectionRequest,
        defaultValue?: NFTCollectionNodeConnection | null,
      ) => Observable<NFTCollectionNodeConnection | null>
    })
  nftCollection: ((args?: {
    id?: ID | null
  }) => NFTCollectionNodeObservableChain & {
    execute: (
      request: NFTCollectionNodeRequest,
      defaultValue?: NFTCollectionNode | null,
    ) => Observable<NFTCollectionNode | null>
  }) &
    (NFTCollectionNodeObservableChain & {
      execute: (
        request: NFTCollectionNodeRequest,
        defaultValue?: NFTCollectionNode | null,
      ) => Observable<NFTCollectionNode | null>
    })
  nftItems: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    chain?: String | null
    collection?: ID | null
    tokenAddress?: String | null
    tokenId?: String | null
    tokenAddressAndTokenIds?: (String | null)[] | null
  }) => NFTItemNodeConnectionObservableChain & {
    execute: (
      request: NFTItemNodeConnectionRequest,
      defaultValue?: NFTItemNodeConnection | null,
    ) => Observable<NFTItemNodeConnection | null>
  }) &
    (NFTItemNodeConnectionObservableChain & {
      execute: (
        request: NFTItemNodeConnectionRequest,
        defaultValue?: NFTItemNodeConnection | null,
      ) => Observable<NFTItemNodeConnection | null>
    })
  nftItem: ((args?: {
    id?: ID | null
    tokenAddress?: String | null
    tokenId?: String | null
  }) => NFTItemNodeObservableChain & {
    execute: (request: NFTItemNodeRequest, defaultValue?: NFTItemNode | null) => Observable<NFTItemNode | null>
  }) &
    (NFTItemNodeObservableChain & {
      execute: (request: NFTItemNodeRequest, defaultValue?: NFTItemNode | null) => Observable<NFTItemNode | null>
    })
  importNftItemsForOwnerAddress: (args: {
    ownerAddress: String
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    chain?: String | null
    collection?: ID | null
    tokenAddress?: String | null
    tokenId?: String | null
    tokenAddressAndTokenIds?: (String | null)[] | null
  }) => NFTItemNodeConnectionObservableChain & {
    execute: (
      request: NFTItemNodeConnectionRequest,
      defaultValue?: NFTItemNodeConnection | null,
    ) => Observable<NFTItemNodeConnection | null>
  }
  myBiddingDeposit: DepositNodeObservableChain & {
    execute: (request: DepositNodeRequest, defaultValue?: DepositNode | null) => Observable<DepositNode | null>
  }
  channels: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    /** Ordering */
    orderBy?: String | null
  }) => ChannelNodeConnectionObservableChain & {
    execute: (
      request: ChannelNodeConnectionRequest,
      defaultValue?: ChannelNodeConnection | null,
    ) => Observable<ChannelNodeConnection | null>
  }) &
    (ChannelNodeConnectionObservableChain & {
      execute: (
        request: ChannelNodeConnectionRequest,
        defaultValue?: ChannelNodeConnection | null,
      ) => Observable<ChannelNodeConnection | null>
    })
  channel: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => ChannelNodeObservableChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode | null) => Observable<ChannelNode | null>
  }) &
    (ChannelNodeObservableChain & {
      execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode | null) => Observable<ChannelNode | null>
    })
  boxBreak: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
  }) &
    (BoxBreakNodeObservableChain & {
      execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
    })
  boxBreaks: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionObservableChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection | null,
    ) => Observable<BoxBreakNodeConnection | null>
  }) &
    (BoxBreakNodeConnectionObservableChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection | null,
      ) => Observable<BoxBreakNodeConnection | null>
    })
  myBoxBreaks: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionObservableChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection | null,
    ) => Observable<BoxBreakNodeConnection | null>
  }) &
    (BoxBreakNodeConnectionObservableChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection | null,
      ) => Observable<BoxBreakNodeConnection | null>
    })
  myPersonalBreakRequests: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PersonalBreakRequestNodeConnectionObservableChain & {
    execute: (
      request: PersonalBreakRequestNodeConnectionRequest,
      defaultValue?: PersonalBreakRequestNodeConnection | null,
    ) => Observable<PersonalBreakRequestNodeConnection | null>
  }) &
    (PersonalBreakRequestNodeConnectionObservableChain & {
      execute: (
        request: PersonalBreakRequestNodeConnectionRequest,
        defaultValue?: PersonalBreakRequestNodeConnection | null,
      ) => Observable<PersonalBreakRequestNodeConnection | null>
    })
  remints: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionObservableChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection | null,
    ) => Observable<BoxBreakNodeConnection | null>
  }) &
    (BoxBreakNodeConnectionObservableChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection | null,
      ) => Observable<BoxBreakNodeConnection | null>
    })
  remint: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
  }) &
    (BoxBreakNodeObservableChain & {
      execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
    })
  auction: ((args?: {
    id?: ID | null
    slug?: String | null
  }) => AuctionNodeObservableChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Observable<AuctionNode | null>
  }) &
    (AuctionNodeObservableChain & {
      execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Observable<AuctionNode | null>
    })
  auctions: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    era?: String | null
    state?: (String | null)[] | null
    featured?: Boolean | null
    currency?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => AuctionNodeConnectionObservableChain & {
    execute: (
      request: AuctionNodeConnectionRequest,
      defaultValue?: AuctionNodeConnection | null,
    ) => Observable<AuctionNodeConnection | null>
  }) &
    (AuctionNodeConnectionObservableChain & {
      execute: (
        request: AuctionNodeConnectionRequest,
        defaultValue?: AuctionNodeConnection | null,
      ) => Observable<AuctionNodeConnection | null>
    })
  bid: ((args?: {
    id?: ID | null
  }) => BidNodeObservableChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Observable<BidNode | null>
  }) &
    (BidNodeObservableChain & {
      execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Observable<BidNode | null>
    })
  lot: ((args?: {
    id?: ID | null
  }) => LotNodeObservableChain & {
    execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Observable<LotNode | null>
  }) &
    (LotNodeObservableChain & {
      execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Observable<LotNode | null>
    })
  lots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionObservableChain & {
    execute: (
      request: LotNodeConnectionRequest,
      defaultValue?: LotNodeConnection | null,
    ) => Observable<LotNodeConnection | null>
  }) &
    (LotNodeConnectionObservableChain & {
      execute: (
        request: LotNodeConnectionRequest,
        defaultValue?: LotNodeConnection | null,
      ) => Observable<LotNodeConnection | null>
    })
  myBiddingLots: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionObservableChain & {
    execute: (
      request: LotNodeConnectionRequest,
      defaultValue?: LotNodeConnection | null,
    ) => Observable<LotNodeConnection | null>
  }) &
    (LotNodeConnectionObservableChain & {
      execute: (
        request: LotNodeConnectionRequest,
        defaultValue?: LotNodeConnection | null,
      ) => Observable<LotNodeConnection | null>
    })
  announcementBanners: {
    execute: (
      request: AnnouncementNodeRequest,
      defaultValue?: (AnnouncementNode | null)[] | null,
    ) => Observable<(AnnouncementNode | null)[] | null>
  }
  myAddresses: {
    execute: (
      request: AddressNodeRequest,
      defaultValue?: (AddressNode | null)[] | null,
    ) => Observable<(AddressNode | null)[] | null>
  }
  me: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  myAccount: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  promotion: PromotionTypeObservableChain & {
    execute: (request: PromotionTypeRequest, defaultValue?: PromotionType | null) => Observable<PromotionType | null>
  }
  myDiscordUser: DiscordUserTypeObservableChain & {
    execute: (request: DiscordUserTypeRequest, defaultValue?: DiscordUserType | null) => Observable<DiscordUserType | null>
  }
  user: ((args?: {
    discordId?: String | null
  }) => UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }) &
    (UserTypeObservableChain & {
      execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
    })
}

export interface StripePaymentMethodNodePromiseChain {
  paymentMethodId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  brand: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  lastFourDigits: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  expiryMonth: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  expiryYear: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  offSession: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  defaultPaymentMethod: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
}

export interface StripePaymentMethodNodeObservableChain {
  paymentMethodId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  brand: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  lastFourDigits: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  expiryMonth: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  expiryYear: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  offSession: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  defaultPaymentMethod: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
}

/** An object with an ID */
export interface NodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
}

/** An object with an ID */
export interface NodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
}

export interface StreamingChannelNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: StreamingChannelNodeEdgeRequest,
      defaultValue?: (StreamingChannelNodeEdge | null)[],
    ) => Promise<(StreamingChannelNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface StreamingChannelNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: StreamingChannelNodeEdgeRequest,
      defaultValue?: (StreamingChannelNodeEdge | null)[],
    ) => Observable<(StreamingChannelNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export interface PageInfoPromiseChain {
  /** When paginating forwards, are there more items? */
  hasNextPage: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  /** When paginating backwards, are there more items? */
  hasPreviousPage: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  /** When paginating backwards, the cursor to continue. */
  startCursor: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  /** When paginating forwards, the cursor to continue. */
  endCursor: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export interface PageInfoObservableChain {
  /** When paginating forwards, are there more items? */
  hasNextPage: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  /** When paginating backwards, are there more items? */
  hasPreviousPage: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  /** When paginating backwards, the cursor to continue. */
  startCursor: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  /** When paginating forwards, the cursor to continue. */
  endCursor: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

/** A Relay edge containing a `StreamingChannelNode` and its cursor. */
export interface StreamingChannelNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: StreamingChannelNodePromiseChain & {
    execute: (
      request: StreamingChannelNodeRequest,
      defaultValue?: StreamingChannelNode | null,
    ) => Promise<StreamingChannelNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `StreamingChannelNode` and its cursor. */
export interface StreamingChannelNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: StreamingChannelNodeObservableChain & {
    execute: (
      request: StreamingChannelNodeRequest,
      defaultValue?: StreamingChannelNode | null,
    ) => Observable<StreamingChannelNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface StreamingChannelNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** User-facing description of this channel and its focus. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** URL identifier for this channel. */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  eventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    eventType?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => EventNodeConnectionPromiseChain & {
    execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Promise<EventNodeConnection>
  }) &
    (EventNodeConnectionPromiseChain & {
      execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Promise<EventNodeConnection>
    })
  streamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => StreamNodeConnectionPromiseChain & {
    execute: (request: StreamNodeConnectionRequest, defaultValue?: StreamNodeConnection) => Promise<StreamNodeConnection>
  }) &
    (StreamNodeConnectionPromiseChain & {
      execute: (request: StreamNodeConnectionRequest, defaultValue?: StreamNodeConnection) => Promise<StreamNodeConnection>
    })
  nextUpcomingStreamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => StreamNodeConnectionPromiseChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Promise<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionPromiseChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Promise<StreamNodeConnection | null>
    })
  nextUpcomingStream: StreamNodePromiseChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
  }
  liveStreamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => StreamNodeConnectionPromiseChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Promise<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionPromiseChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Promise<StreamNodeConnection | null>
    })
  liveStream: StreamNodePromiseChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
  }
  recentlyEndedStreamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => StreamNodeConnectionPromiseChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Promise<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionPromiseChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Promise<StreamNodeConnection | null>
    })
  recentlyEndedStream: StreamNodePromiseChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
  }
}

export interface StreamingChannelNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** User-facing description of this channel and its focus. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** URL identifier for this channel. */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  eventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    eventType?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => EventNodeConnectionObservableChain & {
    execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Observable<EventNodeConnection>
  }) &
    (EventNodeConnectionObservableChain & {
      execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Observable<EventNodeConnection>
    })
  streamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => StreamNodeConnectionObservableChain & {
    execute: (request: StreamNodeConnectionRequest, defaultValue?: StreamNodeConnection) => Observable<StreamNodeConnection>
  }) &
    (StreamNodeConnectionObservableChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection,
      ) => Observable<StreamNodeConnection>
    })
  nextUpcomingStreamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => StreamNodeConnectionObservableChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Observable<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionObservableChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Observable<StreamNodeConnection | null>
    })
  nextUpcomingStream: StreamNodeObservableChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
  }
  liveStreamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => StreamNodeConnectionObservableChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Observable<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionObservableChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Observable<StreamNodeConnection | null>
    })
  liveStream: StreamNodeObservableChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
  }
  recentlyEndedStreamSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => StreamNodeConnectionObservableChain & {
    execute: (
      request: StreamNodeConnectionRequest,
      defaultValue?: StreamNodeConnection | null,
    ) => Observable<StreamNodeConnection | null>
  }) &
    (StreamNodeConnectionObservableChain & {
      execute: (
        request: StreamNodeConnectionRequest,
        defaultValue?: StreamNodeConnection | null,
      ) => Observable<StreamNodeConnection | null>
    })
  recentlyEndedStream: StreamNodeObservableChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
  }
}

export interface EventNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: EventNodeEdgeRequest, defaultValue?: (EventNodeEdge | null)[]) => Promise<(EventNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface EventNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: EventNodeEdgeRequest, defaultValue?: (EventNodeEdge | null)[]) => Observable<(EventNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `EventNode` and its cursor. */
export interface EventNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: EventNodePromiseChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `EventNode` and its cursor. */
export interface EventNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: EventNodeObservableChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface EventNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  eventType: { execute: (request?: boolean | number, defaultValue?: EventEventType) => Promise<EventEventType> }
  channel: StreamingChannelNodePromiseChain & {
    execute: (request: StreamingChannelNodeRequest, defaultValue?: StreamingChannelNode) => Promise<StreamingChannelNode>
  }
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  stream: StreamNodePromiseChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
  }
  /** Date and time at which this event is scheduled to begin. Does not enforce when it starts; only used as a guideline. May be left blank if not yet scheduled. */
  scheduledStartTime: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Estimated duration of this event. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  /** Optional event-specific replay video, hosted on YouTube. If populated, once the event has ended it will show this video instead of the video from the associated Stream. Leave blank to just continue to show the full Stream video. */
  replayYoutubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  state: { execute: (request?: boolean | number, defaultValue?: EventState) => Promise<EventState> }
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
  }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface EventNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  eventType: { execute: (request?: boolean | number, defaultValue?: EventEventType) => Observable<EventEventType> }
  channel: StreamingChannelNodeObservableChain & {
    execute: (request: StreamingChannelNodeRequest, defaultValue?: StreamingChannelNode) => Observable<StreamingChannelNode>
  }
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  stream: StreamNodeObservableChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
  }
  /** Date and time at which this event is scheduled to begin. Does not enforce when it starts; only used as a guideline. May be left blank if not yet scheduled. */
  scheduledStartTime: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  /** Estimated duration of this event. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  /** Optional event-specific replay video, hosted on YouTube. If populated, once the event has ended it will show this video instead of the video from the associated Stream. Leave blank to just continue to show the full Stream video. */
  replayYoutubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  state: { execute: (request?: boolean | number, defaultValue?: EventState) => Observable<EventState> }
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
  }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface StreamNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Display name for this stream. May be named after the date, number in a series, or just a descriptive title for the content being included in the stream. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** User-facing description of this stream and and what it will be covering. e.g. "Tonight we will be ripping 4 group breaks including a PYT Bowman Chrome break! Personal breaks on-request throughout the night!" */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  channel: StreamingChannelNodePromiseChain & {
    execute: (request: StreamingChannelNodeRequest, defaultValue?: StreamingChannelNode) => Promise<StreamingChannelNode>
  }
  /** Date and time at which this stream is scheduled to begin. Does not enforce when it starts; only used as a guideline. */
  scheduledStartTime: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  /** Estimated duration of this stream. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration: { execute: (request?: boolean | number, defaultValue?: Float) => Promise<Float> }
  /** The 11-digit YouTube video ID for this stream. */
  youtubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  state: { execute: (request?: boolean | number, defaultValue?: StreamState) => Promise<StreamState> }
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  eventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    eventType?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => EventNodeConnectionPromiseChain & {
    execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Promise<EventNodeConnection>
  }) &
    (EventNodeConnectionPromiseChain & {
      execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Promise<EventNodeConnection>
    })
  nextUpcomingEventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => EventNodeConnectionPromiseChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Promise<EventNodeConnection | null>
  }) &
    (EventNodeConnectionPromiseChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Promise<EventNodeConnection | null>
    })
  nextUpcomingEvent: EventNodePromiseChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
  }
  liveEventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => EventNodeConnectionPromiseChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Promise<EventNodeConnection | null>
  }) &
    (EventNodeConnectionPromiseChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Promise<EventNodeConnection | null>
    })
  liveEvent: EventNodePromiseChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
  }
  recentlyEndedEventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => EventNodeConnectionPromiseChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Promise<EventNodeConnection | null>
  }) &
    (EventNodeConnectionPromiseChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Promise<EventNodeConnection | null>
    })
  recentlyEndedEvent: EventNodePromiseChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
  }
}

export interface StreamNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Display name for this stream. May be named after the date, number in a series, or just a descriptive title for the content being included in the stream. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** User-facing description of this stream and and what it will be covering. e.g. "Tonight we will be ripping 4 group breaks including a PYT Bowman Chrome break! Personal breaks on-request throughout the night!" */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  channel: StreamingChannelNodeObservableChain & {
    execute: (request: StreamingChannelNodeRequest, defaultValue?: StreamingChannelNode) => Observable<StreamingChannelNode>
  }
  /** Date and time at which this stream is scheduled to begin. Does not enforce when it starts; only used as a guideline. */
  scheduledStartTime: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  /** Estimated duration of this stream. Does not enforce when it ends; only used as a guideline. */
  scheduledDuration: { execute: (request?: boolean | number, defaultValue?: Float) => Observable<Float> }
  /** The 11-digit YouTube video ID for this stream. */
  youtubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  state: { execute: (request?: boolean | number, defaultValue?: StreamState) => Observable<StreamState> }
  /** The live stream this event is going to be shown in. May be left blank if not yet scheduled. */
  eventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    eventType?: (String | null)[] | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => EventNodeConnectionObservableChain & {
    execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Observable<EventNodeConnection>
  }) &
    (EventNodeConnectionObservableChain & {
      execute: (request: EventNodeConnectionRequest, defaultValue?: EventNodeConnection) => Observable<EventNodeConnection>
    })
  nextUpcomingEventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => EventNodeConnectionObservableChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Observable<EventNodeConnection | null>
  }) &
    (EventNodeConnectionObservableChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Observable<EventNodeConnection | null>
    })
  nextUpcomingEvent: EventNodeObservableChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
  }
  liveEventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => EventNodeConnectionObservableChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Observable<EventNodeConnection | null>
  }) &
    (EventNodeConnectionObservableChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Observable<EventNodeConnection | null>
    })
  liveEvent: EventNodeObservableChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
  }
  recentlyEndedEventSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => EventNodeConnectionObservableChain & {
    execute: (
      request: EventNodeConnectionRequest,
      defaultValue?: EventNodeConnection | null,
    ) => Observable<EventNodeConnection | null>
  }) &
    (EventNodeConnectionObservableChain & {
      execute: (
        request: EventNodeConnectionRequest,
        defaultValue?: EventNodeConnection | null,
      ) => Observable<EventNodeConnection | null>
    })
  recentlyEndedEvent: EventNodeObservableChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
  }
}

export interface BoxBreakNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  format: FormatNodePromiseChain & {
    execute: (request: FormatNodeRequest, defaultValue?: FormatNode) => Promise<FormatNode>
  }
  /** Display name for this break (e.g. "2021 Topps Definitive Baseball 3x Hobby Box Break: 2 Random Teams") */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** URL identifier for this break */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Assign a featured rank to promote this break. Higher values take priority. Use sparingly; leave blank for most breaks. */
  featuredRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  /** A text description of this break, for example outlining the break format (random teams, PYT...) and products being broken. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Date and time at which this break is scheduled to begin. Presented to users as approximate. Leave blank if break is not yet scheduled. */
  scheduledStartTime: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  createdBy: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  state: { execute: (request?: boolean | number, defaultValue?: BoxBreakState) => Promise<BoxBreakState> }
  /** Date and time at which this break was published onto the live site. */
  publishedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which the break opened for entries. */
  openedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which the live break began. */
  startedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which the break stopped accepting new entries. */
  lockedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which the live break concluded. */
  endedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which the break's items completed processing. */
  settledAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** The 11-digit video ID for the YouTube video this break will be streamed on. */
  youtubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** The name for the Twitch channel this break will be streamed on. */
  twitchChannelName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  breakLocation: BreakLocationNodePromiseChain & {
    execute: (request: BreakLocationNodeRequest, defaultValue?: BreakLocationNode) => Promise<BreakLocationNode>
  }
  /** Choose where the event is visible and who can access it. */
  visibility: { execute: (request?: boolean | number, defaultValue?: BoxBreakVisibility) => Promise<BoxBreakVisibility> }
  /** Optional restriction on how many spots a participant can claim. */
  maxSpotsPerUser: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  /** Optional URL to link to buy page for reMints */
  purchaseOverrideUrl: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Break in which this box is going to be or was opened. */
  boxSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BoxNodeConnectionPromiseChain & {
    execute: (request: BoxNodeConnectionRequest, defaultValue?: BoxNodeConnection) => Promise<BoxNodeConnection>
  }) &
    (BoxNodeConnectionPromiseChain & {
      execute: (request: BoxNodeConnectionRequest, defaultValue?: BoxNodeConnection) => Promise<BoxNodeConnection>
    })
  boxbreakphotoSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BoxBreakPhotoNodeConnectionPromiseChain & {
    execute: (
      request: BoxBreakPhotoNodeConnectionRequest,
      defaultValue?: BoxBreakPhotoNodeConnection,
    ) => Promise<BoxBreakPhotoNodeConnection>
  }) &
    (BoxBreakPhotoNodeConnectionPromiseChain & {
      execute: (
        request: BoxBreakPhotoNodeConnectionRequest,
        defaultValue?: BoxBreakPhotoNodeConnection,
      ) => Promise<BoxBreakPhotoNodeConnection>
    })
  spotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SpotNodeConnectionPromiseChain & {
    execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Promise<SpotNodeConnection>
  }) &
    (SpotNodeConnectionPromiseChain & {
      execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Promise<SpotNodeConnection>
    })
  /** The scheduled personal break that the user would like their box broken as part of. */
  personalbreakrequestSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PersonalBreakRequestNodeConnectionPromiseChain & {
    execute: (
      request: PersonalBreakRequestNodeConnectionRequest,
      defaultValue?: PersonalBreakRequestNodeConnection,
    ) => Promise<PersonalBreakRequestNodeConnection>
  }) &
    (PersonalBreakRequestNodeConnectionPromiseChain & {
      execute: (
        request: PersonalBreakRequestNodeConnectionRequest,
        defaultValue?: PersonalBreakRequestNodeConnection,
      ) => Promise<PersonalBreakRequestNodeConnection>
    })
  /** Break in which this nft item is going to be or was opened. */
  remintnftSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => RemintNFTNodeConnectionPromiseChain & {
    execute: (
      request: RemintNFTNodeConnectionRequest,
      defaultValue?: RemintNFTNodeConnection,
    ) => Promise<RemintNFTNodeConnection>
  }) &
    (RemintNFTNodeConnectionPromiseChain & {
      execute: (
        request: RemintNFTNodeConnectionRequest,
        defaultValue?: RemintNFTNodeConnection,
      ) => Promise<RemintNFTNodeConnection>
    })
  event: EventNodePromiseChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Promise<EventNode | null>
  }
  myParticipant: ParticipantNodePromiseChain & {
    execute: (request: ParticipantNodeRequest, defaultValue?: ParticipantNode | null) => Promise<ParticipantNode | null>
  }
  hitSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => HitNodeConnectionPromiseChain & {
    execute: (
      request: HitNodeConnectionRequest,
      defaultValue?: HitNodeConnection | null,
    ) => Promise<HitNodeConnection | null>
  }) &
    (HitNodeConnectionPromiseChain & {
      execute: (
        request: HitNodeConnectionRequest,
        defaultValue?: HitNodeConnection | null,
      ) => Promise<HitNodeConnection | null>
    })
  spotsTotalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  spotsAvailableCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  spotsFilledCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  spotsMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  spotsMaxPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  spotsCurrency: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  allowPersonalBreakRequests: {
    execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null>
  }
}

export interface BoxBreakNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  format: FormatNodeObservableChain & {
    execute: (request: FormatNodeRequest, defaultValue?: FormatNode) => Observable<FormatNode>
  }
  /** Display name for this break (e.g. "2021 Topps Definitive Baseball 3x Hobby Box Break: 2 Random Teams") */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** URL identifier for this break */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Assign a featured rank to promote this break. Higher values take priority. Use sparingly; leave blank for most breaks. */
  featuredRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  /** A text description of this break, for example outlining the break format (random teams, PYT...) and products being broken. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Date and time at which this break is scheduled to begin. Presented to users as approximate. Leave blank if break is not yet scheduled. */
  scheduledStartTime: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  createdBy: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  state: { execute: (request?: boolean | number, defaultValue?: BoxBreakState) => Observable<BoxBreakState> }
  /** Date and time at which this break was published onto the live site. */
  publishedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which the break opened for entries. */
  openedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which the live break began. */
  startedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which the break stopped accepting new entries. */
  lockedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which the live break concluded. */
  endedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which the break's items completed processing. */
  settledAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** The 11-digit video ID for the YouTube video this break will be streamed on. */
  youtubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** The name for the Twitch channel this break will be streamed on. */
  twitchChannelName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  breakLocation: BreakLocationNodeObservableChain & {
    execute: (request: BreakLocationNodeRequest, defaultValue?: BreakLocationNode) => Observable<BreakLocationNode>
  }
  /** Choose where the event is visible and who can access it. */
  visibility: { execute: (request?: boolean | number, defaultValue?: BoxBreakVisibility) => Observable<BoxBreakVisibility> }
  /** Optional restriction on how many spots a participant can claim. */
  maxSpotsPerUser: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  /** Optional URL to link to buy page for reMints */
  purchaseOverrideUrl: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Break in which this box is going to be or was opened. */
  boxSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BoxNodeConnectionObservableChain & {
    execute: (request: BoxNodeConnectionRequest, defaultValue?: BoxNodeConnection) => Observable<BoxNodeConnection>
  }) &
    (BoxNodeConnectionObservableChain & {
      execute: (request: BoxNodeConnectionRequest, defaultValue?: BoxNodeConnection) => Observable<BoxNodeConnection>
    })
  boxbreakphotoSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BoxBreakPhotoNodeConnectionObservableChain & {
    execute: (
      request: BoxBreakPhotoNodeConnectionRequest,
      defaultValue?: BoxBreakPhotoNodeConnection,
    ) => Observable<BoxBreakPhotoNodeConnection>
  }) &
    (BoxBreakPhotoNodeConnectionObservableChain & {
      execute: (
        request: BoxBreakPhotoNodeConnectionRequest,
        defaultValue?: BoxBreakPhotoNodeConnection,
      ) => Observable<BoxBreakPhotoNodeConnection>
    })
  spotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SpotNodeConnectionObservableChain & {
    execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Observable<SpotNodeConnection>
  }) &
    (SpotNodeConnectionObservableChain & {
      execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Observable<SpotNodeConnection>
    })
  /** The scheduled personal break that the user would like their box broken as part of. */
  personalbreakrequestSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => PersonalBreakRequestNodeConnectionObservableChain & {
    execute: (
      request: PersonalBreakRequestNodeConnectionRequest,
      defaultValue?: PersonalBreakRequestNodeConnection,
    ) => Observable<PersonalBreakRequestNodeConnection>
  }) &
    (PersonalBreakRequestNodeConnectionObservableChain & {
      execute: (
        request: PersonalBreakRequestNodeConnectionRequest,
        defaultValue?: PersonalBreakRequestNodeConnection,
      ) => Observable<PersonalBreakRequestNodeConnection>
    })
  /** Break in which this nft item is going to be or was opened. */
  remintnftSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => RemintNFTNodeConnectionObservableChain & {
    execute: (
      request: RemintNFTNodeConnectionRequest,
      defaultValue?: RemintNFTNodeConnection,
    ) => Observable<RemintNFTNodeConnection>
  }) &
    (RemintNFTNodeConnectionObservableChain & {
      execute: (
        request: RemintNFTNodeConnectionRequest,
        defaultValue?: RemintNFTNodeConnection,
      ) => Observable<RemintNFTNodeConnection>
    })
  event: EventNodeObservableChain & {
    execute: (request: EventNodeRequest, defaultValue?: EventNode | null) => Observable<EventNode | null>
  }
  myParticipant: ParticipantNodeObservableChain & {
    execute: (request: ParticipantNodeRequest, defaultValue?: ParticipantNode | null) => Observable<ParticipantNode | null>
  }
  hitSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => HitNodeConnectionObservableChain & {
    execute: (
      request: HitNodeConnectionRequest,
      defaultValue?: HitNodeConnection | null,
    ) => Observable<HitNodeConnection | null>
  }) &
    (HitNodeConnectionObservableChain & {
      execute: (
        request: HitNodeConnectionRequest,
        defaultValue?: HitNodeConnection | null,
      ) => Observable<HitNodeConnection | null>
    })
  spotsTotalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  spotsAvailableCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  spotsFilledCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  spotsMinPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  spotsMaxPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  spotsCurrency: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  allowPersonalBreakRequests: {
    execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null>
  }
}

export interface FormatNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** A descriptive name for this break format (e.g. "Random Team" or "Hit Draft") */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Optional description of this break format, to inform users about how it works and why they might want to choose this format over another. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Indicates whether it is a group break, open to multiple participants, or a personal break for a single user. */
  breakCategory: {
    execute: (request?: boolean | number, defaultValue?: FormatBreakCategory) => Promise<FormatBreakCategory>
  }
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  boxbreakSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionPromiseChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection,
    ) => Promise<BoxBreakNodeConnection>
  }) &
    (BoxBreakNodeConnectionPromiseChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection,
      ) => Promise<BoxBreakNodeConnection>
    })
}

export interface FormatNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** A descriptive name for this break format (e.g. "Random Team" or "Hit Draft") */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Optional description of this break format, to inform users about how it works and why they might want to choose this format over another. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Indicates whether it is a group break, open to multiple participants, or a personal break for a single user. */
  breakCategory: {
    execute: (request?: boolean | number, defaultValue?: FormatBreakCategory) => Observable<FormatBreakCategory>
  }
  /** The break format determines the spots available to buy into, and the way that cards are allocated to those spots during the break. */
  boxbreakSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionObservableChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection,
    ) => Observable<BoxBreakNodeConnection>
  }) &
    (BoxBreakNodeConnectionObservableChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection,
      ) => Observable<BoxBreakNodeConnection>
    })
}

export interface BoxBreakNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: BoxBreakNodeEdgeRequest,
      defaultValue?: (BoxBreakNodeEdge | null)[],
    ) => Promise<(BoxBreakNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface BoxBreakNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: BoxBreakNodeEdgeRequest,
      defaultValue?: (BoxBreakNodeEdge | null)[],
    ) => Observable<(BoxBreakNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `BoxBreakNode` and its cursor. */
export interface BoxBreakNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `BoxBreakNode` and its cursor. */
export interface BoxBreakNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface UserTypePromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  firstName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  lastName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  email: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  discorduser: DiscordUserTypePromiseChain & {
    execute: (request: DiscordUserTypeRequest, defaultValue?: DiscordUserType | null) => Promise<DiscordUserType | null>
  }
  userprofile: UserProfileTypePromiseChain & {
    execute: (request: UserProfileTypeRequest, defaultValue?: UserProfileType | null) => Promise<UserProfileType | null>
  }
  teamMemberships: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    role?: String | null
  }) => TeamMemberNodeConnectionPromiseChain & {
    execute: (
      request: TeamMemberNodeConnectionRequest,
      defaultValue?: TeamMemberNodeConnection | null,
    ) => Promise<TeamMemberNodeConnection | null>
  }) &
    (TeamMemberNodeConnectionPromiseChain & {
      execute: (
        request: TeamMemberNodeConnectionRequest,
        defaultValue?: TeamMemberNodeConnection | null,
      ) => Promise<TeamMemberNodeConnection | null>
    })
  balance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  isStaff: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface UserTypeObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  firstName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  lastName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  email: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  discorduser: DiscordUserTypeObservableChain & {
    execute: (request: DiscordUserTypeRequest, defaultValue?: DiscordUserType | null) => Observable<DiscordUserType | null>
  }
  userprofile: UserProfileTypeObservableChain & {
    execute: (request: UserProfileTypeRequest, defaultValue?: UserProfileType | null) => Observable<UserProfileType | null>
  }
  teamMemberships: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    role?: String | null
  }) => TeamMemberNodeConnectionObservableChain & {
    execute: (
      request: TeamMemberNodeConnectionRequest,
      defaultValue?: TeamMemberNodeConnection | null,
    ) => Observable<TeamMemberNodeConnection | null>
  }) &
    (TeamMemberNodeConnectionObservableChain & {
      execute: (
        request: TeamMemberNodeConnectionRequest,
        defaultValue?: TeamMemberNodeConnection | null,
      ) => Observable<TeamMemberNodeConnection | null>
    })
  balance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  isStaff: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface DiscordUserTypePromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  discordId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  discordUsername: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  discordDiscriminator: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  user: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
}

export interface DiscordUserTypeObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  discordId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  discordUsername: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  discordDiscriminator: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  user: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
}

export interface UserProfileTypePromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  emailAddress: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  marketingEmails: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  buyTargetPercentage: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  sellTargetPercentage: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  dailyDigestHour: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  isCollectionPublic: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
}

export interface UserProfileTypeObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  emailAddress: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  marketingEmails: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  buyTargetPercentage: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  sellTargetPercentage: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  dailyDigestHour: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  isCollectionPublic: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
}

export interface TeamMemberNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: TeamMemberNodeEdgeRequest,
      defaultValue?: (TeamMemberNodeEdge | null)[],
    ) => Promise<(TeamMemberNodeEdge | null)[]>
  }
}

export interface TeamMemberNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: TeamMemberNodeEdgeRequest,
      defaultValue?: (TeamMemberNodeEdge | null)[],
    ) => Observable<(TeamMemberNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `TeamMemberNode` and its cursor. */
export interface TeamMemberNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: TeamMemberNodePromiseChain & {
    execute: (request: TeamMemberNodeRequest, defaultValue?: TeamMemberNode | null) => Promise<TeamMemberNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `TeamMemberNode` and its cursor. */
export interface TeamMemberNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: TeamMemberNodeObservableChain & {
    execute: (request: TeamMemberNodeRequest, defaultValue?: TeamMemberNode | null) => Observable<TeamMemberNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface TeamMemberNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  user: UserTypePromiseChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Promise<UserType> }
  channel: ChannelNodePromiseChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode) => Promise<ChannelNode>
  }
  role: { execute: (request?: boolean | number, defaultValue?: TeamMemberRole) => Promise<TeamMemberRole> }
}

export interface TeamMemberNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  user: UserTypeObservableChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Observable<UserType> }
  channel: ChannelNodeObservableChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode) => Observable<ChannelNode>
  }
  role: { execute: (request?: boolean | number, defaultValue?: TeamMemberRole) => Observable<TeamMemberRole> }
}

export interface ChannelNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** User-facing description of this channel and its focus. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** URL identifier for this channel. */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  bannerImageUrl: ((args?: {
    width?: Int | null
    height?: Int | null
    crop?: String | null
    quality?: Int | null
  }) => { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }) & {
    execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null>
  }
  profileImageUrl: ((args?: {
    width?: Int | null
    height?: Int | null
    crop?: String | null
    quality?: Int | null
  }) => { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }) & {
    execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null>
  }
  bannerImage: ImageNodePromiseChain & {
    execute: (request: ImageNodeRequest, defaultValue?: ImageNode | null) => Promise<ImageNode | null>
  }
  profileImage: ImageNodePromiseChain & {
    execute: (request: ImageNodeRequest, defaultValue?: ImageNode | null) => Promise<ImageNode | null>
  }
  auctions: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    era?: String | null
    state?: (String | null)[] | null
    featured?: Boolean | null
    currency?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => AuctionNodeConnectionPromiseChain & {
    execute: (
      request: AuctionNodeConnectionRequest,
      defaultValue?: AuctionNodeConnection | null,
    ) => Promise<AuctionNodeConnection | null>
  }) &
    (AuctionNodeConnectionPromiseChain & {
      execute: (
        request: AuctionNodeConnectionRequest,
        defaultValue?: AuctionNodeConnection | null,
      ) => Promise<AuctionNodeConnection | null>
    })
  teamMembers: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    role?: String | null
  }) => TeamMemberNodeConnectionPromiseChain & {
    execute: (
      request: TeamMemberNodeConnectionRequest,
      defaultValue?: TeamMemberNodeConnection | null,
    ) => Promise<TeamMemberNodeConnection | null>
  }) &
    (TeamMemberNodeConnectionPromiseChain & {
      execute: (
        request: TeamMemberNodeConnectionRequest,
        defaultValue?: TeamMemberNodeConnection | null,
      ) => Promise<TeamMemberNodeConnection | null>
    })
}

export interface ChannelNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** User-facing description of this channel and its focus. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** URL identifier for this channel. */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  bannerImageUrl: ((args?: {
    width?: Int | null
    height?: Int | null
    crop?: String | null
    quality?: Int | null
  }) => { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }) & {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
  profileImageUrl: ((args?: {
    width?: Int | null
    height?: Int | null
    crop?: String | null
    quality?: Int | null
  }) => { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }) & {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
  bannerImage: ImageNodeObservableChain & {
    execute: (request: ImageNodeRequest, defaultValue?: ImageNode | null) => Observable<ImageNode | null>
  }
  profileImage: ImageNodeObservableChain & {
    execute: (request: ImageNodeRequest, defaultValue?: ImageNode | null) => Observable<ImageNode | null>
  }
  auctions: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    era?: String | null
    state?: (String | null)[] | null
    featured?: Boolean | null
    currency?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => AuctionNodeConnectionObservableChain & {
    execute: (
      request: AuctionNodeConnectionRequest,
      defaultValue?: AuctionNodeConnection | null,
    ) => Observable<AuctionNodeConnection | null>
  }) &
    (AuctionNodeConnectionObservableChain & {
      execute: (
        request: AuctionNodeConnectionRequest,
        defaultValue?: AuctionNodeConnection | null,
      ) => Observable<AuctionNodeConnection | null>
    })
  teamMembers: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    role?: String | null
  }) => TeamMemberNodeConnectionObservableChain & {
    execute: (
      request: TeamMemberNodeConnectionRequest,
      defaultValue?: TeamMemberNodeConnection | null,
    ) => Observable<TeamMemberNodeConnection | null>
  }) &
    (TeamMemberNodeConnectionObservableChain & {
      execute: (
        request: TeamMemberNodeConnectionRequest,
        defaultValue?: TeamMemberNodeConnection | null,
      ) => Observable<TeamMemberNodeConnection | null>
    })
}

export interface ImageNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  url: ((args?: {
    width?: Int | null
    height?: Int | null
    crop?: String | null
    quality?: Int | null
  }) => { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }) & {
    execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null>
  }
  originalUrl: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface ImageNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  url: ((args?: {
    width?: Int | null
    height?: Int | null
    crop?: String | null
    quality?: Int | null
  }) => { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }) & {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
  originalUrl: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface AuctionNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: AuctionNodeEdgeRequest,
      defaultValue?: (AuctionNodeEdge | null)[],
    ) => Promise<(AuctionNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface AuctionNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: AuctionNodeEdgeRequest,
      defaultValue?: (AuctionNodeEdge | null)[],
    ) => Observable<(AuctionNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `AuctionNode` and its cursor. */
export interface AuctionNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: AuctionNodePromiseChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Promise<AuctionNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `AuctionNode` and its cursor. */
export interface AuctionNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: AuctionNodeObservableChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Observable<AuctionNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface AuctionNodePromiseChain {
  channel: ChannelNodePromiseChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode) => Promise<ChannelNode>
  }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** URL identifier for this auction. */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Assign a featured rank to promote this auction. Higher values take priority. Use sparingly; leave blank for most auctions. */
  featuredRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  /** A longer text description of this auction, such as an overview of the items included, special guests participating in the stream, and so on. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  state: { execute: (request?: boolean | number, defaultValue?: AuctionState) => Promise<AuctionState> }
  /** Date and time at which this auction is scheduled to begin. Presented to users as approximate. Leave blank if auction is not yet scheduled. */
  scheduledStartTime: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** The 11-digit video ID for the YouTube video this auction will be streamed on. */
  youtubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** The name for the Twitch channel this auction will be streamed on. */
  twitchChannelName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Controls how and when included lots are opened for bidding. */
  lotsOpenBidding: {
    execute: (request?: boolean | number, defaultValue?: AuctionLotsOpenBidding) => Promise<AuctionLotsOpenBidding>
  }
  /** The date and time that included lots should open for bidding. Leave blank if auction is configured to open lots immediately. */
  lotsOpenBiddingAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** The date and time that the first lot is scheduled to conclude bidding. */
  lotsConcludeBiddingFrom: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null>
  }
  /** The time period, in seconds, between the scheduled end times of adjacent lots. */
  lotInterval: { execute: (request?: boolean | number, defaultValue?: AuctionLotInterval) => Promise<AuctionLotInterval> }
  /** The time period, in seconds, at the end of a bidding window where new bids will reset the countdown to this time remaining. */
  lotBidExtensionPeriod: {
    execute: (
      request?: boolean | number,
      defaultValue?: AuctionLotBidExtensionPeriod,
    ) => Promise<AuctionLotBidExtensionPeriod>
  }
  currency: { execute: (request?: boolean | number, defaultValue?: AuctionCurrency) => Promise<AuctionCurrency> }
  /** Choose where the event is visible and who can access it. */
  visibility: { execute: (request?: boolean | number, defaultValue?: AuctionVisibility) => Promise<AuctionVisibility> }
  /** Tag to allow Discord channels to filter content to share in their server. */
  discordFilterTag: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  auctionphotoSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => AuctionPhotoNodeConnectionPromiseChain & {
    execute: (
      request: AuctionPhotoNodeConnectionRequest,
      defaultValue?: AuctionPhotoNodeConnection,
    ) => Promise<AuctionPhotoNodeConnection>
  }) &
    (AuctionPhotoNodeConnectionPromiseChain & {
      execute: (
        request: AuctionPhotoNodeConnectionRequest,
        defaultValue?: AuctionPhotoNodeConnection,
      ) => Promise<AuctionPhotoNodeConnection>
    })
  lotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionPromiseChain & {
    execute: (request: LotNodeConnectionRequest, defaultValue?: LotNodeConnection) => Promise<LotNodeConnection>
  }) &
    (LotNodeConnectionPromiseChain & {
      execute: (request: LotNodeConnectionRequest, defaultValue?: LotNodeConnection) => Promise<LotNodeConnection>
    })
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  lotCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  lotMinOpeningPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  lotMaxOpeningPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  lotRemainingCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  pusherChannelId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface AuctionNodeObservableChain {
  channel: ChannelNodeObservableChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode) => Observable<ChannelNode>
  }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** URL identifier for this auction. */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Assign a featured rank to promote this auction. Higher values take priority. Use sparingly; leave blank for most auctions. */
  featuredRank: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  /** A longer text description of this auction, such as an overview of the items included, special guests participating in the stream, and so on. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  state: { execute: (request?: boolean | number, defaultValue?: AuctionState) => Observable<AuctionState> }
  /** Date and time at which this auction is scheduled to begin. Presented to users as approximate. Leave blank if auction is not yet scheduled. */
  scheduledStartTime: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  /** The 11-digit video ID for the YouTube video this auction will be streamed on. */
  youtubeVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** The name for the Twitch channel this auction will be streamed on. */
  twitchChannelName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** The video ID for playing back the Twitch stream (digits only). */
  twitchVideoId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Controls how and when included lots are opened for bidding. */
  lotsOpenBidding: {
    execute: (request?: boolean | number, defaultValue?: AuctionLotsOpenBidding) => Observable<AuctionLotsOpenBidding>
  }
  /** The date and time that included lots should open for bidding. Leave blank if auction is configured to open lots immediately. */
  lotsOpenBiddingAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** The date and time that the first lot is scheduled to conclude bidding. */
  lotsConcludeBiddingFrom: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  /** The time period, in seconds, between the scheduled end times of adjacent lots. */
  lotInterval: { execute: (request?: boolean | number, defaultValue?: AuctionLotInterval) => Observable<AuctionLotInterval> }
  /** The time period, in seconds, at the end of a bidding window where new bids will reset the countdown to this time remaining. */
  lotBidExtensionPeriod: {
    execute: (
      request?: boolean | number,
      defaultValue?: AuctionLotBidExtensionPeriod,
    ) => Observable<AuctionLotBidExtensionPeriod>
  }
  currency: { execute: (request?: boolean | number, defaultValue?: AuctionCurrency) => Observable<AuctionCurrency> }
  /** Choose where the event is visible and who can access it. */
  visibility: { execute: (request?: boolean | number, defaultValue?: AuctionVisibility) => Observable<AuctionVisibility> }
  /** Tag to allow Discord channels to filter content to share in their server. */
  discordFilterTag: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  auctionphotoSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => AuctionPhotoNodeConnectionObservableChain & {
    execute: (
      request: AuctionPhotoNodeConnectionRequest,
      defaultValue?: AuctionPhotoNodeConnection,
    ) => Observable<AuctionPhotoNodeConnection>
  }) &
    (AuctionPhotoNodeConnectionObservableChain & {
      execute: (
        request: AuctionPhotoNodeConnectionRequest,
        defaultValue?: AuctionPhotoNodeConnection,
      ) => Observable<AuctionPhotoNodeConnection>
    })
  lotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionObservableChain & {
    execute: (request: LotNodeConnectionRequest, defaultValue?: LotNodeConnection) => Observable<LotNodeConnection>
  }) &
    (LotNodeConnectionObservableChain & {
      execute: (request: LotNodeConnectionRequest, defaultValue?: LotNodeConnection) => Observable<LotNodeConnection>
    })
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  lotCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  lotMinOpeningPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  lotMaxOpeningPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  lotRemainingCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  pusherChannelId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface AuctionPhotoNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: AuctionPhotoNodeEdgeRequest,
      defaultValue?: (AuctionPhotoNodeEdge | null)[],
    ) => Promise<(AuctionPhotoNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface AuctionPhotoNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: AuctionPhotoNodeEdgeRequest,
      defaultValue?: (AuctionPhotoNodeEdge | null)[],
    ) => Observable<(AuctionPhotoNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `AuctionPhotoNode` and its cursor. */
export interface AuctionPhotoNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: AuctionPhotoNodePromiseChain & {
    execute: (request: AuctionPhotoNodeRequest, defaultValue?: AuctionPhotoNode | null) => Promise<AuctionPhotoNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `AuctionPhotoNode` and its cursor. */
export interface AuctionPhotoNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: AuctionPhotoNodeObservableChain & {
    execute: (
      request: AuctionPhotoNodeRequest,
      defaultValue?: AuctionPhotoNode | null,
    ) => Observable<AuctionPhotoNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface AuctionPhotoNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  auction: AuctionNodePromiseChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode) => Promise<AuctionNode>
  }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface AuctionPhotoNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  auction: AuctionNodeObservableChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode) => Observable<AuctionNode>
  }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface LotNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: { execute: (request: LotNodeEdgeRequest, defaultValue?: (LotNodeEdge | null)[]) => Promise<(LotNodeEdge | null)[]> }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface LotNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: LotNodeEdgeRequest, defaultValue?: (LotNodeEdge | null)[]) => Observable<(LotNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `LotNode` and its cursor. */
export interface LotNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: LotNodePromiseChain & {
    execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Promise<LotNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `LotNode` and its cursor. */
export interface LotNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: LotNodeObservableChain & {
    execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Observable<LotNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface LotNodePromiseChain {
  order: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** An optional name which will overwrite the NFT name. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** An optional description which will overwrite the NFT description if it has one. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  auction: AuctionNodePromiseChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Promise<AuctionNode | null>
  }
  /** The NFT item to be sold in this lot. If not found here please import via the staff page. */
  nftItem: NFTItemNodePromiseChain & {
    execute: (request: NFTItemNodeRequest, defaultValue?: NFTItemNode) => Promise<NFTItemNode>
  }
  nftApprovalState: {
    execute: (request?: boolean | number, defaultValue?: LotNftApprovalState) => Promise<LotNftApprovalState>
  }
  /** Optional URL to link out to further details about this NFT. Leave blank to default to the NFT's OpenSea page. */
  externalUrl: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Wallet address for seller of lot */
  sellerWalletAddress: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  currency: { execute: (request?: boolean | number, defaultValue?: LotCurrency) => Promise<LotCurrency> }
  openingPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  initialDuration: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  /** Fixed end time for the initial bidding period for this lot. Set only one of initial duration or initial end time, based on whether a precise window or precise conclusion time are more important. */
  initialEndTime: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Optional scheduled start time to automatically open bidding for this lot. Leave blank to manually handle opening bidding. */
  scheduledStartTime: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  newBidMinimumDuration: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  state: { execute: (request?: boolean | number, defaultValue?: LotState) => Promise<LotState> }
  /** Date and time at which this lot opened for bidding. */
  biddingOpenedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which this lot is scheduled to conclude bidding. */
  biddingEndsAfter: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** Date and time at which this lot concluded bidding. */
  biddingEndedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  bidSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BidNodeConnectionPromiseChain & {
    execute: (request: BidNodeConnectionRequest, defaultValue?: BidNodeConnection) => Promise<BidNodeConnection>
  }) &
    (BidNodeConnectionPromiseChain & {
      execute: (request: BidNodeConnectionRequest, defaultValue?: BidNodeConnection) => Promise<BidNodeConnection>
    })
  lotphotoSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => LotPhotoNodeConnectionPromiseChain & {
    execute: (
      request: LotPhotoNodeConnectionRequest,
      defaultValue?: LotPhotoNodeConnection,
    ) => Promise<LotPhotoNodeConnection>
  }) &
    (LotPhotoNodeConnectionPromiseChain & {
      execute: (
        request: LotPhotoNodeConnectionRequest,
        defaultValue?: LotPhotoNodeConnection,
      ) => Promise<LotPhotoNodeConnection>
    })
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  bidCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  currentPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  highestBid: BidNodePromiseChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Promise<BidNode | null>
  }
  highestBidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bidPriceIncrement: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  minimumNextBidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  buyersPremiumPercentage: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  buyersPremiumFee: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  finalPurchasePrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  nftExternalUrl: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  smartContractLotId: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  nftCollectionName: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface LotNodeObservableChain {
  order: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** An optional name which will overwrite the NFT name. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** An optional description which will overwrite the NFT description if it has one. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  auction: AuctionNodeObservableChain & {
    execute: (request: AuctionNodeRequest, defaultValue?: AuctionNode | null) => Observable<AuctionNode | null>
  }
  /** The NFT item to be sold in this lot. If not found here please import via the staff page. */
  nftItem: NFTItemNodeObservableChain & {
    execute: (request: NFTItemNodeRequest, defaultValue?: NFTItemNode) => Observable<NFTItemNode>
  }
  nftApprovalState: {
    execute: (request?: boolean | number, defaultValue?: LotNftApprovalState) => Observable<LotNftApprovalState>
  }
  /** Optional URL to link out to further details about this NFT. Leave blank to default to the NFT's OpenSea page. */
  externalUrl: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Wallet address for seller of lot */
  sellerWalletAddress: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  currency: { execute: (request?: boolean | number, defaultValue?: LotCurrency) => Observable<LotCurrency> }
  openingPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  initialDuration: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  /** Fixed end time for the initial bidding period for this lot. Set only one of initial duration or initial end time, based on whether a precise window or precise conclusion time are more important. */
  initialEndTime: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Optional scheduled start time to automatically open bidding for this lot. Leave blank to manually handle opening bidding. */
  scheduledStartTime: {
    execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null>
  }
  newBidMinimumDuration: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  state: { execute: (request?: boolean | number, defaultValue?: LotState) => Observable<LotState> }
  /** Date and time at which this lot opened for bidding. */
  biddingOpenedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which this lot is scheduled to conclude bidding. */
  biddingEndsAfter: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** Date and time at which this lot concluded bidding. */
  biddingEndedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  bidSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => BidNodeConnectionObservableChain & {
    execute: (request: BidNodeConnectionRequest, defaultValue?: BidNodeConnection) => Observable<BidNodeConnection>
  }) &
    (BidNodeConnectionObservableChain & {
      execute: (request: BidNodeConnectionRequest, defaultValue?: BidNodeConnection) => Observable<BidNodeConnection>
    })
  lotphotoSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => LotPhotoNodeConnectionObservableChain & {
    execute: (
      request: LotPhotoNodeConnectionRequest,
      defaultValue?: LotPhotoNodeConnection,
    ) => Observable<LotPhotoNodeConnection>
  }) &
    (LotPhotoNodeConnectionObservableChain & {
      execute: (
        request: LotPhotoNodeConnectionRequest,
        defaultValue?: LotPhotoNodeConnection,
      ) => Observable<LotPhotoNodeConnection>
    })
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  bidCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  currentPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  highestBid: BidNodeObservableChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Observable<BidNode | null>
  }
  highestBidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bidPriceIncrement: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  minimumNextBidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  buyersPremiumPercentage: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  buyersPremiumFee: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  finalPurchasePrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  nftExternalUrl: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  smartContractLotId: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  nftCollectionName: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface NFTItemNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  tokenType: { execute: (request?: boolean | number, defaultValue?: NFTItemTokenType) => Promise<NFTItemTokenType> }
  tokenAddress: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  tokenId: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** The token_uri as it was imported from a third party service. */
  importedTokenUri: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** If the token URI uses ipfs then this is the canonical location in the format of ipfs://<ipfs_path>. */
  canonicalTokenUri: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  collection: NFTCollectionNodePromiseChain & {
    execute: (request: NFTCollectionNodeRequest, defaultValue?: NFTCollectionNode) => Promise<NFTCollectionNode>
  }
  /** The image_uri as it was imported from a third party service. */
  importedImageUri: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** If the image uses ipfs then this is the canonical location in the format of ipfs://<ipfs_path>. */
  canonicalImageUri: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  chain: { execute: (request?: boolean | number, defaultValue?: NFTItemChain) => Promise<NFTItemChain> }
  data: { execute: (request?: boolean | number, defaultValue?: JSONString | null) => Promise<JSONString | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  cachedImage: ImageNodePromiseChain & {
    execute: (request: ImageNodeRequest, defaultValue?: ImageNode | null) => Promise<ImageNode | null>
  }
  nftitemtraitSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => NFTItemTraitNodeConnectionPromiseChain & {
    execute: (
      request: NFTItemTraitNodeConnectionRequest,
      defaultValue?: NFTItemTraitNodeConnection,
    ) => Promise<NFTItemTraitNodeConnection>
  }) &
    (NFTItemTraitNodeConnectionPromiseChain & {
      execute: (
        request: NFTItemTraitNodeConnectionRequest,
        defaultValue?: NFTItemTraitNodeConnection,
      ) => Promise<NFTItemTraitNodeConnection>
    })
  /** The NFT item to be sold in this lot. If not found here please import via the staff page. */
  lotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionPromiseChain & {
    execute: (request: LotNodeConnectionRequest, defaultValue?: LotNodeConnection) => Promise<LotNodeConnection>
  }) &
    (LotNodeConnectionPromiseChain & {
      execute: (request: LotNodeConnectionRequest, defaultValue?: LotNodeConnection) => Promise<LotNodeConnection>
    })
  tokenAddressAndTokenId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  openseaUrl: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  cachedImageUrl: ((args?: {
    width?: Int | null
    height?: Int | null
    crop?: String | null
    quality?: Int | null
  }) => { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }) & {
    execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null>
  }
}

export interface NFTItemNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  tokenType: { execute: (request?: boolean | number, defaultValue?: NFTItemTokenType) => Observable<NFTItemTokenType> }
  tokenAddress: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  tokenId: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** The token_uri as it was imported from a third party service. */
  importedTokenUri: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** If the token URI uses ipfs then this is the canonical location in the format of ipfs://<ipfs_path>. */
  canonicalTokenUri: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  collection: NFTCollectionNodeObservableChain & {
    execute: (request: NFTCollectionNodeRequest, defaultValue?: NFTCollectionNode) => Observable<NFTCollectionNode>
  }
  /** The image_uri as it was imported from a third party service. */
  importedImageUri: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** If the image uses ipfs then this is the canonical location in the format of ipfs://<ipfs_path>. */
  canonicalImageUri: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  chain: { execute: (request?: boolean | number, defaultValue?: NFTItemChain) => Observable<NFTItemChain> }
  data: { execute: (request?: boolean | number, defaultValue?: JSONString | null) => Observable<JSONString | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  cachedImage: ImageNodeObservableChain & {
    execute: (request: ImageNodeRequest, defaultValue?: ImageNode | null) => Observable<ImageNode | null>
  }
  nftitemtraitSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => NFTItemTraitNodeConnectionObservableChain & {
    execute: (
      request: NFTItemTraitNodeConnectionRequest,
      defaultValue?: NFTItemTraitNodeConnection,
    ) => Observable<NFTItemTraitNodeConnection>
  }) &
    (NFTItemTraitNodeConnectionObservableChain & {
      execute: (
        request: NFTItemTraitNodeConnectionRequest,
        defaultValue?: NFTItemTraitNodeConnection,
      ) => Observable<NFTItemTraitNodeConnection>
    })
  /** The NFT item to be sold in this lot. If not found here please import via the staff page. */
  lotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    state?: (String | null)[] | null
    /** Ordering */
    orderBy?: String | null
  }) => LotNodeConnectionObservableChain & {
    execute: (request: LotNodeConnectionRequest, defaultValue?: LotNodeConnection) => Observable<LotNodeConnection>
  }) &
    (LotNodeConnectionObservableChain & {
      execute: (request: LotNodeConnectionRequest, defaultValue?: LotNodeConnection) => Observable<LotNodeConnection>
    })
  tokenAddressAndTokenId: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
  openseaUrl: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  cachedImageUrl: ((args?: {
    width?: Int | null
    height?: Int | null
    crop?: String | null
    quality?: Int | null
  }) => { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }) & {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
}

export interface NFTCollectionNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  nftitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    chain?: String | null
    collection?: ID | null
    tokenAddress?: String | null
    tokenId?: String | null
    tokenAddressAndTokenIds?: (String | null)[] | null
  }) => NFTItemNodeConnectionPromiseChain & {
    execute: (request: NFTItemNodeConnectionRequest, defaultValue?: NFTItemNodeConnection) => Promise<NFTItemNodeConnection>
  }) &
    (NFTItemNodeConnectionPromiseChain & {
      execute: (
        request: NFTItemNodeConnectionRequest,
        defaultValue?: NFTItemNodeConnection,
      ) => Promise<NFTItemNodeConnection>
    })
}

export interface NFTCollectionNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  updatedAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  nftitemSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    chain?: String | null
    collection?: ID | null
    tokenAddress?: String | null
    tokenId?: String | null
    tokenAddressAndTokenIds?: (String | null)[] | null
  }) => NFTItemNodeConnectionObservableChain & {
    execute: (
      request: NFTItemNodeConnectionRequest,
      defaultValue?: NFTItemNodeConnection,
    ) => Observable<NFTItemNodeConnection>
  }) &
    (NFTItemNodeConnectionObservableChain & {
      execute: (
        request: NFTItemNodeConnectionRequest,
        defaultValue?: NFTItemNodeConnection,
      ) => Observable<NFTItemNodeConnection>
    })
}

export interface NFTItemNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: NFTItemNodeEdgeRequest,
      defaultValue?: (NFTItemNodeEdge | null)[],
    ) => Promise<(NFTItemNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface NFTItemNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: NFTItemNodeEdgeRequest,
      defaultValue?: (NFTItemNodeEdge | null)[],
    ) => Observable<(NFTItemNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `NFTItemNode` and its cursor. */
export interface NFTItemNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: NFTItemNodePromiseChain & {
    execute: (request: NFTItemNodeRequest, defaultValue?: NFTItemNode | null) => Promise<NFTItemNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `NFTItemNode` and its cursor. */
export interface NFTItemNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: NFTItemNodeObservableChain & {
    execute: (request: NFTItemNodeRequest, defaultValue?: NFTItemNode | null) => Observable<NFTItemNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface NFTItemTraitNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: NFTItemTraitNodeEdgeRequest,
      defaultValue?: (NFTItemTraitNodeEdge | null)[],
    ) => Promise<(NFTItemTraitNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface NFTItemTraitNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: NFTItemTraitNodeEdgeRequest,
      defaultValue?: (NFTItemTraitNodeEdge | null)[],
    ) => Observable<(NFTItemTraitNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `NFTItemTraitNode` and its cursor. */
export interface NFTItemTraitNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: NFTItemTraitNodePromiseChain & {
    execute: (request: NFTItemTraitNodeRequest, defaultValue?: NFTItemTraitNode | null) => Promise<NFTItemTraitNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `NFTItemTraitNode` and its cursor. */
export interface NFTItemTraitNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: NFTItemTraitNodeObservableChain & {
    execute: (
      request: NFTItemTraitNodeRequest,
      defaultValue?: NFTItemTraitNode | null,
    ) => Observable<NFTItemTraitNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface NFTItemTraitNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  nft: NFTItemNodePromiseChain & {
    execute: (request: NFTItemNodeRequest, defaultValue?: NFTItemNode) => Promise<NFTItemNode>
  }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  value: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

export interface NFTItemTraitNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  nft: NFTItemNodeObservableChain & {
    execute: (request: NFTItemNodeRequest, defaultValue?: NFTItemNode) => Observable<NFTItemNode>
  }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  value: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface BidNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: { execute: (request: BidNodeEdgeRequest, defaultValue?: (BidNodeEdge | null)[]) => Promise<(BidNodeEdge | null)[]> }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface BidNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: BidNodeEdgeRequest, defaultValue?: (BidNodeEdge | null)[]) => Observable<(BidNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `BidNode` and its cursor. */
export interface BidNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: BidNodePromiseChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Promise<BidNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `BidNode` and its cursor. */
export interface BidNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: BidNodeObservableChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Observable<BidNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface BidNodePromiseChain {
  lot: LotNodePromiseChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode) => Promise<LotNode> }
  user: UserTypePromiseChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Promise<UserType> }
  bidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  state: { execute: (request?: boolean | number, defaultValue?: BidState) => Promise<BidState> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  bidPriceCurrency: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface BidNodeObservableChain {
  lot: LotNodeObservableChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode) => Observable<LotNode> }
  user: UserTypeObservableChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Observable<UserType> }
  bidPrice: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  state: { execute: (request?: boolean | number, defaultValue?: BidState) => Observable<BidState> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  bidPriceCurrency: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface LotPhotoNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: LotPhotoNodeEdgeRequest,
      defaultValue?: (LotPhotoNodeEdge | null)[],
    ) => Promise<(LotPhotoNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface LotPhotoNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: LotPhotoNodeEdgeRequest,
      defaultValue?: (LotPhotoNodeEdge | null)[],
    ) => Observable<(LotPhotoNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `LotPhotoNode` and its cursor. */
export interface LotPhotoNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: LotPhotoNodePromiseChain & {
    execute: (request: LotPhotoNodeRequest, defaultValue?: LotPhotoNode | null) => Promise<LotPhotoNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `LotPhotoNode` and its cursor. */
export interface LotPhotoNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: LotPhotoNodeObservableChain & {
    execute: (request: LotPhotoNodeRequest, defaultValue?: LotPhotoNode | null) => Observable<LotPhotoNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface LotPhotoNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  lot: LotNodePromiseChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode) => Promise<LotNode> }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface LotPhotoNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  lot: LotNodeObservableChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode) => Observable<LotNode> }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface BreakLocationNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** e.g. StarStock New York office, Partner Studio... */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Enable to allow users to request an item in their collection be broken during a personal break. */
  allowPersonalBreakRequests: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  boxbreakSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionPromiseChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection,
    ) => Promise<BoxBreakNodeConnection>
  }) &
    (BoxBreakNodeConnectionPromiseChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection,
      ) => Promise<BoxBreakNodeConnection>
    })
}

export interface BreakLocationNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** e.g. StarStock New York office, Partner Studio... */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Enable to allow users to request an item in their collection be broken during a personal break. */
  allowPersonalBreakRequests: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  boxbreakSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
    query?: String | null
    spotsAvailable?: Boolean | null
    state?: (String | null)[] | null
    stateGroup?: (String | null)[] | null
    featured?: Boolean | null
    breakCategory?: (String | null)[] | null
    allowPersonalBreakRequests?: Boolean | null
    /** Ordering */
    orderBy?: String | null
  }) => BoxBreakNodeConnectionObservableChain & {
    execute: (
      request: BoxBreakNodeConnectionRequest,
      defaultValue?: BoxBreakNodeConnection,
    ) => Observable<BoxBreakNodeConnection>
  }) &
    (BoxBreakNodeConnectionObservableChain & {
      execute: (
        request: BoxBreakNodeConnectionRequest,
        defaultValue?: BoxBreakNodeConnection,
      ) => Observable<BoxBreakNodeConnection>
    })
}

export interface BoxNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: { execute: (request: BoxNodeEdgeRequest, defaultValue?: (BoxNodeEdge | null)[]) => Promise<(BoxNodeEdge | null)[]> }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface BoxNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: BoxNodeEdgeRequest, defaultValue?: (BoxNodeEdge | null)[]) => Observable<(BoxNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `BoxNode` and its cursor. */
export interface BoxNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: BoxNodePromiseChain & {
    execute: (request: BoxNodeRequest, defaultValue?: BoxNode | null) => Promise<BoxNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `BoxNode` and its cursor. */
export interface BoxNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: BoxNodeObservableChain & {
    execute: (request: BoxNodeRequest, defaultValue?: BoxNode | null) => Observable<BoxNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface BoxNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Optional display name for this this breakable product (e.g. Prizm Football 2020 Hobby Box). If blank, name is taken from associated product type. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Break in which this box is going to be or was opened. */
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  createdBy: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
}

export interface BoxNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Optional display name for this this breakable product (e.g. Prizm Football 2020 Hobby Box). If blank, name is taken from associated product type. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Break in which this box is going to be or was opened. */
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  createdBy: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
}

export interface BoxBreakPhotoNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: BoxBreakPhotoNodeEdgeRequest,
      defaultValue?: (BoxBreakPhotoNodeEdge | null)[],
    ) => Promise<(BoxBreakPhotoNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface BoxBreakPhotoNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: BoxBreakPhotoNodeEdgeRequest,
      defaultValue?: (BoxBreakPhotoNodeEdge | null)[],
    ) => Observable<(BoxBreakPhotoNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `BoxBreakPhotoNode` and its cursor. */
export interface BoxBreakPhotoNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: BoxBreakPhotoNodePromiseChain & {
    execute: (
      request: BoxBreakPhotoNodeRequest,
      defaultValue?: BoxBreakPhotoNode | null,
    ) => Promise<BoxBreakPhotoNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `BoxBreakPhotoNode` and its cursor. */
export interface BoxBreakPhotoNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: BoxBreakPhotoNodeObservableChain & {
    execute: (
      request: BoxBreakPhotoNodeRequest,
      defaultValue?: BoxBreakPhotoNode | null,
    ) => Observable<BoxBreakPhotoNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface BoxBreakPhotoNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Promise<BoxBreakNode>
  }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface BoxBreakPhotoNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Observable<BoxBreakNode>
  }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface SpotNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: SpotNodeEdgeRequest, defaultValue?: (SpotNodeEdge | null)[]) => Promise<(SpotNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface SpotNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: SpotNodeEdgeRequest, defaultValue?: (SpotNodeEdge | null)[]) => Observable<(SpotNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `SpotNode` and its cursor. */
export interface SpotNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: SpotNodePromiseChain & {
    execute: (request: SpotNodeRequest, defaultValue?: SpotNode | null) => Promise<SpotNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `SpotNode` and its cursor. */
export interface SpotNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: SpotNodeObservableChain & {
    execute: (request: SpotNodeRequest, defaultValue?: SpotNode | null) => Observable<SpotNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface SpotNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Promise<BoxBreakNode>
  }
  /** Spots are ordered within the parent break by their rank (asc). Rank will also be used as slot name if one is not specified (e.g. random breaks). */
  rank: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** Optional display name for this spot in the break (e.g. Cleveland Browns), or may be left blank to use rank. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  priceCurrency: { execute: (request?: boolean | number, defaultValue?: SpotPriceCurrency) => Promise<SpotPriceCurrency> }
  price: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  purchasedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  /** The spot in the break that this hit is assigned to. */
  hitSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => HitNodeConnectionPromiseChain & {
    execute: (request: HitNodeConnectionRequest, defaultValue?: HitNodeConnection) => Promise<HitNodeConnection>
  }) &
    (HitNodeConnectionPromiseChain & {
      execute: (request: HitNodeConnectionRequest, defaultValue?: HitNodeConnection) => Promise<HitNodeConnection>
    })
  personalbreakrequest: PersonalBreakRequestNodePromiseChain & {
    execute: (
      request: PersonalBreakRequestNodeRequest,
      defaultValue?: PersonalBreakRequestNode | null,
    ) => Promise<PersonalBreakRequestNode | null>
  }
  isPersonalBreakRequest: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
  myParticipant: ParticipantNodePromiseChain & {
    execute: (request: ParticipantNodeRequest, defaultValue?: ParticipantNode | null) => Promise<ParticipantNode | null>
  }
}

export interface SpotNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Observable<BoxBreakNode>
  }
  /** Spots are ordered within the parent break by their rank (asc). Rank will also be used as slot name if one is not specified (e.g. random breaks). */
  rank: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** Optional display name for this spot in the break (e.g. Cleveland Browns), or may be left blank to use rank. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  priceCurrency: { execute: (request?: boolean | number, defaultValue?: SpotPriceCurrency) => Observable<SpotPriceCurrency> }
  price: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  purchasedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  /** The spot in the break that this hit is assigned to. */
  hitSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => HitNodeConnectionObservableChain & {
    execute: (request: HitNodeConnectionRequest, defaultValue?: HitNodeConnection) => Observable<HitNodeConnection>
  }) &
    (HitNodeConnectionObservableChain & {
      execute: (request: HitNodeConnectionRequest, defaultValue?: HitNodeConnection) => Observable<HitNodeConnection>
    })
  personalbreakrequest: PersonalBreakRequestNodeObservableChain & {
    execute: (
      request: PersonalBreakRequestNodeRequest,
      defaultValue?: PersonalBreakRequestNode | null,
    ) => Observable<PersonalBreakRequestNode | null>
  }
  isPersonalBreakRequest: {
    execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null>
  }
  myParticipant: ParticipantNodeObservableChain & {
    execute: (request: ParticipantNodeRequest, defaultValue?: ParticipantNode | null) => Observable<ParticipantNode | null>
  }
}

export interface HitNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: { execute: (request: HitNodeEdgeRequest, defaultValue?: (HitNodeEdge | null)[]) => Promise<(HitNodeEdge | null)[]> }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface HitNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: HitNodeEdgeRequest, defaultValue?: (HitNodeEdge | null)[]) => Observable<(HitNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `HitNode` and its cursor. */
export interface HitNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: HitNodePromiseChain & {
    execute: (request: HitNodeRequest, defaultValue?: HitNode | null) => Promise<HitNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `HitNode` and its cursor. */
export interface HitNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: HitNodeObservableChain & {
    execute: (request: HitNodeRequest, defaultValue?: HitNode | null) => Observable<HitNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface HitNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** User-facing display name for this hit (e.g. "🔥 EPIC PULL! Tom Brady Prizm Auto Silver #/10!") */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** An optional longer description, giving context to the break or hit. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** The spot in the break that this hit is assigned to. */
  spot: SpotNodePromiseChain & { execute: (request: SpotNodeRequest, defaultValue?: SpotNode) => Promise<SpotNode> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  createdBy: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface HitNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** User-facing display name for this hit (e.g. "🔥 EPIC PULL! Tom Brady Prizm Auto Silver #/10!") */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** An optional longer description, giving context to the break or hit. */
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** The spot in the break that this hit is assigned to. */
  spot: SpotNodeObservableChain & { execute: (request: SpotNodeRequest, defaultValue?: SpotNode) => Observable<SpotNode> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  createdBy: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  photo: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoXs: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoSm: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  photoMd: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface PersonalBreakRequestNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  user: UserTypePromiseChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Promise<UserType> }
  /** The scheduled personal break that the user would like their box broken as part of. */
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Promise<BoxBreakNode>
  }
  /** The spot in the break that represents this accepted request. */
  spot: SpotNodePromiseChain & {
    execute: (request: SpotNodeRequest, defaultValue?: SpotNode | null) => Promise<SpotNode | null>
  }
  /** Optional, additional request information, such as a preferred start time. */
  requestDetails: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  breakFeeCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: PersonalBreakRequestBreakFeeCurrency,
    ) => Promise<PersonalBreakRequestBreakFeeCurrency>
  }
  breakFee: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  state: {
    execute: (request?: boolean | number, defaultValue?: PersonalBreakRequestState) => Promise<PersonalBreakRequestState>
  }
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  address: AddressNodePromiseChain & {
    execute: (request: AddressNodeRequest, defaultValue?: AddressNode | null) => Promise<AddressNode | null>
  }
}

export interface PersonalBreakRequestNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  user: UserTypeObservableChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Observable<UserType> }
  /** The scheduled personal break that the user would like their box broken as part of. */
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Observable<BoxBreakNode>
  }
  /** The spot in the break that represents this accepted request. */
  spot: SpotNodeObservableChain & {
    execute: (request: SpotNodeRequest, defaultValue?: SpotNode | null) => Observable<SpotNode | null>
  }
  /** Optional, additional request information, such as a preferred start time. */
  requestDetails: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  breakFeeCurrency: {
    execute: (
      request?: boolean | number,
      defaultValue?: PersonalBreakRequestBreakFeeCurrency,
    ) => Observable<PersonalBreakRequestBreakFeeCurrency>
  }
  breakFee: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  state: {
    execute: (request?: boolean | number, defaultValue?: PersonalBreakRequestState) => Observable<PersonalBreakRequestState>
  }
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  address: AddressNodeObservableChain & {
    execute: (request: AddressNodeRequest, defaultValue?: AddressNode | null) => Observable<AddressNode | null>
  }
}

export interface AddressNodePromiseChain {
  description: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  addressLines: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  locality: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  region: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  postalCode: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  country: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  role: { execute: (request?: boolean | number, defaultValue?: AddressRole) => Promise<AddressRole> }
  default: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
}

export interface AddressNodeObservableChain {
  description: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  addressLines: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  locality: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  region: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  postalCode: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  country: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  role: { execute: (request?: boolean | number, defaultValue?: AddressRole) => Observable<AddressRole> }
  default: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
}

export interface ParticipantNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Promise<BoxBreakNode>
  }
  user: UserTypePromiseChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Promise<UserType> }
  joinedAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  /** The streaming platform on which the participant plans to watch. */
  liveStreamPlatform: {
    execute: (
      request?: boolean | number,
      defaultValue?: ParticipantLiveStreamPlatform,
    ) => Promise<ParticipantLiveStreamPlatform>
  }
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
  address: AddressNodePromiseChain & {
    execute: (request: AddressNodeRequest, defaultValue?: AddressNode | null) => Promise<AddressNode | null>
  }
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** The break participant who has claimed this spot (note that one participant may claim multiple spots). */
  spotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SpotNodeConnectionPromiseChain & {
    execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Promise<SpotNodeConnection>
  }) &
    (SpotNodeConnectionPromiseChain & {
      execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Promise<SpotNodeConnection>
    })
}

export interface ParticipantNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode) => Observable<BoxBreakNode>
  }
  user: UserTypeObservableChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Observable<UserType> }
  joinedAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  /** The streaming platform on which the participant plans to watch. */
  liveStreamPlatform: {
    execute: (
      request?: boolean | number,
      defaultValue?: ParticipantLiveStreamPlatform,
    ) => Observable<ParticipantLiveStreamPlatform>
  }
  /** Ship all cards home, rather than adding qualifying cards to the participant's StarStock collection. */
  preferShipHome: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
  address: AddressNodeObservableChain & {
    execute: (request: AddressNodeRequest, defaultValue?: AddressNode | null) => Observable<AddressNode | null>
  }
  /** Additional instructions the participant wishes to share with the breaker (e.g. shipping address, discard non-rookie hits, etc…). */
  additionalInstructions: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** The break participant who has claimed this spot (note that one participant may claim multiple spots). */
  spotSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => SpotNodeConnectionObservableChain & {
    execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Observable<SpotNodeConnection>
  }) &
    (SpotNodeConnectionObservableChain & {
      execute: (request: SpotNodeConnectionRequest, defaultValue?: SpotNodeConnection) => Observable<SpotNodeConnection>
    })
}

export interface PersonalBreakRequestNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: PersonalBreakRequestNodeEdgeRequest,
      defaultValue?: (PersonalBreakRequestNodeEdge | null)[],
    ) => Promise<(PersonalBreakRequestNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface PersonalBreakRequestNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: PersonalBreakRequestNodeEdgeRequest,
      defaultValue?: (PersonalBreakRequestNodeEdge | null)[],
    ) => Observable<(PersonalBreakRequestNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `PersonalBreakRequestNode` and its cursor. */
export interface PersonalBreakRequestNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: PersonalBreakRequestNodePromiseChain & {
    execute: (
      request: PersonalBreakRequestNodeRequest,
      defaultValue?: PersonalBreakRequestNode | null,
    ) => Promise<PersonalBreakRequestNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `PersonalBreakRequestNode` and its cursor. */
export interface PersonalBreakRequestNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: PersonalBreakRequestNodeObservableChain & {
    execute: (
      request: PersonalBreakRequestNodeRequest,
      defaultValue?: PersonalBreakRequestNode | null,
    ) => Observable<PersonalBreakRequestNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface RemintNFTNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: RemintNFTNodeEdgeRequest,
      defaultValue?: (RemintNFTNodeEdge | null)[],
    ) => Promise<(RemintNFTNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface RemintNFTNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: RemintNFTNodeEdgeRequest,
      defaultValue?: (RemintNFTNodeEdge | null)[],
    ) => Observable<(RemintNFTNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `RemintNFTNode` and its cursor. */
export interface RemintNFTNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: RemintNFTNodePromiseChain & {
    execute: (request: RemintNFTNodeRequest, defaultValue?: RemintNFTNode | null) => Promise<RemintNFTNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `RemintNFTNode` and its cursor. */
export interface RemintNFTNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: RemintNFTNodeObservableChain & {
    execute: (request: RemintNFTNodeRequest, defaultValue?: RemintNFTNode | null) => Observable<RemintNFTNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface RemintNFTNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Display name for this nft item. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Display name for the collection this nft item belongs to. */
  collectionName: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  blockchain: { execute: (request?: boolean | number, defaultValue?: RemintNFTBlockchain) => Promise<RemintNFTBlockchain> }
  /** Optional Link to external URL. */
  externalLink: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Optional Name of external URL page. */
  externalLinkText: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  image: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Break in which this nft item is going to be or was opened. */
  boxBreak: BoxBreakNodePromiseChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Promise<BoxBreakNode | null>
  }
}

export interface RemintNFTNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Display name for this nft item. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Display name for the collection this nft item belongs to. */
  collectionName: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  blockchain: {
    execute: (request?: boolean | number, defaultValue?: RemintNFTBlockchain) => Observable<RemintNFTBlockchain>
  }
  /** Optional Link to external URL. */
  externalLink: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Optional Name of external URL page. */
  externalLinkText: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  image: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Break in which this nft item is going to be or was opened. */
  boxBreak: BoxBreakNodeObservableChain & {
    execute: (request: BoxBreakNodeRequest, defaultValue?: BoxBreakNode | null) => Observable<BoxBreakNode | null>
  }
}

export interface StreamNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (request: StreamNodeEdgeRequest, defaultValue?: (StreamNodeEdge | null)[]) => Promise<(StreamNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface StreamNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: StreamNodeEdgeRequest,
      defaultValue?: (StreamNodeEdge | null)[],
    ) => Observable<(StreamNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `StreamNode` and its cursor. */
export interface StreamNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: StreamNodePromiseChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Promise<StreamNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `StreamNode` and its cursor. */
export interface StreamNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: StreamNodeObservableChain & {
    execute: (request: StreamNodeRequest, defaultValue?: StreamNode | null) => Observable<StreamNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface UserNotificationPreferenceNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  notificationType: {
    execute: (
      request?: boolean | number,
      defaultValue?: UserNotificationPreferenceNotificationType,
    ) => Promise<UserNotificationPreferenceNotificationType>
  }
  targetType: {
    execute: (
      request?: boolean | number,
      defaultValue?: UserNotificationPreferenceTargetType,
    ) => Promise<UserNotificationPreferenceTargetType>
  }
}

export interface UserNotificationPreferenceNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  notificationType: {
    execute: (
      request?: boolean | number,
      defaultValue?: UserNotificationPreferenceNotificationType,
    ) => Observable<UserNotificationPreferenceNotificationType>
  }
  targetType: {
    execute: (
      request?: boolean | number,
      defaultValue?: UserNotificationPreferenceTargetType,
    ) => Observable<UserNotificationPreferenceTargetType>
  }
}

export interface NFTCollectionNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: NFTCollectionNodeEdgeRequest,
      defaultValue?: (NFTCollectionNodeEdge | null)[],
    ) => Promise<(NFTCollectionNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface NFTCollectionNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: NFTCollectionNodeEdgeRequest,
      defaultValue?: (NFTCollectionNodeEdge | null)[],
    ) => Observable<(NFTCollectionNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `NFTCollectionNode` and its cursor. */
export interface NFTCollectionNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: NFTCollectionNodePromiseChain & {
    execute: (
      request: NFTCollectionNodeRequest,
      defaultValue?: NFTCollectionNode | null,
    ) => Promise<NFTCollectionNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `NFTCollectionNode` and its cursor. */
export interface NFTCollectionNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: NFTCollectionNodeObservableChain & {
    execute: (
      request: NFTCollectionNodeRequest,
      defaultValue?: NFTCollectionNode | null,
    ) => Observable<NFTCollectionNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface DepositNodePromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  user: UserTypePromiseChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Promise<UserType> }
  /** Determines what state the bidding deposit is in */
  depositStatus: {
    execute: (request?: boolean | number, defaultValue?: BiddingDepositDepositStatus) => Promise<BiddingDepositDepositStatus>
  }
  biddingEnabled: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface DepositNodeObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  user: UserTypeObservableChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Observable<UserType> }
  /** Determines what state the bidding deposit is in */
  depositStatus: {
    execute: (
      request?: boolean | number,
      defaultValue?: BiddingDepositDepositStatus,
    ) => Observable<BiddingDepositDepositStatus>
  }
  biddingEnabled: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface ChannelNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: ChannelNodeEdgeRequest,
      defaultValue?: (ChannelNodeEdge | null)[],
    ) => Promise<(ChannelNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface ChannelNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: ChannelNodeEdgeRequest,
      defaultValue?: (ChannelNodeEdge | null)[],
    ) => Observable<(ChannelNodeEdge | null)[]>
  }
  totalCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  edgeCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** A Relay edge containing a `ChannelNode` and its cursor. */
export interface ChannelNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: ChannelNodePromiseChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode | null) => Promise<ChannelNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `ChannelNode` and its cursor. */
export interface ChannelNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: ChannelNodeObservableChain & {
    execute: (request: ChannelNodeRequest, defaultValue?: ChannelNode | null) => Observable<ChannelNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface AnnouncementNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  state: {
    execute: (request?: boolean | number, defaultValue?: AnnouncementBannerState) => Promise<AnnouncementBannerState>
  }
  /** Automatically generated. Example: 2021-10-16-submit-for-grading-update */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Example: Submit for grading update */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Text that will be highlighted in the announcement banner  */
  prefixText: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  /** Announcement body content */
  bodyText: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** Link title */
  linkTitle: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  /** URL linking to the announced target */
  linkUrl: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  style: AnnouncementStyleNodePromiseChain & {
    execute: (request: AnnouncementStyleNodeRequest, defaultValue?: AnnouncementStyleNode) => Promise<AnnouncementStyleNode>
  }
}

export interface AnnouncementNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  state: {
    execute: (request?: boolean | number, defaultValue?: AnnouncementBannerState) => Observable<AnnouncementBannerState>
  }
  /** Automatically generated. Example: 2021-10-16-submit-for-grading-update */
  slug: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Example: Submit for grading update */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Text that will be highlighted in the announcement banner  */
  prefixText: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  /** Announcement body content */
  bodyText: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** Link title */
  linkTitle: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  /** URL linking to the announced target */
  linkUrl: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  style: AnnouncementStyleNodeObservableChain & {
    execute: (
      request: AnnouncementStyleNodeRequest,
      defaultValue?: AnnouncementStyleNode,
    ) => Observable<AnnouncementStyleNode>
  }
}

export interface AnnouncementStyleNodePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  /** Examples: Breaks, Product announcements, Technical announcements, Marketing, etc. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  badgeTextColor: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  linkTextColor: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  badgeGradientStart: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  badgeGradientEnd: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  announcementbannerSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => AnnouncementNodeConnectionPromiseChain & {
    execute: (
      request: AnnouncementNodeConnectionRequest,
      defaultValue?: AnnouncementNodeConnection,
    ) => Promise<AnnouncementNodeConnection>
  }) &
    (AnnouncementNodeConnectionPromiseChain & {
      execute: (
        request: AnnouncementNodeConnectionRequest,
        defaultValue?: AnnouncementNodeConnection,
      ) => Promise<AnnouncementNodeConnection>
    })
}

export interface AnnouncementStyleNodeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  /** Examples: Breaks, Product announcements, Technical announcements, Marketing, etc. */
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  badgeTextColor: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  linkTextColor: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  badgeGradientStart: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  badgeGradientEnd: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  announcementbannerSet: ((args?: {
    offset?: Int | null
    before?: String | null
    after?: String | null
    first?: Int | null
    last?: Int | null
  }) => AnnouncementNodeConnectionObservableChain & {
    execute: (
      request: AnnouncementNodeConnectionRequest,
      defaultValue?: AnnouncementNodeConnection,
    ) => Observable<AnnouncementNodeConnection>
  }) &
    (AnnouncementNodeConnectionObservableChain & {
      execute: (
        request: AnnouncementNodeConnectionRequest,
        defaultValue?: AnnouncementNodeConnection,
      ) => Observable<AnnouncementNodeConnection>
    })
}

export interface AnnouncementNodeConnectionPromiseChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoPromiseChain & { execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Promise<PageInfo> }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: AnnouncementNodeEdgeRequest,
      defaultValue?: (AnnouncementNodeEdge | null)[],
    ) => Promise<(AnnouncementNodeEdge | null)[]>
  }
}

export interface AnnouncementNodeConnectionObservableChain {
  /** Pagination data for this connection. */
  pageInfo: PageInfoObservableChain & {
    execute: (request: PageInfoRequest, defaultValue?: PageInfo) => Observable<PageInfo>
  }
  /** Contains the nodes in this connection. */
  edges: {
    execute: (
      request: AnnouncementNodeEdgeRequest,
      defaultValue?: (AnnouncementNodeEdge | null)[],
    ) => Observable<(AnnouncementNodeEdge | null)[]>
  }
}

/** A Relay edge containing a `AnnouncementNode` and its cursor. */
export interface AnnouncementNodeEdgePromiseChain {
  /** The item at the end of the edge */
  node: AnnouncementNodePromiseChain & {
    execute: (request: AnnouncementNodeRequest, defaultValue?: AnnouncementNode | null) => Promise<AnnouncementNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** A Relay edge containing a `AnnouncementNode` and its cursor. */
export interface AnnouncementNodeEdgeObservableChain {
  /** The item at the end of the edge */
  node: AnnouncementNodeObservableChain & {
    execute: (
      request: AnnouncementNodeRequest,
      defaultValue?: AnnouncementNode | null,
    ) => Observable<AnnouncementNode | null>
  }
  /** A cursor for use in pagination */
  cursor: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface PromotionTypePromiseChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  description: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface PromotionTypeObservableChain {
  /** The ID of the object. */
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  description: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface MutationPromiseChain {
  createPaymentIntent: (args: {
    amount: Int
    saveCard?: Boolean | null
  }) => CreatePaymentIntentPromiseChain & {
    execute: (
      request: CreatePaymentIntentRequest,
      defaultValue?: CreatePaymentIntent | null,
    ) => Promise<CreatePaymentIntent | null>
  }
  createWithdrawalRequest: (args: {
    addressLine1?: String | null
    addressLine2?: String | null
    amount: Float
    checkRecipient?: String | null
    city?: String | null
    country?: String | null
    paymentMethod: WithdrawalPaymentMethods
    paypalEmail?: String | null
    postalTracking?: Boolean | null
    state?: String | null
    zipCode?: String | null
  }) => CreateWithdrawalRequestPromiseChain & {
    execute: (
      request: CreateWithdrawalRequestRequest,
      defaultValue?: CreateWithdrawalRequest | null,
    ) => Promise<CreateWithdrawalRequest | null>
  }
  deletePaymentMethod: ((args?: {
    paymentMethodId?: String | null
  }) => DeletePaymentMethodPromiseChain & {
    execute: (
      request: DeletePaymentMethodRequest,
      defaultValue?: DeletePaymentMethod | null,
    ) => Promise<DeletePaymentMethod | null>
  }) &
    (DeletePaymentMethodPromiseChain & {
      execute: (
        request: DeletePaymentMethodRequest,
        defaultValue?: DeletePaymentMethod | null,
      ) => Promise<DeletePaymentMethod | null>
    })
  setDefaultPaymentMethod: ((args?: {
    paymentMethodId?: String | null
  }) => SetDefaultPaymentMethodPromiseChain & {
    execute: (
      request: SetDefaultPaymentMethodRequest,
      defaultValue?: SetDefaultPaymentMethod | null,
    ) => Promise<SetDefaultPaymentMethod | null>
  }) &
    (SetDefaultPaymentMethodPromiseChain & {
      execute: (
        request: SetDefaultPaymentMethodRequest,
        defaultValue?: SetDefaultPaymentMethod | null,
      ) => Promise<SetDefaultPaymentMethod | null>
    })
  createSetupIntent: CreateSetupIntentPromiseChain & {
    execute: (
      request: CreateSetupIntentRequest,
      defaultValue?: CreateSetupIntent | null,
    ) => Promise<CreateSetupIntent | null>
  }
  updateCryptoWalletBalance: (args: {
    balanceAmount: Float
    balanceCurrency: CryptoCurrencies
    cryptoWalletId: String
  }) => UpdateCryptoWalletBalancePromiseChain & {
    execute: (
      request: UpdateCryptoWalletBalanceRequest,
      defaultValue?: UpdateCryptoWalletBalance | null,
    ) => Promise<UpdateCryptoWalletBalance | null>
  }
  updateNotificationPreferences: ((args?: {
    buyTargetPercentage?: Int | null
    notificationPreferences?: (UpdateNotificationPreferencesInput | null)[] | null
    sellTargetPercentage?: Int | null
  }) => UpdateNotificationPreferencesPromiseChain & {
    execute: (
      request: UpdateNotificationPreferencesRequest,
      defaultValue?: UpdateNotificationPreferences | null,
    ) => Promise<UpdateNotificationPreferences | null>
  }) &
    (UpdateNotificationPreferencesPromiseChain & {
      execute: (
        request: UpdateNotificationPreferencesRequest,
        defaultValue?: UpdateNotificationPreferences | null,
      ) => Promise<UpdateNotificationPreferences | null>
    })
  withdrawDepositRequestCreate: WithdrawDepositRequestCreatePromiseChain & {
    execute: (
      request: WithdrawDepositRequestCreateRequest,
      defaultValue?: WithdrawDepositRequestCreate | null,
    ) => Promise<WithdrawDepositRequestCreate | null>
  }
  editChannel: (args: {
    channelId: ID
    description?: String | null
    name?: String | null
  }) => EditChannelPromiseChain & {
    execute: (request: EditChannelRequest, defaultValue?: EditChannel | null) => Promise<EditChannel | null>
  }
  removeTeamMemberFromChannel: (args: {
    teamMemberId: ID
  }) => RemoveTeamMemberFromChannelPromiseChain & {
    execute: (
      request: RemoveTeamMemberFromChannelRequest,
      defaultValue?: RemoveTeamMemberFromChannel | null,
    ) => Promise<RemoveTeamMemberFromChannel | null>
  }
  uploadChannelBannerImage: (args: {
    bannerImage: Upload
    channelId: ID
  }) => UploadChannelBannerImagePromiseChain & {
    execute: (
      request: UploadChannelBannerImageRequest,
      defaultValue?: UploadChannelBannerImage | null,
    ) => Promise<UploadChannelBannerImage | null>
  }
  uploadChannelProfileImage: (args: {
    channelId: ID
    profileImage: Upload
  }) => UploadChannelProfileImagePromiseChain & {
    execute: (
      request: UploadChannelProfileImageRequest,
      defaultValue?: UploadChannelProfileImage | null,
    ) => Promise<UploadChannelProfileImage | null>
  }
  addTeamMemberToChannel: (args: {
    channelId: ID
    role: String
    walletAddress: String
  }) => AddTeamMemberToChannelPromiseChain & {
    execute: (
      request: AddTeamMemberToChannelRequest,
      defaultValue?: AddTeamMemberToChannel | null,
    ) => Promise<AddTeamMemberToChannel | null>
  }
  buyBoxBreakSpots: (args: {
    additionalInstructions?: String | null
    addressId: ID
    boxBreakId: ID
    paymentMethod: String
    preferShipHome?: Boolean | null
    spotIds: (ID | null)[]
  }) => BuyBoxBreakSpotsPromiseChain & {
    execute: (request: BuyBoxBreakSpotsRequest, defaultValue?: BuyBoxBreakSpots | null) => Promise<BuyBoxBreakSpots | null>
  }
  auctionCreate: (args: {
    channelId: ID
    name: String
  }) => AuctionCreatePromiseChain & {
    execute: (request: AuctionCreateRequest, defaultValue?: AuctionCreate | null) => Promise<AuctionCreate | null>
  }
  auctionDraftDelete: (args: {
    auctionId: ID
  }) => AuctionDraftDeletePromiseChain & {
    execute: (
      request: AuctionDraftDeleteRequest,
      defaultValue?: AuctionDraftDelete | null,
    ) => Promise<AuctionDraftDelete | null>
  }
  auctionDraftEdit: (args: {
    auctionId: ID
    description?: String | null
    name?: String | null
    scheduledStartTime?: DateTime | null
    twitchChannelName?: String | null
    twitchVideoId?: String | null
    youtubeVideoId?: String | null
  }) => AuctionDraftEditPromiseChain & {
    execute: (request: AuctionDraftEditRequest, defaultValue?: AuctionDraftEdit | null) => Promise<AuctionDraftEdit | null>
  }
  bidCreate: (args: {
    bidCurrency?: String | null
    bidPrice: Float
    lotId: ID
  }) => BidCreatePromiseChain & {
    execute: (request: BidCreateRequest, defaultValue?: BidCreate | null) => Promise<BidCreate | null>
  }
  bidTransitionToPendingReview: (args: {
    bidId: ID
  }) => BidTransitionToPendingReviewPromiseChain & {
    execute: (
      request: BidTransitionToPendingReviewRequest,
      defaultValue?: BidTransitionToPendingReview | null,
    ) => Promise<BidTransitionToPendingReview | null>
  }
  discordBotBidCreate: (args: {
    bidCurrency?: String | null
    bidPrice: Float
    botHmacDigest: String
    discordId: String
    lotId: ID
  }) => DiscordBotBidCreatePromiseChain & {
    execute: (
      request: DiscordBotBidCreateRequest,
      defaultValue?: DiscordBotBidCreate | null,
    ) => Promise<DiscordBotBidCreate | null>
  }
  updateLotNftApprovalStateToApproving: (args: {
    lotId: ID
  }) => UpdateLotNftApprovalStateToApprovingPromiseChain & {
    execute: (
      request: UpdateLotNftApprovalStateToApprovingRequest,
      defaultValue?: UpdateLotNftApprovalStateToApproving | null,
    ) => Promise<UpdateLotNftApprovalStateToApproving | null>
  }
  updateLotStateToCompletingSale: (args: {
    lotId: ID
  }) => UpdateLotStateToCompletingSalePromiseChain & {
    execute: (
      request: UpdateLotStateToCompletingSaleRequest,
      defaultValue?: UpdateLotStateToCompletingSale | null,
    ) => Promise<UpdateLotStateToCompletingSale | null>
  }
  /** Create a brand new address for the logged-in user. */
  createAddress: (args: {
    addressLines: String
    country: String
    description: String
    locality: String
    name: String
    postalCode: String
    region: String
    role: String
  }) => CreateAddressPromiseChain & {
    execute: (request: CreateAddressRequest, defaultValue?: CreateAddress | null) => Promise<CreateAddress | null>
  }
  /** Hide a specified address for the logged-in user. */
  deleteAddress: (args: {
    id: ID
  }) => DeleteAddressPromiseChain & {
    execute: (request: DeleteAddressRequest, defaultValue?: DeleteAddress | null) => Promise<DeleteAddress | null>
  }
  /** Hide the existing address and create a replacement with the specified changes. */
  updateAddress: (args: {
    addressLines: String
    country: String
    description: String
    id: ID
    locality: String
    name: String
    postalCode: String
    region: String
    role: String
  }) => UpdateAddressPromiseChain & {
    execute: (request: UpdateAddressRequest, defaultValue?: UpdateAddress | null) => Promise<UpdateAddress | null>
  }
  /** Mark an existing address as the default. */
  makeAddressDefault: (args: {
    id: ID
  }) => MakeAddressDefaultPromiseChain & {
    execute: (
      request: MakeAddressDefaultRequest,
      defaultValue?: MakeAddressDefault | null,
    ) => Promise<MakeAddressDefault | null>
  }
  accountRegister: (args: {
    email: String
    firstName: String
    lastName: String
    marketingEmails: Boolean
    password: String
    promoCode?: String | null
    username: String
  }) => AccountRegisterPromiseChain & {
    execute: (request: AccountRegisterRequest, defaultValue?: AccountRegister | null) => Promise<AccountRegister | null>
  }
  disconnectDiscordUser: DisconnectDiscordUserPromiseChain & {
    execute: (
      request: DisconnectDiscordUserRequest,
      defaultValue?: DisconnectDiscordUser | null,
    ) => Promise<DisconnectDiscordUser | null>
  }
  editUserProfile: ((args?: {
    emailAddress?: String | null
  }) => EditUserProfilePromiseChain & {
    execute: (request: EditUserProfileRequest, defaultValue?: EditUserProfile | null) => Promise<EditUserProfile | null>
  }) &
    (EditUserProfilePromiseChain & {
      execute: (request: EditUserProfileRequest, defaultValue?: EditUserProfile | null) => Promise<EditUserProfile | null>
    })
  generateDiscordToken: (args: {
    discordDiscriminator: String
    discordId: String
    discordUsername: String
  }) => GenerateDiscordTokenPromiseChain & {
    execute: (
      request: GenerateDiscordTokenRequest,
      defaultValue?: GenerateDiscordToken | null,
    ) => Promise<GenerateDiscordToken | null>
  }
  passwordChange: ((args?: {
    newPassword?: String | null
    oldPassword?: String | null
  }) => PasswordChangePromiseChain & {
    execute: (request: PasswordChangeRequest, defaultValue?: PasswordChange | null) => Promise<PasswordChange | null>
  }) &
    (PasswordChangePromiseChain & {
      execute: (request: PasswordChangeRequest, defaultValue?: PasswordChange | null) => Promise<PasswordChange | null>
    })
  passwordResetRequest: ((args?: {
    username?: String | null
  }) => PasswordResetRequestPromiseChain & {
    execute: (
      request: PasswordResetRequestRequest,
      defaultValue?: PasswordResetRequest | null,
    ) => Promise<PasswordResetRequest | null>
  }) &
    (PasswordResetRequestPromiseChain & {
      execute: (
        request: PasswordResetRequestRequest,
        defaultValue?: PasswordResetRequest | null,
      ) => Promise<PasswordResetRequest | null>
    })
  passwordResetChange: ((args?: {
    newPassword?: String | null
    token?: String | null
    userIdB64?: String | null
  }) => PasswordResetChangePromiseChain & {
    execute: (
      request: PasswordResetChangeRequest,
      defaultValue?: PasswordResetChange | null,
    ) => Promise<PasswordResetChange | null>
  }) &
    (PasswordResetChangePromiseChain & {
      execute: (
        request: PasswordResetChangeRequest,
        defaultValue?: PasswordResetChange | null,
      ) => Promise<PasswordResetChange | null>
    })
  tokenAuth: (args: {
    username: String
    password: String
  }) => ObtainJSONWebTokenPromiseChain & {
    execute: (
      request: ObtainJSONWebTokenRequest,
      defaultValue?: ObtainJSONWebToken | null,
    ) => Promise<ObtainJSONWebToken | null>
  }
  updateUserCollectionVisibility: (args: {
    isPublic: Boolean
  }) => UpdateUserCollectionVisibilityPromiseChain & {
    execute: (
      request: UpdateUserCollectionVisibilityRequest,
      defaultValue?: UpdateUserCollectionVisibility | null,
    ) => Promise<UpdateUserCollectionVisibility | null>
  }
  verifyDiscordToken: (args: {
    token: String
  }) => VerifyDiscordTokenPromiseChain & {
    execute: (
      request: VerifyDiscordTokenRequest,
      defaultValue?: VerifyDiscordToken | null,
    ) => Promise<VerifyDiscordToken | null>
  }
  walletAuth: (args: {
    cryptoWalletId: String
    logOutUser?: Boolean | null
    moralisToken: String
    moralisUserId: String
    signature: String
  }) => WalletAuthPromiseChain & {
    execute: (request: WalletAuthRequest, defaultValue?: WalletAuth | null) => Promise<WalletAuth | null>
  }
  verifyToken: ((args?: {
    token?: String | null
  }) => VerifyPromiseChain & { execute: (request: VerifyRequest, defaultValue?: Verify | null) => Promise<Verify | null> }) &
    (VerifyPromiseChain & { execute: (request: VerifyRequest, defaultValue?: Verify | null) => Promise<Verify | null> })
  refreshToken: ((args?: {
    refreshToken?: String | null
  }) => RefreshPromiseChain & {
    execute: (request: RefreshRequest, defaultValue?: Refresh | null) => Promise<Refresh | null>
  }) &
    (RefreshPromiseChain & { execute: (request: RefreshRequest, defaultValue?: Refresh | null) => Promise<Refresh | null> })
  deleteTokenCookie: DeleteJSONWebTokenCookiePromiseChain & {
    execute: (
      request: DeleteJSONWebTokenCookieRequest,
      defaultValue?: DeleteJSONWebTokenCookie | null,
    ) => Promise<DeleteJSONWebTokenCookie | null>
  }
  deleteRefreshTokenCookie: DeleteRefreshTokenCookiePromiseChain & {
    execute: (
      request: DeleteRefreshTokenCookieRequest,
      defaultValue?: DeleteRefreshTokenCookie | null,
    ) => Promise<DeleteRefreshTokenCookie | null>
  }
}

export interface MutationObservableChain {
  createPaymentIntent: (args: {
    amount: Int
    saveCard?: Boolean | null
  }) => CreatePaymentIntentObservableChain & {
    execute: (
      request: CreatePaymentIntentRequest,
      defaultValue?: CreatePaymentIntent | null,
    ) => Observable<CreatePaymentIntent | null>
  }
  createWithdrawalRequest: (args: {
    addressLine1?: String | null
    addressLine2?: String | null
    amount: Float
    checkRecipient?: String | null
    city?: String | null
    country?: String | null
    paymentMethod: WithdrawalPaymentMethods
    paypalEmail?: String | null
    postalTracking?: Boolean | null
    state?: String | null
    zipCode?: String | null
  }) => CreateWithdrawalRequestObservableChain & {
    execute: (
      request: CreateWithdrawalRequestRequest,
      defaultValue?: CreateWithdrawalRequest | null,
    ) => Observable<CreateWithdrawalRequest | null>
  }
  deletePaymentMethod: ((args?: {
    paymentMethodId?: String | null
  }) => DeletePaymentMethodObservableChain & {
    execute: (
      request: DeletePaymentMethodRequest,
      defaultValue?: DeletePaymentMethod | null,
    ) => Observable<DeletePaymentMethod | null>
  }) &
    (DeletePaymentMethodObservableChain & {
      execute: (
        request: DeletePaymentMethodRequest,
        defaultValue?: DeletePaymentMethod | null,
      ) => Observable<DeletePaymentMethod | null>
    })
  setDefaultPaymentMethod: ((args?: {
    paymentMethodId?: String | null
  }) => SetDefaultPaymentMethodObservableChain & {
    execute: (
      request: SetDefaultPaymentMethodRequest,
      defaultValue?: SetDefaultPaymentMethod | null,
    ) => Observable<SetDefaultPaymentMethod | null>
  }) &
    (SetDefaultPaymentMethodObservableChain & {
      execute: (
        request: SetDefaultPaymentMethodRequest,
        defaultValue?: SetDefaultPaymentMethod | null,
      ) => Observable<SetDefaultPaymentMethod | null>
    })
  createSetupIntent: CreateSetupIntentObservableChain & {
    execute: (
      request: CreateSetupIntentRequest,
      defaultValue?: CreateSetupIntent | null,
    ) => Observable<CreateSetupIntent | null>
  }
  updateCryptoWalletBalance: (args: {
    balanceAmount: Float
    balanceCurrency: CryptoCurrencies
    cryptoWalletId: String
  }) => UpdateCryptoWalletBalanceObservableChain & {
    execute: (
      request: UpdateCryptoWalletBalanceRequest,
      defaultValue?: UpdateCryptoWalletBalance | null,
    ) => Observable<UpdateCryptoWalletBalance | null>
  }
  updateNotificationPreferences: ((args?: {
    buyTargetPercentage?: Int | null
    notificationPreferences?: (UpdateNotificationPreferencesInput | null)[] | null
    sellTargetPercentage?: Int | null
  }) => UpdateNotificationPreferencesObservableChain & {
    execute: (
      request: UpdateNotificationPreferencesRequest,
      defaultValue?: UpdateNotificationPreferences | null,
    ) => Observable<UpdateNotificationPreferences | null>
  }) &
    (UpdateNotificationPreferencesObservableChain & {
      execute: (
        request: UpdateNotificationPreferencesRequest,
        defaultValue?: UpdateNotificationPreferences | null,
      ) => Observable<UpdateNotificationPreferences | null>
    })
  withdrawDepositRequestCreate: WithdrawDepositRequestCreateObservableChain & {
    execute: (
      request: WithdrawDepositRequestCreateRequest,
      defaultValue?: WithdrawDepositRequestCreate | null,
    ) => Observable<WithdrawDepositRequestCreate | null>
  }
  editChannel: (args: {
    channelId: ID
    description?: String | null
    name?: String | null
  }) => EditChannelObservableChain & {
    execute: (request: EditChannelRequest, defaultValue?: EditChannel | null) => Observable<EditChannel | null>
  }
  removeTeamMemberFromChannel: (args: {
    teamMemberId: ID
  }) => RemoveTeamMemberFromChannelObservableChain & {
    execute: (
      request: RemoveTeamMemberFromChannelRequest,
      defaultValue?: RemoveTeamMemberFromChannel | null,
    ) => Observable<RemoveTeamMemberFromChannel | null>
  }
  uploadChannelBannerImage: (args: {
    bannerImage: Upload
    channelId: ID
  }) => UploadChannelBannerImageObservableChain & {
    execute: (
      request: UploadChannelBannerImageRequest,
      defaultValue?: UploadChannelBannerImage | null,
    ) => Observable<UploadChannelBannerImage | null>
  }
  uploadChannelProfileImage: (args: {
    channelId: ID
    profileImage: Upload
  }) => UploadChannelProfileImageObservableChain & {
    execute: (
      request: UploadChannelProfileImageRequest,
      defaultValue?: UploadChannelProfileImage | null,
    ) => Observable<UploadChannelProfileImage | null>
  }
  addTeamMemberToChannel: (args: {
    channelId: ID
    role: String
    walletAddress: String
  }) => AddTeamMemberToChannelObservableChain & {
    execute: (
      request: AddTeamMemberToChannelRequest,
      defaultValue?: AddTeamMemberToChannel | null,
    ) => Observable<AddTeamMemberToChannel | null>
  }
  buyBoxBreakSpots: (args: {
    additionalInstructions?: String | null
    addressId: ID
    boxBreakId: ID
    paymentMethod: String
    preferShipHome?: Boolean | null
    spotIds: (ID | null)[]
  }) => BuyBoxBreakSpotsObservableChain & {
    execute: (
      request: BuyBoxBreakSpotsRequest,
      defaultValue?: BuyBoxBreakSpots | null,
    ) => Observable<BuyBoxBreakSpots | null>
  }
  auctionCreate: (args: {
    channelId: ID
    name: String
  }) => AuctionCreateObservableChain & {
    execute: (request: AuctionCreateRequest, defaultValue?: AuctionCreate | null) => Observable<AuctionCreate | null>
  }
  auctionDraftDelete: (args: {
    auctionId: ID
  }) => AuctionDraftDeleteObservableChain & {
    execute: (
      request: AuctionDraftDeleteRequest,
      defaultValue?: AuctionDraftDelete | null,
    ) => Observable<AuctionDraftDelete | null>
  }
  auctionDraftEdit: (args: {
    auctionId: ID
    description?: String | null
    name?: String | null
    scheduledStartTime?: DateTime | null
    twitchChannelName?: String | null
    twitchVideoId?: String | null
    youtubeVideoId?: String | null
  }) => AuctionDraftEditObservableChain & {
    execute: (
      request: AuctionDraftEditRequest,
      defaultValue?: AuctionDraftEdit | null,
    ) => Observable<AuctionDraftEdit | null>
  }
  bidCreate: (args: {
    bidCurrency?: String | null
    bidPrice: Float
    lotId: ID
  }) => BidCreateObservableChain & {
    execute: (request: BidCreateRequest, defaultValue?: BidCreate | null) => Observable<BidCreate | null>
  }
  bidTransitionToPendingReview: (args: {
    bidId: ID
  }) => BidTransitionToPendingReviewObservableChain & {
    execute: (
      request: BidTransitionToPendingReviewRequest,
      defaultValue?: BidTransitionToPendingReview | null,
    ) => Observable<BidTransitionToPendingReview | null>
  }
  discordBotBidCreate: (args: {
    bidCurrency?: String | null
    bidPrice: Float
    botHmacDigest: String
    discordId: String
    lotId: ID
  }) => DiscordBotBidCreateObservableChain & {
    execute: (
      request: DiscordBotBidCreateRequest,
      defaultValue?: DiscordBotBidCreate | null,
    ) => Observable<DiscordBotBidCreate | null>
  }
  updateLotNftApprovalStateToApproving: (args: {
    lotId: ID
  }) => UpdateLotNftApprovalStateToApprovingObservableChain & {
    execute: (
      request: UpdateLotNftApprovalStateToApprovingRequest,
      defaultValue?: UpdateLotNftApprovalStateToApproving | null,
    ) => Observable<UpdateLotNftApprovalStateToApproving | null>
  }
  updateLotStateToCompletingSale: (args: {
    lotId: ID
  }) => UpdateLotStateToCompletingSaleObservableChain & {
    execute: (
      request: UpdateLotStateToCompletingSaleRequest,
      defaultValue?: UpdateLotStateToCompletingSale | null,
    ) => Observable<UpdateLotStateToCompletingSale | null>
  }
  /** Create a brand new address for the logged-in user. */
  createAddress: (args: {
    addressLines: String
    country: String
    description: String
    locality: String
    name: String
    postalCode: String
    region: String
    role: String
  }) => CreateAddressObservableChain & {
    execute: (request: CreateAddressRequest, defaultValue?: CreateAddress | null) => Observable<CreateAddress | null>
  }
  /** Hide a specified address for the logged-in user. */
  deleteAddress: (args: {
    id: ID
  }) => DeleteAddressObservableChain & {
    execute: (request: DeleteAddressRequest, defaultValue?: DeleteAddress | null) => Observable<DeleteAddress | null>
  }
  /** Hide the existing address and create a replacement with the specified changes. */
  updateAddress: (args: {
    addressLines: String
    country: String
    description: String
    id: ID
    locality: String
    name: String
    postalCode: String
    region: String
    role: String
  }) => UpdateAddressObservableChain & {
    execute: (request: UpdateAddressRequest, defaultValue?: UpdateAddress | null) => Observable<UpdateAddress | null>
  }
  /** Mark an existing address as the default. */
  makeAddressDefault: (args: {
    id: ID
  }) => MakeAddressDefaultObservableChain & {
    execute: (
      request: MakeAddressDefaultRequest,
      defaultValue?: MakeAddressDefault | null,
    ) => Observable<MakeAddressDefault | null>
  }
  accountRegister: (args: {
    email: String
    firstName: String
    lastName: String
    marketingEmails: Boolean
    password: String
    promoCode?: String | null
    username: String
  }) => AccountRegisterObservableChain & {
    execute: (request: AccountRegisterRequest, defaultValue?: AccountRegister | null) => Observable<AccountRegister | null>
  }
  disconnectDiscordUser: DisconnectDiscordUserObservableChain & {
    execute: (
      request: DisconnectDiscordUserRequest,
      defaultValue?: DisconnectDiscordUser | null,
    ) => Observable<DisconnectDiscordUser | null>
  }
  editUserProfile: ((args?: {
    emailAddress?: String | null
  }) => EditUserProfileObservableChain & {
    execute: (request: EditUserProfileRequest, defaultValue?: EditUserProfile | null) => Observable<EditUserProfile | null>
  }) &
    (EditUserProfileObservableChain & {
      execute: (request: EditUserProfileRequest, defaultValue?: EditUserProfile | null) => Observable<EditUserProfile | null>
    })
  generateDiscordToken: (args: {
    discordDiscriminator: String
    discordId: String
    discordUsername: String
  }) => GenerateDiscordTokenObservableChain & {
    execute: (
      request: GenerateDiscordTokenRequest,
      defaultValue?: GenerateDiscordToken | null,
    ) => Observable<GenerateDiscordToken | null>
  }
  passwordChange: ((args?: {
    newPassword?: String | null
    oldPassword?: String | null
  }) => PasswordChangeObservableChain & {
    execute: (request: PasswordChangeRequest, defaultValue?: PasswordChange | null) => Observable<PasswordChange | null>
  }) &
    (PasswordChangeObservableChain & {
      execute: (request: PasswordChangeRequest, defaultValue?: PasswordChange | null) => Observable<PasswordChange | null>
    })
  passwordResetRequest: ((args?: {
    username?: String | null
  }) => PasswordResetRequestObservableChain & {
    execute: (
      request: PasswordResetRequestRequest,
      defaultValue?: PasswordResetRequest | null,
    ) => Observable<PasswordResetRequest | null>
  }) &
    (PasswordResetRequestObservableChain & {
      execute: (
        request: PasswordResetRequestRequest,
        defaultValue?: PasswordResetRequest | null,
      ) => Observable<PasswordResetRequest | null>
    })
  passwordResetChange: ((args?: {
    newPassword?: String | null
    token?: String | null
    userIdB64?: String | null
  }) => PasswordResetChangeObservableChain & {
    execute: (
      request: PasswordResetChangeRequest,
      defaultValue?: PasswordResetChange | null,
    ) => Observable<PasswordResetChange | null>
  }) &
    (PasswordResetChangeObservableChain & {
      execute: (
        request: PasswordResetChangeRequest,
        defaultValue?: PasswordResetChange | null,
      ) => Observable<PasswordResetChange | null>
    })
  tokenAuth: (args: {
    username: String
    password: String
  }) => ObtainJSONWebTokenObservableChain & {
    execute: (
      request: ObtainJSONWebTokenRequest,
      defaultValue?: ObtainJSONWebToken | null,
    ) => Observable<ObtainJSONWebToken | null>
  }
  updateUserCollectionVisibility: (args: {
    isPublic: Boolean
  }) => UpdateUserCollectionVisibilityObservableChain & {
    execute: (
      request: UpdateUserCollectionVisibilityRequest,
      defaultValue?: UpdateUserCollectionVisibility | null,
    ) => Observable<UpdateUserCollectionVisibility | null>
  }
  verifyDiscordToken: (args: {
    token: String
  }) => VerifyDiscordTokenObservableChain & {
    execute: (
      request: VerifyDiscordTokenRequest,
      defaultValue?: VerifyDiscordToken | null,
    ) => Observable<VerifyDiscordToken | null>
  }
  walletAuth: (args: {
    cryptoWalletId: String
    logOutUser?: Boolean | null
    moralisToken: String
    moralisUserId: String
    signature: String
  }) => WalletAuthObservableChain & {
    execute: (request: WalletAuthRequest, defaultValue?: WalletAuth | null) => Observable<WalletAuth | null>
  }
  verifyToken: ((args?: {
    token?: String | null
  }) => VerifyObservableChain & {
    execute: (request: VerifyRequest, defaultValue?: Verify | null) => Observable<Verify | null>
  }) &
    (VerifyObservableChain & {
      execute: (request: VerifyRequest, defaultValue?: Verify | null) => Observable<Verify | null>
    })
  refreshToken: ((args?: {
    refreshToken?: String | null
  }) => RefreshObservableChain & {
    execute: (request: RefreshRequest, defaultValue?: Refresh | null) => Observable<Refresh | null>
  }) &
    (RefreshObservableChain & {
      execute: (request: RefreshRequest, defaultValue?: Refresh | null) => Observable<Refresh | null>
    })
  deleteTokenCookie: DeleteJSONWebTokenCookieObservableChain & {
    execute: (
      request: DeleteJSONWebTokenCookieRequest,
      defaultValue?: DeleteJSONWebTokenCookie | null,
    ) => Observable<DeleteJSONWebTokenCookie | null>
  }
  deleteRefreshTokenCookie: DeleteRefreshTokenCookieObservableChain & {
    execute: (
      request: DeleteRefreshTokenCookieRequest,
      defaultValue?: DeleteRefreshTokenCookie | null,
    ) => Observable<DeleteRefreshTokenCookie | null>
  }
}

export interface CreatePaymentIntentPromiseChain {
  paymentIntentToken: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  paymentSequence: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface CreatePaymentIntentObservableChain {
  paymentIntentToken: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  paymentSequence: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

export interface CreateWithdrawalRequestPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface CreateWithdrawalRequestObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

/** Represents an error in the input of a mutation. */
export interface ErrorPromiseChain {
  /** Name of the field that caused the error. A value of `null` indicates that the error isn't associated with a particular field. */
  field: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  /** The error message. */
  message: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

/** Represents an error in the input of a mutation. */
export interface ErrorObservableChain {
  /** Name of the field that caused the error. A value of `null` indicates that the error isn't associated with a particular field. */
  field: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  /** The error message. */
  message: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface DeletePaymentMethodPromiseChain {
  paymentMethodId: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface DeletePaymentMethodObservableChain {
  paymentMethodId: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface SetDefaultPaymentMethodPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface SetDefaultPaymentMethodObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface CreateSetupIntentPromiseChain {
  setupIntentClientSecret: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface CreateSetupIntentObservableChain {
  setupIntentClientSecret: {
    execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null>
  }
}

export interface UpdateCryptoWalletBalancePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface UpdateCryptoWalletBalanceObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface UpdateNotificationPreferencesPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface UpdateNotificationPreferencesObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface WithdrawDepositRequestCreatePromiseChain {
  withdrawDepositRequest: WithdrawDepositRequestNodePromiseChain & {
    execute: (
      request: WithdrawDepositRequestNodeRequest,
      defaultValue?: WithdrawDepositRequestNode | null,
    ) => Promise<WithdrawDepositRequestNode | null>
  }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface WithdrawDepositRequestCreateObservableChain {
  withdrawDepositRequest: WithdrawDepositRequestNodeObservableChain & {
    execute: (
      request: WithdrawDepositRequestNodeRequest,
      defaultValue?: WithdrawDepositRequestNode | null,
    ) => Observable<WithdrawDepositRequestNode | null>
  }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface WithdrawDepositRequestNodePromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Promise<ID> }
  user: UserTypePromiseChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Promise<UserType> }
  status: {
    execute: (
      request?: boolean | number,
      defaultValue?: WithdrawDepositRequestStatus,
    ) => Promise<WithdrawDepositRequestStatus>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Promise<DateTime> }
  refundedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Promise<DateTime | null> }
  notes: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

export interface WithdrawDepositRequestNodeObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: ID) => Observable<ID> }
  user: UserTypeObservableChain & { execute: (request: UserTypeRequest, defaultValue?: UserType) => Observable<UserType> }
  status: {
    execute: (
      request?: boolean | number,
      defaultValue?: WithdrawDepositRequestStatus,
    ) => Observable<WithdrawDepositRequestStatus>
  }
  createdAt: { execute: (request?: boolean | number, defaultValue?: DateTime) => Observable<DateTime> }
  refundedAt: { execute: (request?: boolean | number, defaultValue?: DateTime | null) => Observable<DateTime | null> }
  notes: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface EditChannelPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface EditChannelObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface RemoveTeamMemberFromChannelPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface RemoveTeamMemberFromChannelObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface UploadChannelBannerImagePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface UploadChannelBannerImageObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface UploadChannelProfileImagePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface UploadChannelProfileImageObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface AddTeamMemberToChannelPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface AddTeamMemberToChannelObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface BuyBoxBreakSpotsPromiseChain {
  purchasedSpotsCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  purchasedSpotsCost: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface BuyBoxBreakSpotsObservableChain {
  purchasedSpotsCount: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  purchasedSpotsCost: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface AuctionCreatePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface AuctionCreateObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface AuctionDraftDeletePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface AuctionDraftDeleteObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface AuctionDraftEditPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface AuctionDraftEditObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface BidCreatePromiseChain {
  bid: BidNodePromiseChain & { execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Promise<BidNode | null> }
  lot: LotNodePromiseChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Promise<LotNode | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface BidCreateObservableChain {
  bid: BidNodeObservableChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Observable<BidNode | null>
  }
  lot: LotNodeObservableChain & {
    execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Observable<LotNode | null>
  }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface BidTransitionToPendingReviewPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface BidTransitionToPendingReviewObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface DiscordBotBidCreatePromiseChain {
  bid: BidNodePromiseChain & { execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Promise<BidNode | null> }
  lot: LotNodePromiseChain & { execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Promise<LotNode | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface DiscordBotBidCreateObservableChain {
  bid: BidNodeObservableChain & {
    execute: (request: BidNodeRequest, defaultValue?: BidNode | null) => Observable<BidNode | null>
  }
  lot: LotNodeObservableChain & {
    execute: (request: LotNodeRequest, defaultValue?: LotNode | null) => Observable<LotNode | null>
  }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface UpdateLotNftApprovalStateToApprovingPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface UpdateLotNftApprovalStateToApprovingObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface UpdateLotStateToCompletingSalePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface UpdateLotStateToCompletingSaleObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

/** Create a brand new address for the logged-in user. */
export interface CreateAddressPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

/** Create a brand new address for the logged-in user. */
export interface CreateAddressObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

/** Hide a specified address for the logged-in user. */
export interface DeleteAddressPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

/** Hide a specified address for the logged-in user. */
export interface DeleteAddressObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

/** Hide the existing address and create a replacement with the specified changes. */
export interface UpdateAddressPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

/** Hide the existing address and create a replacement with the specified changes. */
export interface UpdateAddressObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

/** Mark an existing address as the default. */
export interface MakeAddressDefaultPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

/** Mark an existing address as the default. */
export interface MakeAddressDefaultObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface AccountRegisterPromiseChain {
  user: UserTypePromiseChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Promise<UserType | null>
  }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface AccountRegisterObservableChain {
  user: UserTypeObservableChain & {
    execute: (request: UserTypeRequest, defaultValue?: UserType | null) => Observable<UserType | null>
  }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface DisconnectDiscordUserPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface DisconnectDiscordUserObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface EditUserProfilePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface EditUserProfileObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface GenerateDiscordTokenPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
  token: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

export interface GenerateDiscordTokenObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
  token: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

export interface PasswordChangePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface PasswordChangeObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface PasswordResetRequestPromiseChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface PasswordResetRequestObservableChain {
  success: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface PasswordResetChangePromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface PasswordResetChangeObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface ObtainJSONWebTokenPromiseChain {
  payload: { execute: (request?: boolean | number, defaultValue?: GenericScalar) => Promise<GenericScalar> }
  refreshExpiresIn: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  token: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

export interface ObtainJSONWebTokenObservableChain {
  payload: { execute: (request?: boolean | number, defaultValue?: GenericScalar) => Observable<GenericScalar> }
  refreshExpiresIn: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  token: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface UpdateUserCollectionVisibilityPromiseChain {
  ok: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Promise<Boolean | null> }
}

export interface UpdateUserCollectionVisibilityObservableChain {
  ok: { execute: (request?: boolean | number, defaultValue?: Boolean | null) => Observable<Boolean | null> }
}

export interface VerifyDiscordTokenPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
}

export interface VerifyDiscordTokenObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
}

export interface WalletAuthPromiseChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Promise<Error[] | null> }
  token: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  refreshExpiresIn: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  exp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  origIat: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

export interface WalletAuthObservableChain {
  /** List of errors that occurred executing the mutation. */
  errors: { execute: (request: ErrorRequest, defaultValue?: Error[] | null) => Observable<Error[] | null> }
  token: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  refreshExpiresIn: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  exp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  origIat: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

export interface VerifyPromiseChain {
  payload: { execute: (request?: boolean | number, defaultValue?: GenericScalar) => Promise<GenericScalar> }
}

export interface VerifyObservableChain {
  payload: { execute: (request?: boolean | number, defaultValue?: GenericScalar) => Observable<GenericScalar> }
}

export interface RefreshPromiseChain {
  payload: { execute: (request?: boolean | number, defaultValue?: GenericScalar) => Promise<GenericScalar> }
  refreshExpiresIn: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  token: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

export interface RefreshObservableChain {
  payload: { execute: (request?: boolean | number, defaultValue?: GenericScalar) => Observable<GenericScalar> }
  refreshExpiresIn: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  token: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  refreshToken: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

export interface DeleteJSONWebTokenCookiePromiseChain {
  deleted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
}

export interface DeleteJSONWebTokenCookieObservableChain {
  deleted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
}

export interface DeleteRefreshTokenCookiePromiseChain {
  deleted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Promise<Boolean> }
}

export interface DeleteRefreshTokenCookieObservableChain {
  deleted: { execute: (request?: boolean | number, defaultValue?: Boolean) => Observable<Boolean> }
}
