import { gql } from "@apollo/client";

export const GET_CHANNEL = gql`
  query GET_CHANNEL($slug: String) {
    channel(slug: $slug) {
      id
      bannerImageUrl(width: 800, height: 200)
      name
      profileImageUrl(width: 256, height: 256)
      slug

      auctions {
        edges {
          node {
            id
            currency
            description
            lotCount
            lotMinOpeningPrice
            name
            scheduledStartTime
            slug
            state

            auctionphotoSet {
              edges {
                node {
                  photoMd
                }
              }
            }
          }
        }
      }

      teamMembers {
        edges {
          node {
            id
            role

            user {
              id
              username
            }
          }
        }
      }
    }
  }
`;
